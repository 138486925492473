import React from "react";
import { Paper, Tabs, Tab } from "@mui/material";
import { useState } from "react";
import { setSelectedNavIndex } from "../../redux/reducers/appReducer";
import { useDispatch, useSelector } from "react-redux";
import xlsx from "json-as-xlsx";
import { FilterAltOff } from "@mui/icons-material";
import IntangibleAssets from "../../Components/IntangibleAssets/IntangibleAssets";

import "./IntangibleAssetsTab.css";
import AssetAssignForm from "../../Components/IntangibleAssets/AssetAssignForm";

const IntangibleAssetsTab = () => {
  const dispatch = useDispatch();
  dispatch(setSelectedNavIndex(6));
  // **master data********************
  //InventoryData is the source for the data sent to the table. data is stored by fetching the data from the database

  //tab state
  const [value, setValue] = useState(0);

  const handleChange = (event, newValue) => {
    setValue(newValue);
  };

  return (
    <>
      {/* <Paper style={{ height: 'calc(100vh - 52px - 3em)', margin: '1em', overflow: 'hidden', paddingBottom: '1rem', padding: '5px' }}> */}
      <div style={{ height: "100%" }}>
        <Tabs
          value={value}
          onChange={handleChange}
          sx={{
            padding: "20px 20px 0px 20px",
            "& button": {
              fontWeight: "200",
              fontSize: "16px",
              borderRadius: "4px 4px 0 0",
              border: "solid 1px #ddd",
              padding: "8px 14px",
              textTransform: "none",
            },
            "& button.Mui-selected": {
              fontWeight: "bold",
              fontSize: "16px",
              backgroundColor: "#f1f5fe",
              color: "#0288d1",
              boxShadow: "0",
              padding: "0",
              padding: "8px 14px",
              boxShadow: "rgb(0 0 0 / 25%) 0px 4px 4px 2px",
            },
          }}
          style={{ width: "100%", minHeight: "30px" }}
          TabIndicatorProps={{ hidden: true }}
        >
          <Tab label="Intangible Assets" />
          <Tab label="Assign Asset" />
        </Tabs>

        <TabPanel value={value} index={0}>
          <IntangibleAssets />
        </TabPanel>
        <TabPanel value={value} index={1}>
          <AssetAssignForm />
        </TabPanel>
      </div>
      {/* </Paper > */}
    </>
  );
};

export default IntangibleAssetsTab;

function TabPanel(props) {
  const { children, value, index } = props;
  return <>{value === index && <>{children}</>}</>;
}

import {
  Tab,
  Tabs,
  Avatar,
  Typography,
  Paper,
  Box,
  Grid,
  TextField,
  Dialog,
  DialogTitle,
  DialogActions,
  DialogContent,
  Button,
  Backdrop,
  CircularProgress,
  Stack,
  Divider,
} from "@mui/material";
import React, { useEffect, useState } from "react";
import TableUtility from "../../Components/TableUtility/TableUtility";
import avatarPhoto from "../../Assets/profilei.png";
import { baseUrl } from "../../Utils/serviceRequest";
import axios from "axios";
import { useSelector, useDispatch } from "react-redux";
import { setSelectedNavIndex } from "../../redux/reducers/appReducer";
import DraggableFileUploader from "../../Components/DraggableComponent/DraggableFileUploader";

const inputSize = {
  style: {
    height: "5vh",
    maxHeight: "32px",
    width: "15em",
    padding: "2px 10px",
  },
};

// function TabPanel(props) {
//   const { children, value, index } = props;
//   return <div>{value === index && <div>{children}</div>}</div>;
// }
function InternalTabPanel(props) {
  const { children, value, index } = props;
  return <>{value === index && <>{children}</>}</>;
}

const UserHome = () => {
  const userReducer = useSelector((state) => state.userReducer);
  console.log("userReducer", userReducer);
  // const [showEmployeeIDForm, setShowEmployeeIDForm] = useState(false);
  const [userProfileData, setUserProfileData] = useState(null);
  const [isLoading, setIsLoading] = useState(false);

  // const DateString = (timestamp) => {
  //   var time = Number(timestamp);
  //   var date = new Date(time).toISOString().substring(0, 10);
  //   return date;
  // };

  const DateString = (timestamp) => {
    if (timestamp !== null) {
      // var time = Number(timestamp);
      var time = Number(timestamp);
      var date = new Date(time + 5.5 * 60 * 60 * 1000)
        .toISOString()
        .substring(0, 10);
      return date;
    }
    return "-";
  };

  const updateEmployeeId = async () => {
    try {
      let res = await axios.put(`${baseUrl}/updateUser`, {
        userId: userReducer.userData.username,
        employeeId: employeeId,
      });
      if (res.status === 200) {
        fetchUserAssetAllocation(userReducer.userData.username);
        window.location.reload();
      }
    } catch (error) {
      console.error(error);
    }
  };

  const fetchUserAssetAllocation = async (userId) => {
    try {
      setIsLoading(true);
      let data = (
        await axios.get(`${baseUrl}/getAssignedActiveAssetsByUserId/${userId}`)
      ).data;
      // console.log("23",data)
      if (data) {
        console.log(data);
        // if (data[0]["employeeId"] !== null) {
          // data.forEach((element) => {
          //   element.assetHandOverDate = DateString(element.assetHandOverDate);
          // });
          setUserProfileData(data);
          setIsLoading(false);
        // } else {
          // setShowEmployeeIDForm(true);
          // setIsLoading(false);
        // }
      }
    } catch (error) {
      setIsLoading(false);
      console.error(error);
    }
  };

  //state to store employeeId when user logIn for the first time
  const [employeeId, setEmployeeId] = useState("");

  useEffect(() => {
    fetchUserAssetAllocation(userReducer?.userData?.employeeId);
  }, []);

  const dispatch = useDispatch();
  dispatch(setSelectedNavIndex(0));

  const [value, setValue] = useState(0);
  const handleTab = (e, val) => {
    setValue(val);
  };

  const [consentDialogStatus, setConsentDialogStatus] = useState(false);
  let ShowImportModalFunc = () => {
    setConsentDialogStatus(true);
  };

  const [selectedFile, setSelectedFile] = useState(null);
  const [assetAllocationId, setAssetAllocationId] = useState("");

  const uploadConsent = async (e) => {
    try {
      const formData = new FormData();
      formData.append("consentForm ", selectedFile);
      let res = await axios.put(
        `${baseUrl}/uploadConsentForm/${assetAllocationId}`,
        formData
      );

      if (res.status === 200) {
        console.log("done");
        window.location.reload();
      }
    } catch (error) {
      console.error(error);
    }
  };

  function capitalizeFirstLetter(string) {
    return string.charAt(0).toUpperCase() + string.slice(1);
  }

  return (
    <Box sx={{ padding: "1em" }}>
      {!isLoading ? (
        <>
          {/* <Tabs
            value={value}
            onChange={handleTab}
            sx={{
              "& button": {
                fontWeight: "200",
                fontSize: "16px",
                borderRadius: "4px 4px 0 0",
                border: "solid 1px #ddd",
                padding: "8px 14px",
                textTransform: "none",
              },
              "& button.Mui-selected": {
                fontWeight: "bold",
                fontSize: "16px",
                backgroundColor: "#f1f5fe",
                color: "#0288d1",
                boxShadow: "0",
                padding: "0",
                padding: "8px 14px",
                boxShadow: "rgb(0 0 0 / 25%) 0px 4px 4px 2px",
              },
            }}
            style={{ width: "100%", minHeight: "30px" }}
            TabIndicatorProps={{ hidden: true }}
          >
            <Tab label="Assigned Assets" />
          </Tabs> */}
          {userProfileData !== null && (
            // <TabPanel value={value} index={0}>
              <Paper sx={{ height: "78vh" }}>
                <Box
                  sx={{
                    display: "flex",
                    flexDirection: "column",
                    justifyContent: "center",
                    alignItems: "center",
                    height: "100%",
                  }}
                >
                  {/* <Box
                    sx={{
                      display: "flex",
                      flexDirection: "column",
                      justifyContent: "center",
                      alignItems: "center",
                    }}
                  >
                    <Avatar
                      // src={avatarPhoto}
                      sx={{ height: "83.3px", width: "83.3px" }}
                    >
                      {userReducer.avatarInitials}
                    </Avatar>
                    {userProfileData && (
                      <>
                        <Typography variant="p" align="center">
                        
                          {userReducer.userData.name}
                          <br />
                          {userReducer.userData.username}
                         
                        </Typography>
                      </>
                    )}
                  </Box> */}

                  {/* new code check user details */}
                  <div className="child1">
                    <Box
                      sx={{
                        display: "flex",
                        alignItems: "center",
                        justifyContent: "space-evenly",
                        // width:"80%",
                        p: 2,
                        border: "1px solid #ddd",
                        borderRadius: 2,
                        backgroundColor: "rgb(248,248,248)",
                      }}
                    >
                      {userProfileData && (
                        <>
                          <Grid
                            container
                            spacing={2}
                            sx={{ width: "50%", marginLeft: "25px" }}
                          >
                            <Grid item xs={12}>
                              <Box
                                spacing={2}
                                sx={{
                                  display: "flex",
                                  flexDirection: "row",
                                  justifyContent: "flex-start",
                                  alignItems: "center",
                                }}
                              >
                                <Typography variant="h6">
                                  <strong>Name :&nbsp;</strong>
                                </Typography>
                                <Typography
                                  variant="body1"
                                  sx={{ fontSize: "20px" }}
                                >
                                  {/* {userProfileData?.[0]?.userName ?? ""}{" "} */}
                                  {userReducer.userData.name}
                                </Typography>
                              </Box>
                            </Grid>

                            <Grid item xs={12}>
                              <Box
                                spacing={2}
                                sx={{
                                  display: "flex",
                                  flexDirection: "row",
                                  justifyContent: "flex-start",
                                }}
                              >
                                <Typography variant="h6">
                                  <strong>Email :&nbsp;</strong>
                                </Typography>
                                <Typography
                                  variant="body1"
                                  sx={{ fontSize: "20px" }}
                                >
                                 {userReducer.userData.username}
                                </Typography>
                              </Box>
                            </Grid>
                            <Grid item xs={12}>
                              <Box
                                spacing={2}
                                sx={{
                                  display: "flex",
                                  flexDirection: "row",
                                  justifyContent: "flex-start",
                                }}
                              >
                                <Typography variant="h6">
                                  <strong>Emp Code :&nbsp;</strong>
                                </Typography>
                                <Typography
                                  variant="body1"
                                  sx={{ fontSize: "20px" }}
                                >
                                 {userReducer.userData.employeeId}
                                </Typography>
                              </Box>
                            </Grid>
                            <Grid item xs={12}>
                              <Box
                                spacing={2}
                                sx={{
                                  display: "flex",
                                  flexDirection: "row",
                                  justifyContent: "flex-start",
                                }}
                              >
                                <Typography variant="h6">
                                  <strong>User Location :&nbsp;</strong>
                                </Typography>
                                <Typography
                                  variant="body1"
                                  sx={{ fontSize: "20px" }}
                                >
                                  {" "}
                                  {userProfileData?.[0]?.userLocation ?? ""}
                                </Typography>
                              </Box>
                            </Grid>
                          </Grid>

                          <Divider
                            orientation="vertical"
                            flexItem
                            sx={{ margin: "15px" }}
                          />
                        </>
                      )}

                      <Stack>
                        {/* <Box
                  style={{
                    padding: "0.8em 0.5em 0em 0em",
                    flex: 1,
                    bgcolor: "#cfe8fc",
                  }}
                >
                <Typography variant="h6">Role: </Typography>
                <Typography variant="body1" sx={{ fontSize: "20px" }}>{userReducer.userData.role}</Typography>
                 
                </Box> */}

                        <Grid item xs={12}>
                          <Box
                            spacing={2}
                            sx={{
                              display: "flex",
                              flexDirection: "row",
                              justifyContent: "flex-start",
                            }}
                          >
                            <Typography variant="h6">
                              <strong>Role :&nbsp; </strong>
                            </Typography>
                            <Typography
                              variant="body1"
                              sx={{ fontSize: "20px" }}
                            >
                              {capitalizeFirstLetter(userReducer.userData.role)}
                              {/* {userReducer.userData.role} */}
                            </Typography>
                          </Box>
                        </Grid>

                        {/* {userProfileData && (
                  <>
                   
                    {userReducer.role === "superAdmin" && (
                      <Button
                        variant="contained"
                        sx={{
                          marginTop: "0.5em",
                          border: "1px solid #F1F5FE",
                          backgroundColor: "#F1F5FE",
                          color: "#0288d1",
                          fontSize: "12px",
                          width: "97%",
                          //  justifyContent:"center",
                          textTransform: "capitalize",
                          height: "max-content",
                          "&:hover": {
                            backgroundColor: "#F1F5FE",
                            color: "#0288d1",
                            border: "1px solid #0288d1",
                            boxShadow: "1px #000000",
                          },
                        }}
                        onClick={() => setRoleModalStatus(true)}
                      >
                        Change Role
                      </Button>
                    )}
                  </>
                )} */}
                      </Stack>

                      {userProfileData && (
                        <Box
                          style={{
                            flex: 10,
                            display: "flex",
                            flexDirection: "column",
                            alignItems: "center",
                            // alignItems: "flex-start",
                            justifyContent: "center",
                          }}
                        >
                          <Avatar
                            src={avatarPhoto}
                            sx={{
                              height: "150.3px",
                              width: "150.3px",
                              marginBottom: "0.5em",
                            }}
                          />
                        </Box>
                      )}
                    </Box>
                  </div>

                  {/* end */}

                  {userProfileData !== null && (
                    <>
                      <Tabs
                value={value}
                onChange={handleTab}
                sx={{
                  // padding: "20px 0px 0px 20px",
                  "& button": {
                    fontWeight: "200",
                    fontSize: "16px",
                    borderRadius: "4px 4px 0 0",
                    border: "solid 1px #ddd",
                    padding: "8px 14px",
                    textTransform: "none",
                  },
                  "& button.Mui-selected": {
                    fontWeight: "bold",
                    fontSize: "16px",
                    backgroundColor: "#f1f5fe",
                    color: "#0288d1",
                    boxShadow: "0",
                    padding: "0",
                    padding: "8px 14px",
                    boxShadow: "rgb(0 0 0 / 25%) 0px 4px 4px 2px",
                  },
                }}
                style={{ width: "100%", minHeight: "30px" }}
                TabIndicatorProps={{ hidden: true }}
              >
                <Tab label="Assigned Assets" sx={{marginBottom:"1.25rem"}} />
                {/* <Tab label="Other Assets" /> */}
              </Tabs>
              <InternalTabPanel value={value} index={0}></InternalTabPanel>
                    <TableUtility
                      Data={userProfileData}
                      headerkeys={[
                        "",
                        "Tag No",
                        "Asset Type",
                        "Make",
                        "Model",
                        "Assign Date",
                        "Assigned By",
                        "Assigned Location",
                        "Return Date",
                        "Returned To",
                        "Return Location",
                      ]}
                      bodykeys={[
                        "",
                        "assetId",
                        "assetType",
                        "brand",
                        "model",
                        "assetStDate",
                        "assignedBy",
                        "baseLocation",
                        "assetReturnedDate",
                        "returnedBy",
                        "returnLocation",
                      ]}
                      enableDelete={false}
                      enableEdit={false}
                      height={"65vh"}
                    />
                    </>
                  )}
                </Box>
              </Paper>
            // </TabPanel>
          )}
          <Backdrop
            sx={{ color: "#fff", zIndex: (theme) => theme.zIndex.drawer + 1 }}
            open={isLoading}
          >
            <CircularProgress color="inherit" />
          </Backdrop>
          <Dialog open={consentDialogStatus}>
            <DialogTitle sx={{ textAlign: "center" }}>
              <Typography variant="h6">Select File</Typography>
            </DialogTitle>
            <DialogContent sx={{ padding: "10px" }} className="wbScroll">
              <DraggableFileUploader
                file={selectedFile}
                setFile={setSelectedFile}
                fileTypes={["doc", "docx"]}
              />
            </DialogContent>
            <DialogActions>
              <Button
                onClick={(e) => uploadConsent(e)}
                variant="contained"
                sx={{
                  backgroundColor: "#3026B9",
                  textTransform: "none",
                  "&:hover": {
                    backgroundColor: "#140B96",
                    boxShadow: "1px #000000",
                  },
                }}
                disableRipple
              >
                Upload
              </Button>
              <Button
                onClick={() => setConsentDialogStatus(false)}
                variant="contained"
                sx={{
                  backgroundColor: "#E0E0E0",
                  textTransform: "none",
                  color: "black",
                  "&:hover": {
                    backgroundColor: "#E0E0E0",
                    boxShadow: "1px #000000",
                  },
                }}
                disableRipple
              >
                Cancel
              </Button>
            </DialogActions>
          </Dialog>
        </>
      ) : (
        <Box
        flexGrow={1}
        display="flex"
        flexDirection="column"
        alignItems="center"
        justifyContent="center"
        sx={{ height: "62vh" }}
      >
        <CircularProgress />
        <Typography color="#0288d1">Loading...</Typography>
      </Box>
        // <Dialog open={true}>
        //   <DialogTitle
        //     sx={{ textAlign: "center", padding: "0%", paddingTop: "4%" }}
        //   >
        //     <Typography variant="h6">Enter Your Employee ID</Typography>
        //   </DialogTitle>
        //   <DialogContent
        //     sx={{ justifyContent: "center", padding: "0 15px" }}
        //     className="wbScroll"
        //   >
           
        //     <TextField
        //       margin="normal"
        //       id="outlined-basic"
        //       variant="outlined"
        //       name="make"
        //       inputProps={inputSize}
        //       // defaultValue={employeeId}
        //       onChange={(e) => setEmployeeId(e.target.value)}
        //     />
        //   </DialogContent>
        //   <DialogActions sx={{ justifyContent: "center" }}>
        //     <Button
        //       onClick={(e) => updateEmployeeId(e)}
        //       variant="contained"
        //       sx={{
        //         backgroundColor: "#3026B9",
        //         textTransform: "none",
        //         "&:hover": {
        //           backgroundColor: "#140B96",
        //           boxShadow: "1px #000000",
        //         },
        //       }}
        //       disableRipple
        //     >
        //       Submit
        //     </Button>
        //   </DialogActions>
        // </Dialog>
      )}
    </Box>
  );
};

export default UserHome;

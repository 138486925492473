import React, { useState } from "react";
import {
  Tab,
  Tabs,
} from "@mui/material";
import { setSelectedNavIndex } from "../../redux/reducers/appReducer";
import "./reportstyle.css";
import { useDispatch } from "react-redux";import { Box } from "@mui/system";
import ReturnReportTable from "./ReportTabPanels/ReturnReportTable";
import AssignReportTable from "./ReportTabPanels/AssignReportTable";

const Reports = () => {
  const dispatch = useDispatch();
  dispatch(setSelectedNavIndex(3));

  const [value, setValue] = useState(0);
  const handleTab = (e, val) => {
    setValue(val);
  };

  return (
    <Box sx={{ padding: "1em" }}>
      <Tabs
        value={value}
        onChange={handleTab}
        sx={{
          "& button": {
            fontWeight: "200",
            fontSize: "16px",
            borderRadius: "4px 4px 0 0",
            border: "solid 1px #ddd",
            padding: "8px 14px",
            textTransform: "none",
          },
          "& button.Mui-selected": {
            fontWeight: "bold",
            fontSize: "16px",
            backgroundColor: "#f1f5fe",
            color: "#0288d1",
            boxShadow: "0",
            padding: "0",
            padding: "8px 14px",
            boxShadow: "rgb(0 0 0 / 25%) 0px 4px 4px 2px",
          },
        }}
        style={{ width: "100%", minHeight: "30px" }}
        TabIndicatorProps={{ hidden: true }}
      >
        {/* <Tab label="Returned Asset" /> */}
        {/* <Tab label="Assigned Asset" /> */}
        <Tab label="Assets Report" />
      </Tabs>

      {/* <TabPanel value={value} index={0}>
        <ReturnReportTable />
      </TabPanel> */}
      <TabPanel value={value} index={0}>
        <AssignReportTable />
      </TabPanel>
    </Box>
  );
};



function TabPanel(props) {
  const { children, value, index } = props;
  return <div>{value === index && <div>{children}</div>}</div>;
}

export default Reports;

import React, { useState, useEffect } from 'react';
import { Line } from 'react-chartjs-2';
import axios from 'axios';
import { baseUrl } from '../../Utils/serviceRequest';
import { FormControl, Select, MenuItem, Box } from '@mui/material';

const ProcurementLineGraph = () => {
    const [chartData, setChartData] = useState(null);
    const [loading, setLoading] = useState(true);
    const [yearRange, setYearRange] = useState('2016-2021');
    const [data, setData] = useState([]);

    const formatChartData = (data) => {
        const labels = data.map(d => `${d.year}-${String(d.month).padStart(2, '0')}`);
        const counts = data.map(d => d.count);

        return {
            labels,
            datasets: [
                {
                    label: 'Asset Count',
                    data: counts,
                    fill: false,
                    borderColor: 'rgba(75,192,192,1)',
                    tension: 0.1
                }
            ]
        };
    };

    const filterDataByYearRange = (data, range) => {
        const [startYear, endYear] = range === '2016-2021' ? [2016, 2021] : [2022, new Date().getFullYear()];
        return data.filter(d => d.year >= startYear && d.year <= endYear);
    };

    useEffect(() => {
        const fetchData = async () => {
            try {
                const response = await axios.get(`${baseUrl}/getProcurementsMonthly`); 
                const fetchedData = response.data.data;
                if (fetchedData && fetchedData.length > 0) {
                    setData(fetchedData);
                    const filteredData = filterDataByYearRange(fetchedData, yearRange);
                    const formattedData = formatChartData(filteredData);
                    setChartData(formattedData);
                }
                setLoading(false);
            } catch (error) {
                console.error('Error fetching data:', error);
                setLoading(false);
            }
        };
        fetchData();
    }, []);

    useEffect(() => {
        if (data.length > 0) {
            const filteredData = filterDataByYearRange(data, yearRange);
            const formattedData = formatChartData(filteredData);
            setChartData(formattedData);
        }
    }, [yearRange, data]);

    const handleYearRangeChange = (event) => {
        setYearRange(event.target.value);
    };

    return (
        <>
        <Box>
            <FormControl fullWidth>
                <Select
                    value={yearRange}
                    onChange={handleYearRangeChange}
                >
                    <MenuItem value="2016-2021">2016-2021</MenuItem>
                    <MenuItem value="2022-Onwards">2022-Onwards</MenuItem>
                </Select>
            </FormControl>
            <Box style={{ height: '414px', width: '100%', marginTop: '1rem' }}>
                {loading ? (
                    <p>Loading chart data...</p>
                ) : chartData ? (
                    <Line 
                        data={chartData} 
                        options={{ 
                            maintainAspectRatio: false,
                            plugins: {
                                title: {
                                  display: true,
                                  text: `Monthly Procurement`,
                                  font: { size: 16 },
                                },
                              },
                            scales: {
                                x: {
                                    title: {
                                        display: true,
                                        text: 'Procurement Date'
                                    }
                                },
                                y: {
                                    title: {
                                        display: true,
                                        text: 'Count'
                                    },
                                    beginAtZero: true
                                }
                            }
                        }}
                    />
                ) : (
                    <p>No data available</p>
                )}
            </Box>
        </Box>
        </>
    );
};

export default ProcurementLineGraph;

import React, { useState, useEffect } from "react";
import {
  Button,
  Stack,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  Typography,
} from "@mui/material";
import TableUtility from "../../../Components/TableUtility/TableUtility";
import FileDownloadIcon from "@mui/icons-material/FileDownload";
import "../reportstyle.css";
import { baseUrl } from "../../../Utils/serviceRequest";
import axios from "axios";
// import xlsx from "json-as-xlsx";
import { Box } from "@mui/system";
// import DraggableFileUploader from "../../../Components/DraggableComponent/DraggableFileUploader";
import { ErrorOutline } from "@mui/icons-material";
import ShowData from "./ShowData";
// import * as XLSX from 'xlsx';
import ExcelJS from 'exceljs';
import { saveAs } from 'file-saver';

const AssignReportTable = () => {
  const [pageNumber, setPageNumber] = useState(1);

  const [assignData, setAssignData] = useState([]);
  const [loading, setLoading] = useState(true);

  const [consentDialogStatus, setConsentDialogStatus] = useState(false);
  let ShowRevokeDialog = () => {
    setConsentDialogStatus(true);
  };

  const [assetAllocationId, setAssetAllocationId] = useState("");

  const revokeConsent = async (e) => {
    try {
      let data = await axios.put(
        `${baseUrl}/removeConsentForm/${assetAllocationId}`
      );
      if (data.status === 200) {
        setConsentDialogStatus(false);
      }
    } catch (error) {
      console.error(error);
    }
  };

  const DateString = (timestamp) => {
    if (timestamp !== null) {
      // var time = Number(timestamp);
      var time = Number(timestamp);
      var date = new Date(time + 5.5 * 60 * 60 * 1000)
        .toISOString()
        .substring(0, 10);
      return date;
    }
    return "-";
  };

  // const fetchAssignedData = async () => {
  //   // console.log("hello");
  //   try {
  //     let data = await (
  //             await axios.get(`${baseUrl}/getAssignedLaptops/20/${pageNumber}`)
  //           ).data;
  //     if (data) {
  //       data.forEach((element) => {
  //         // element.assetHandOverDate = DateString(element.assetHandOverDate);
  //         element.assetReturnedDate = DateString(element.assetReturnedDate);
  //         element.assetStDate = DateString(element.assetStDate);
  //         element.assetEndDate = DateString(element.assetEndDate);
  //         element.assetHandOverDate = DateString(element.assetHandOverDate);
  //       });
  //       // console.log(res.data,"12345")
  //       setAssignData((prev) => prev.concat(data));
  //       setLoading(false);

  //     }
  //   } catch (e) {
  //     console.error(e);
  //   }
  // };

  const fetchAssignedData = async () => {
    // console.log("hello");
    // debugger
    try {
      let data = await (await axios.get(`${baseUrl}/getAssetsForReports`)).data;
      if (data) {
              data.forEach((element) => {
              

                  // element.assetReturnedDate = DateString(element.assetReturnedDate);
                  element.assetStDate = DateString(element.assetStDate);
                  // element.assetEndDate = DateString(element.assetEndDate);
                  // element.assetHandOverDate = DateString(element.assetHandOverDate);
                  element.procureDate = DateString(element.procureDate);
               
                // element.assetHandOverDate = DateString(element.assetHandOverDate);
              });
              // console.log(res.data,"12345")
              setAssignData((prev) => prev.concat(data));
              setLoading(false);
      
            }

      // if (data) {
      //   setAssignData(data);
      //   setLoading(false);
      // }
    } catch (e) {
      console.error(e);
    }
  };

  useEffect(() => {
    fetchAssignedData();
  }, []);

  let getNextBatchOfData = () => {
    fetchAssignedData();
  };
  const binaryStringToUint8Array = (binaryString) => {
    const len = binaryString.length;
    const bytes = new Uint8Array(len);
    for (let i = 0; i < len; i++) {
      bytes[i] = binaryString.charCodeAt(i);
    }
    return bytes;
  };

  // const exportData = async () => {
  //   // console.log("hello");
  //   // debugger
  //   try {
  //     let data = await axios.post(
  //       `${baseUrl}/download/laptops.xlsx`,
  //       assignData,
  //       { responseType: "blob" }
  //     );
  //     if (data) {
  //       const link = document.createElement("a");
  //       const url = URL.createObjectURL(data.data);

  //       // Set the download attribute with a file name
  //       link.href = url;
  //       link.download = "Asset Report.xlsx"; // Set the file name

  //       // Append the link to the document
  //       document.body.appendChild(link);

  //       // Programmatically click the link to trigger the download
  //       link.click();
  //     }
  //     console.log(data);
  //   } catch (e) {
  //     console.error(e);
  //   }
  // };

  // const handleDownload = async () => {
  //   debugger
  //   // Step 1: Convert data to Excel file
  //   const ws = XLSX.utils.json_to_sheet(assignData);
  //   const wb = XLSX.utils.book_new();
  //   XLSX.utils.book_append_sheet(wb, ws, 'Sheet1');

  //   const excelBuffer = XLSX.write(wb, { bookType: 'xlsx', type: 'array' });

  //   // Step 2: Create a Blob from the buffer
  //   const data = new Blob([excelBuffer], { type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet' });

  //   // Step 3: Create FormData
  //   const formData = new FormData();
  //   formData.append('file', data, 'laptops.xlsx');

  //   // Log formData content for debugging
  //   for (let pair of formData.entries()) {
  //     console.log(`${pair[0]}: ${pair[1]}`);
  //   }

  //   try {
  //     let response = await axios.post(`${baseUrl}/download/laptops.xlsx`, formData, {
  //       responseType: 'blob',
  //       headers: {
  //         'Content-Type': 'multipart/form-data',
  //       },
  //     });

  //     if (response) {
  //       const link = document.createElement('a');
  //       const url = URL.createObjectURL(response.data);

  //       // Set the download attribute with a file name
  //       link.href = url;
  //       link.download = 'Asset Report.xlsx'; // Set the file name

  //       // Append the link to the document
  //       document.body.appendChild(link);

  //       // Programmatically click the link to trigger the download
  //       link.click();

  //       // Clean up by removing the link
  //       document.body.removeChild(link);
  //       URL.revokeObjectURL(url);
  //     }
  //     console.log(response);
  //   } catch (error) {
  //     console.error('Error uploading or downloading the file:', error);
  //   }
  // };

  


  // const downloadFile = () => {
  //   let data = [
  //     {
  //       sheet: " Assets Report",
  //       columns: [
  //         // { label: "User Name", value: "firstName" }, // Top level data
  //         // {
  //         //   label: "Asset AllocationId",
  //         //   value: "assetAllocationId",
  //         // },
  //         // {
  //         //   label: "Employee Name",
  //         //   value: "EmployeeName",
  //         // },
  //         // {
  //         //   label: "AssetType",
  //         //   value: "assetType",
  //         // },
  //         { label: "Tag No.", value: "assetId" }, // Custom format
  //         { label: "Asset Serial No.", value: "assetSlNo" }, // Custom format
  //         { label: "Model", value: "model" }, // Custom format
  //         { label: "Procure Date", value: "procureDate" }, // Custom format
  //         { label: "Assigned To", value: "assignedTo" }, // Custom format
  //         { label: "Laptop Status", value: "currentStatus" }, // Custom format
  //         { label: "Current Location", value: "location" }, // Custom format
  //         { label: "Base Location", value: "baseLocation" }, // Custom format
  //         { label: "Return Location", value: "returnLocation" }, // Custom format
  //         { label: "Assigned Date", value: "assetStDate" }, // Custom format
  //         { label: "RAM", value: "ram" }, // Custom format
  //         { label: "Storage", value: "storage" }, // Custom format
  //         { label: "Laptop Health", value: "comments" }, // Custom format
  //         // { label: "Assigned By", value: "assignedBy" }, // Custom format
  //         // { label: "Returned By", value: "returnedBy" }, // Custom format
  //         // { label: "Remark", value: "remarks" }, // Custom format
  //         // { label: "Brand", value: "brand" }, // Custom format
  //         // { label: "Processor", value: "processor" }, // Custom format
  //         // { label: "User Status", value: "userStatus" }, // Custom format
  //         // // { label: "Date", value: "assetHandOverDate" }, // Custom format
  //         // { label: "Assigned End Date", value: "assetEndDate" }, // Custom format
  //         // // { label: "Asset Returned Date", value: "assetReturnedDate" }, // Custom format
  //         // { label: "Warranty End Date", value: "warrantyEndDate" }, // Custom format
  //         // { label: "Assigned By", value: "assignedBy" }, // Custom format
  //         // { label: "Return By", value: "returnedBy" }, // Custom format
  //       ],
  //       content: assignData,
  //     },
  //   ];
  //   let settings = {
  //     fileName: "Assets Report",
  //   };
  //   xlsx(data, settings);
  // };

  ///new code check///
 
 
  const downloadFile = async () => {
    let data = [
      {
        sheet: "Assets Report",
        columns: [
          { label: "Tag No.", value: "assetId" },
          { label: "Asset Serial No.", value: "assetSlNo" },
          { label: "Model", value: "model" },
          { label: "Procure Date", value: "procureDate" },
          { label: "Assigned To", value: "assignedTo" },
          { label: "Laptop Status", value: "currentStatus" },
          { label: "Current Location", value: "location" },
          { label: "Base Location", value: "baseLocation" },
          { label: "Return Location", value: "returnLocation" },
          { label: "Assigned Date", value: "assetStDate" },
          { label: "RAM", value: "ram" },
          { label: "Storage", value: "storage" },
          { label: "Laptop Health", value: "comments" },
        ],
        content: assignData,
      },
    ];
  
    const workbook = new ExcelJS.Workbook();
    data.forEach((sheetData) => {
      const worksheet = workbook.addWorksheet(sheetData.sheet);
  
       // Add columns with headers
    worksheet.columns = sheetData.columns.map((col) => ({
      header: col.label,
      key: col.value,
    }));

    // Add data rows
    sheetData.content.forEach((row) => {
      worksheet.addRow(row);
    });

    // Set header row style
    worksheet.getRow(1).eachCell((cell) => {
      cell.fill = {
        type: 'pattern',
        pattern: 'solid',
        fgColor: { argb: 'FFFF00' }, // Yellow background
      };
      cell.font = {
        bold: true,
      };
    });

    // Auto adjust column width
    worksheet.columns.forEach((column) => {
      let maxLength = 0;
      column.eachCell({ includeEmpty: true }, (cell) => {
        const cellValue = cell.value ? cell.value.toString() : '';
        maxLength = Math.max(maxLength, cellValue.length);
      });
      column.width = maxLength < 10 ? 10 : maxLength + 2;
    });
  });
  
    // Write to a buffer
    const buffer = await workbook.xlsx.writeBuffer();
    
    // Save the file
    const blob = new Blob([buffer], { type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet' });
    saveAs(blob, 'Assets Report.xlsx');
  };
 
 
  const processedData = assignData.map((row) => ({
    ...row,
    userName: `${row.firstName} ${row.lastName}`,
  }));

  ///end///

  return (
    <Stack style={{ position: "relative" }}>
      <Box
        sx={{ display: "flex" }}
        style={{
          position: "absolute",
          top: "-44px",
          right: "0",
          width: "20%",
        }}
      >
        <Box
          sx={{
            flexGrow: "1",
            display: "flex",
            justifyContent: "flex-end",
            gap: "2%",
            alignItems: "center",
          }}
        >
          <Button
            variant="contained"
            endIcon={<FileDownloadIcon />}
            sx={{
              border: "1px solid #F1F5FE",
              backgroundColor: "#F1F5FE",
              color: "#0288d1",
              fontSize: "12px",
              textTransform: "capitalize",
              height: "max-content",
              "&:hover": {
                backgroundColor: "#F1F5FE",
                color: "#0288d1",
                border: "1px solid #0288d1",
                boxShadow: "1px #000000",
              },
            }}
            onClick={() => {
              // exportData();
              // handleDownload();
              downloadFile();
            }}
          >
            Export
          </Button>
        </Box>
      </Box>
      {/* <Box alignItems="center" display="flex" style={{ padding: "0" }}>
        <TableUtility
          getNextBatchOfData={() => getNextBatchOfData()}
          Data={processedData}
          headerkeys={[
            "",
            "User Name",
            "Email",
            "EmployeeID",
            "Tag No.",
            "Remark",
            "Brand",
            "Config",
            "Date",
            "Base Location",
            "User Location"
          ]}
          bodykeys={[
            "",
            // "firstName",
            "userName",
            "email",
            "employeeId",
            "assetId",
            "reason",
            "brand",
            "processor",
            "assetHandOverDate",
            "baseLocation",
            "userLocation"
          ]}
          isCollapsable={true}
          CollapseTitle={" Configuration and Specifications"}
          CollapseHeaderFields={[
            "MODEL",
            "OS",
            "GENERATION",
            "PROCESSOR",
            "RAM",
            "STORAGE",
          ]}
          CollapseDataKeys={[
            "model",
            "oS",
            "gen",
            "processor",
            "ram",
            "storage",
          ]}
          enableDelete={false}
          enableEdit={false}
          height={"78vh"}
          showRevokeConsentButton={true}
          ShowRevokeDialog={ShowRevokeDialog}
          setAssetAllocationId={setAssetAllocationId}
        />
      </Box> */}
      {/*new code test */}
      <ShowData
        data={processedData}
        userLocation={true}
        assetHandOverDate={true}
        isLoading={loading}
      />
      {/* end */}
      <Dialog open={consentDialogStatus}>
        <DialogTitle sx={{ textAlign: "center" }}>
          <ErrorOutline sx={{ color: "#FF9A22", fontSize: "2.3rem" }} />
          <Typography variant="h6">Are you sure?</Typography>
          <Typography sx={{ fontSize: "0.9rem" }}>
            <span>Are you sure you want to revoke this consent form?</span>
            <br />
            <span>This process cannot be undone.</span>
          </Typography>
        </DialogTitle>

        <DialogActions>
          <Button
            onClick={(e) => revokeConsent(e)}
            variant="contained"
            sx={{
              backgroundColor: "#0288d1",
              textTransform: "none",
              "&:hover": {
                backgroundColor: "#140B96",
                boxShadow: "1px #000000",
              },
            }}
            disableRipple
          >
            Revoke
          </Button>
          <Button
            onClick={() => setConsentDialogStatus(false)}
            variant="contained"
            sx={{
              backgroundColor: "#E0E0E0",
              textTransform: "none",
              color: "black",
              "&:hover": {
                backgroundColor: "#E0E0E0",
                boxShadow: "1px #000000",
              },
            }}
            disableRipple
          >
            Cancel
          </Button>
        </DialogActions>
      </Dialog>
    </Stack>
  );
};

export default AssignReportTable;

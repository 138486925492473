import { combineReducers } from "redux";

import userReducer from "./userReducer";
import appReducer from "./appReducer";
import formReducer  from "./formReducers";

const reducers = combineReducers({
  appReducer: appReducer,
  userReducer: userReducer,
  formReducer: formReducer
});

export default reducers;

import React from "react";
import LaptopIcon from "@mui/icons-material/Laptop";
import { Box, Paper, Tooltip, Typography } from "@mui/material";
import CountUp, { useCountUp } from "react-countup";
import { fontWeight } from "@mui/system";
import {
  AssignmentLate,
  AssignmentTurnedIn,
  Inventory,
  PhoneIphone,
} from "@mui/icons-material";
import "./HomeInfoCard.css";

const HomeInfoCards = ({
  statusCount,
  
}) => {
 

  const textColor = (type) => {
  
    if (type === 1) {
      return "#4CAF50";
    } else if (type === 5) {
      return "#F44336";
    } else {
      return "#3026B9";
    }
  };
  return (
    // <Paper sx={{margin: '1em', height: '84px', backgroundColor: '#F1F5FE'}} elevation={4}>

    <Box className="home-info-cards">
      {statusCount.map((e, index) => (
        // <Tooltip title={<Element items={e.details} />} arrow>
          <Paper
            className="home-info-individual-card"
            style={{
              backgroundColor: "#F1F5FE",
              padding: "8px",
              cursor: "pointer",
            }}
            elevation={3}
            sx={{
              width: "150px",
              height: "80px",
              display: "flex",
              flexDirection: "column",
              justifyContent: "center",
              alignItems: "center",
            }}
          >
            <div
              style={{
                width: "100%",
                display: "flex",
                flexDirection: "column",
                justifyContent: "center",
                alignItems: "center",
              }}
            >
              {/* {e.icon} */}
              <Typography variant="h4" fontSize={"90%"} textAlign={"center"}>
                {(e.status_id===1)?"Assigned":(e.status_id===2)?"Disposed":(e.status_id===3)?"Donated":(e.status_id===4)?"Lost And Recovered":(e.status_id===5)?"Spare":"Damaged"}
              </Typography>
            </div>
            <CountUp start={0} end={e.count} delay={0}>
              {({ countUpRef }) => (
                // <Typography variant='h4' fontSize={'177%'} color='#007AD4'>{e.qty}</Typography>
                <>
                  <span
                    style={{
                      fontSize: "180%",
                      color: textColor(e.status_id),
                      fontWeight: "600",
                    }}
                    ref={countUpRef}
                  />
                </>
              )}
            </CountUp>
          </Paper>
        // {/* </Tooltip> */}
      ))}
    </Box>
    // </Paper>
    // <Paper sx={{margin: '1em', height: '84px', backgroundColor: '#F1F5FE'}} elevation={4}>

    //     <Box sx={{height: '100%', display: 'flex', justifyContent: 'space-around', alignItems: 'center'}}>
    //     {
    //     data.map((e, index) => (
    // <div style={{display: 'flex', justifyContent: 'space-around', alignItems: 'center'}}>
    //         {e.icon}
    //         <Typography variant='h4' fontSize={18} style={{marginRight: '1.2em'}}>{e.type}</Typography>
    //         <Typography variant='h4' fontSize={18} color='#007AD4'>{e.qty}</Typography>
    //         {
    //             index!==data.length-1 ? <div className='home-info-separator'></div> : null
    //         }
    //         </div>
    //     ))
    //     }
    //     </Box>
    // </Paper>
  );
};

export default HomeInfoCards;

const Element = ({ items = {} }) => {
  return (
    <>
      {Object.keys(items).map((key) => (
        <p>
          {key} : {items[key]}
        </p>
      ))}
    </>
  );
};

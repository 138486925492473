import React, { useEffect, useState } from "react";
import "./Home.css";
import Paper from "@mui/material/Paper";
import Box from "@mui/material/Box";
import {
  Chart as ChartJS,
  ArcElement,
  Tooltip,
  Legend,
  CategoryScale,
  LinearScale,
  LineElement,
  PointElement,
  Title,
} from "chart.js";
import DoughnutGraphCard from "../../Components/Charts/DoughnutGraphCard";
import HomeInfoCards from "../../Components/HomeInfoCards/HomeInfoCards";
import { useDispatch } from "react-redux";
import { setSelectedNavIndex } from "../../redux/reducers/appReducer";
import axios from "axios";
import { baseUrl, doAxiosCall } from "../../Utils/serviceRequest";
import LocAssetBarChart from "../../Components/Charts/LocAssetBarChart";
import ProcurementLineGraph from "../../Components/Charts/ProcurementLineGraph";
import LocClassificationBarCard from "../../Components/Charts/LocClassificationBarCard";
import { Grid } from "@mui/material";

ChartJS.register(
  ArcElement,
  Tooltip,
  Legend,
  CategoryScale,
  LinearScale,
  LineElement,
  PointElement,
  Title
);

const Home = () => {
  const dispatch = useDispatch();
  const [spareLaptop, setSpareLaptop] = useState([]);
  const [brand, setBrand] = useState([]);
  const [statusCount, setStatusCount] = useState([]);
  const [assetCountData, setAssetCountData] = useState({});

  useEffect(() => {
    dispatch(setSelectedNavIndex(0));

    const fetchData = async () => {
      doAxiosCall({
        method: "get",
        url: "/getAllCount",
        successFunc: function (data) {
          setAssetCountData(data);
        },
        errorFunc: function (err) {
          console.error(err);
        },
      });
    };

    const fetchSpareLaptop = async () => {
      try {
        const response = await axios.get(`${baseUrl}/getSpareCount`);
        if (response.data) {
          setSpareLaptop(response.data.data);
        }
      } catch (error) {
        console.log("error", error.message);
      }
    };

    const fetchBrand = async () => {
      try {
        const response = await axios.get(`${baseUrl}/getBrandCount`);
        if (response.data) {
          setBrand(response.data.data);
        }
      } catch (error) {
        console.log("error", error.message);
      }
    };

    const fetchStatusCount = async () => {
      try {
        const response = await axios.get(`${baseUrl}/getStatusCount`);
        if (response.data) {
          setStatusCount(response.data.data);
        }
      } catch (error) {
        console.log("error", error.message);
      }
    };

    fetchData();
    fetchStatusCount();
    fetchSpareLaptop();
    fetchBrand();
  }, [dispatch]);

  return (
    <>
      <HomeInfoCards statusCount={statusCount} />
      

      <Box sx={{ margin: '0.8em 3.6em 1.6em 3.6em' }}>
      <Grid container spacing={2}>
        <Grid item xs={4} >
        {/* <Box sx={{ height: "470px" }}> */}
          <DoughnutGraphCard
            assetCountData={assetCountData}
            spareLaptop={spareLaptop}
            brand={brand}
          />
            {/* </Box> */}
        </Grid>
        <Grid item xs={8} >
          <Paper elevation={4} >
            <LocClassificationBarCard />
          </Paper>
        </Grid>
        <Grid item xs={6}  >
          <Paper elevation={4} >
            <LocAssetBarChart />
          </Paper>
        </Grid>
        <Grid item xs={6} >
          <Paper elevation={4} >
            <ProcurementLineGraph />
          </Paper>
        </Grid>
      </Grid>
    </Box>



      {/* <Box
        className="layout1"
        sx={{
          display: "flex",
          // minHeight: "60%",
          margin: "0.8em 3.6em 1.6em 3.6em",
          flexDirection: "column",
        }}
      >
        <Box
          sx={{
            display: "flex",
            flexDirection: "row",
            justifyContent: "space-between",
            // height: "100%",
          }}
        >
          <Box sx={{ flex: 3, marginRight: "1em" }}>
            <DoughnutGraphCard
              assetCountData={assetCountData}
              spareLaptop={spareLaptop}
              brand={brand}
            />
          </Box>
          <Box
            sx={{
              flex: 7,
              display: "flex",
              flexDirection: "column",
              height: "100%",
              justifyContent: "space-between",
            }}
          >
            <Paper elevation={4}>
              <LocClassificationBarCard />
            </Paper>
          </Box>
        </Box>
        <Box
          sx={{
            marginTop: "1rem",
            height: "40%",
            display: "flex",
            flexDirection: "row",
            justifyContent:"space-between"
          }}
        >
          <Paper sx={{  width:"49%" }} elevation={4}>
            <LocAssetBarChart />
          </Paper>
          <Paper sx={{ width:"49%" }} elevation={4}>
            <ProcurementLineGraph />
          </Paper>
        </Box>
      </Box> */}
      {/* <Box className="layout2" style={{ minHeight: "60%" }}>
        <Box
          sx={{
            display: "flex",
            height: "55%",
            margin: "0.8em 3.6em 1.6em 3.6em",
            justifyContent: "space-between",
          }}
        >
          <Box sx={{ flex: 3, marginRight: "1em" }}>
            <DoughnutGraphCard
              assetCountData={assetCountData}
              spareLaptop={spareLaptop}
              brand={brand}
            />
          </Box>
          <Box sx={{ flex: 7 }}>
            <Paper
              sx={{
                height: "100%",
                boxShadow: "0px 4px 4px 0px rgba(0, 0, 0, 0.25)",
                borderRadius: "12px",
              }}
            >
              <LocationBarChart />
            </Paper>
          </Box>
        </Box>
        <Box>
          <Paper
            sx={{
              height: "70%",
              boxShadow: "0px 4px 4px 0px rgba(0, 0, 0, 0.25)",
              borderRadius: "12px",
            }}
          >
            <ProcurementLineGraph />
          </Paper>
        </Box>
      </Box> */}
    </>
  );
};

export default Home;

import React, { useCallback } from "react";
import { useDispatch, useSelector } from "react-redux";
import TableUtility from "../../Components/TableUtility/TableUtility";
import {
  setAdminCornerInventoryAssignedData,
  setSelectedNavIndex,
  setAdminCornerInventoryPrimaryData
} from "../../redux/reducers/appReducer";
import xlsx from "json-as-xlsx";
import {
  Grid,
  Paper,
  Typography,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  createTheme,
  ThemeProvider,
  TextField,
  Snackbar,
  Alert,
  Stack,
  MenuItem,
  FormControl,
  InputLabel,
  Backdrop,
  CircularProgress,
  Autocomplete,
  ListItemText,
  ListItem,
} from "@mui/material";
import { Box,  } from '@mui/material';
import { useState, useEffect } from "react";
import ErrorOutlineIcon from "@mui/icons-material/ErrorOutline";
import FilterAltIcon from "@mui/icons-material/FilterAlt";
import UploadFileIcon from "@mui/icons-material/UploadFile";
import AddCircleIcon from "@mui/icons-material/AddCircle";
import FileDownloadIcon from "@mui/icons-material/FileDownload";
import DeleteIcon from "@mui/icons-material/Delete";
import axios from "axios";
// import { Box } from "@mui/system";
import {
  CheckBox,
  ContactsOutlined,
  SearchOutlined,
} from "@mui/icons-material";
import { baseUrl } from "../../Utils/serviceRequest";

const adminAssetsOptions = ["Pen", "Notebook", "Bottle", "T-shirt", "Bag"];
function Admin() {

    const dispatch = useDispatch();
    dispatch(setSelectedNavIndex(5));
  
    const [deleteModalStatus, setDeleteModalStatus] = useState(false);
    const [editModalStatus, setEditModalStatus] = useState(false);
    const [AddModalStatus, setAddModalStatus] = useState(false);
    const [DeletedModalStatus, setDeletedModalStatus] = useState(false);
    const [SuccessStatus, setSuccessStatus] = useState(false);
    const [FailedStatus, setFailedStatus] = useState(false);
    const [prefix, setPrefix] = useState("INC/BBSR/LT");
    const [AssetType, setAssetType] = useState("All Other Assets");
    //DElETE RELATED OPERATIONS
    const [SelectedAssets, setSelectedAssets] = useState([]);
    const [SelectAll, setSelectAll] = useState(false);
    const [DeleteMethod, setDeleteMethod] = useState(null);
    const [OtherAssetsData, setOtherAssetsData] = useState([]);
  
    const appReducerState = useSelector((state) => state.appReducer);


    const fetchData = async () => {
        try {
          let res = await axios.get(
            `${baseUrl}/getAdminCornerDetails/10/${appReducerState.adminCornerInventoryData.primaryData.pageNumber}`
          );
    
          if (res.status === 200) {
            // setInventoryData((prev) => prev.concat(res.data));
            dispatch(setAdminCornerInventoryPrimaryData(res.data));
          }
        } catch (e) {
          console.error(e);
        }
      };
    
      const fetchAssignedData = async () => {
        try {
          let res = await axios.get(
            `${baseUrl}/getAdminCornerAssignedDetails/20/${appReducerState.adminCornerInventoryData.assignedData.pageNumber}`
          );
    
          if (res.status === 200) {
            console.log("here")
            // setInventoryData((prev) => prev.concat(res.data));
            dispatch(setAdminCornerInventoryAssignedData(res.data));
          }
        } catch (e) {
          console.error(e);
        }
      };
    
      useEffect(() => {
        // setOtherAssetsData([...Data.slice(0,10)])
        fetchData();
        fetchAssignedData();
      }, []);
      // ***********all state variables **
    
      const addSelectedAsset = (e) => {
        let value = e.target.checked;
        let method = e.target.dataset.method;
        if (method == "SELECTALL") {
          setSelectAll(true);
          setDeleteMethod(method);
        }
    
        if (method == "SELECTMULTIPLE") {
          let asset = e.target.dataset.assetid;
          if (value === true) {
            setSelectedAssets([...SelectedAssets, asset]);
            setDeleteMethod(method);
          }
          if (value === false) {
            let array = SelectedAssets.filter((item) => {
              return item !== asset; //returns false if selectedasset state contains assetid
            });
            setSelectedAssets(array);
            setDeleteMethod(method);
          }
        }
      };
      //------
      const [ReceivedIdFromTable, setReceivedIdFromTable] = useState(null); // Id replaced to ReceivedIdFRomTable, ID RECEIVED FROM TABLE COMPONENT,
    
      const [EditFormData, setEditFormData] = useState({
        assetName: adminAssetsOptions[0],
        assetQty: 0,
      });
    
      const createAsset = async () => {
        let stateData = { ...EditFormData };
        try {
          let data = await axios.post(`${baseUrl}/createAdminCornerDetails`, {
            assetName: stateData?.assetName,
            assetQty: stateData?.assetQty,
          });
          if (data) {
            handleClose();
            setSuccessStatus(true);
          }
        } catch (error) {
          console.error(error);
        }
      };
      const downloadFile = () => {
        let data = [
          {
            sheet: "sheet1",
            columns: [
              { label: "Id", value: "id" },
              { label: "Asset Name", value: "assetName" }, // Top level data
    
              { label: "Quantity", value: "assetQty" }, // Custom format
            ],
            content: appReducerState.adminCornerInventoryData.primaryData.data,
          },
        ];
        let settings = {
          fileName: "AdminCorner",
        };
        xlsx(data, settings);
      };
    
      let handleOnChange = (e) => {
        //single function handling both updating the data and adding the data, form data is handled in a single EditFromData state reason for data structure being same
        let value = e.target.value;
        let name = e.target.name;
        let stateData = { ...EditFormData };
    
        stateData[name] = value;
        setEditFormData(stateData);
      };
    
      let handleDelete = async (e) => {
        //handling the delete process, Id is received from Id state
        setDeleteModalStatus(false);
        let data;
        let selectedIds = [];
    
        if (DeleteMethod === "DELETEONE") {
          selectedIds = [ReceivedIdFromTable];
    
          setOtherAssetsData((current) =>
            current.filter((item) => {
              return !selectedIds.includes(item.id); //returns false if selectedasset state contains assetid
            })
          );
          setDeleteMethod(null);
          setReceivedIdFromTable(null);
        }
    
        if (DeleteMethod === "SELECTALL") {
          setOtherAssetsData([]);
          setSelectAll(false);
          setDeleteMethod(null);
        }
    
        if (DeleteMethod === "SELECTMULTIPLE") {
          selectedIds = [...SelectedAssets];
          let array = OtherAssetsData.filter((item) => {
            return !selectedIds.includes(item.id); //returns false if selectedasset state contains assetid
          });
          setOtherAssetsData(array);
          setSelectAll(false);
          setSelectedAssets([]);
          setDeleteMethod(null);
        }
      };
      let handleSave = (e) => {
        //  contains to functions for handling adding new data process and updating the existing data process
    
        let handleEdit = () => {
          //if editModalStatus is true? this functions is executed for handling updating data  process
          let updatedData = OtherAssetsData.map((item) => {
            //masterdata gets looped and id is added to the masterdata here
            return item.assetId === ReceivedIdFromTable ? EditFormData : item;
          });
          // axios.post('http://localhost:9090/cw/icm/post/agingbucket/updateAgingBucket', EditFormData)
          setOtherAssetsData(updatedData); //updating masterdata with new data
          setEditFormData({
            assetName: adminAssetsOptions[0],
            assetQty: 0,
          });
          setEditModalStatus(false); //closing the the edit modal
        };
    
        let handleAdd = async () => {
          try {
            console.log("add");
          } catch (error) {
            console.error(error);
          }
          //   let res = axios.post('http://localhost:9090/cw/icm/post/addAgingBucket', EditFormData) // data is sent to database from the EditFormData state variable
          //     .then((response) => {
          //       if (response.status === 200) {
          //         SuccessStatus(true)
          //       }
          //       if (response.status != 200) {
          //         FailedStatus(true)
          //       }
          //     }
          //   setEditFormData({
          //     assetBrand: "",
          //     assetName: "",
          //     assetQty: 0,
          //     assetType: "",
          //     date: "",
          //   });
    
          //handling updating of data on ui side **/
          let updatedData = [...OtherAssetsData]; //destructured agingbucket array
          updatedData.push({
            ...EditFormData,
            assetId: OtherAssetsData.length + 1,
          }); //pushed the new data
          setOtherAssetsData(updatedData);
          handleClose();
        };
    
        editModalStatus && handleEdit();
        AddModalStatus && handleAdd();
      };
    
      // functions to handle display and hidding of modals*******************
      let handleClose = (e) => {
        // handling closing for all modals, and then updates the EditFormData to a blank template to make clear form input values //problem was in switching from edit process to add process.
        setDeleteModalStatus(false);
        setEditModalStatus(false);
        setAddModalStatus(false);
        setEditFormData({
          assetName: adminAssetsOptions[0],
          assetQty: 0,
        });
      };
      let ShowDeleteModalFunc = (receivedIdFromTable, method) => {
        method === "DELETEONE" && setReceivedIdFromTable(receivedIdFromTable);
        method === "DELETEONE" && setDeleteMethod(method);
        setDeleteModalStatus(true);
      };
    
      //**********showing add modal ********/
    
      let ShowAddModal = () => {
        setAddModalStatus(true);
      };
    
      //**********showing edit modal ********/
    
      let ShowEditModalFunc = (receivedIdFromTable) => {
        receivedIdFromTable && setReceivedIdFromTable(receivedIdFromTable);
        OtherAssetsData.map((item) => {
          if (item.id === receivedIdFromTable) {
            setEditFormData({ ...EditFormData, ...item });
          }
        });
        setEditModalStatus(true);
      };
    
      //*function to get next batch of data for the table for infinite scrolling*/
      let getNextBatchOfData = () => {
        fetchData();
      };
    
      let getNextBatchOfAssignedData = () => {
        fetchAssignedData();
      };
    
      const inputSize = {
        style: {
          height: "5vh",
          maxHeight: "32px",
          width: "15em",
          padding: "2px 10px",
        },
      };
    
      let dialogTheme = createTheme({
        components: {
          MuiDialogTitle: {
            styleOverrides: {
              root: {
                paddingTop: "10px",
              },
            },
          },
          MuiGrid: {
            styleOverrides: {
              root: {
                paddingTop: "10px",
              },
            },
          },
          MuiPaper: {
            styleOverrides: {
              root: {
                padding: "10px",
              },
            },
          },
        },
      });
      let options = {
        getNextBatchOfData,
        IdKey: "id",
        ShowEditModalFunc,
        ShowDeleteModalFunc,
        ShowAddModal,
        Data: appReducerState.adminCornerInventoryData.primaryData.data,
        headerkeys: ["", "ID", "Asset Name", "Quantity"],
        bodykeys: ["", "id", "assetName", "assetQty"],
        tableTitle: AssetType,
      };
    
      let assignedOptions = {
        getNextBatchOfAssignedData,
        IdKey: "id",
        Data: appReducerState.adminCornerInventoryData.assignedData.data,
        headerkeys: ["","ID","EmployeeId", "userId", "Asset Name", "Quantity"],
        bodykeys: ["", "id","employeeId","userId", "assetName", "assignedQty"],
      };
    
      // Assign form part
    
      const date = new Date(Date.now()).toISOString().substr(0, 10);
      const [assignFormData, setAssignFormData] = useState({
        userId: "",
        assetName: adminAssetsOptions[0],
        assignedQty: 0,
      });
    
      const dateInputSize = {
        style: {
          height: "4vh",
          maxHeight: "32px",
          padding: "2px 10px",
          paddingLeft: "0px",
          width: "17em",
        },
        disabled: true,
      };
    
      let dateTheme = createTheme({
        components: {
          MuiInputBase: {
            styleOverrides: {
              root: {
                height: "4vh",
                maxHeight: "32px",
              },
            },
          },
          MuiOutlinedInput: {
            styleOverrides: {
              input: {
                height: "4vh",
                maxHeight: "32px",
                padding: "2px 10px",
              },
            },
          },
        },
      });
    
      const [userSearchData, setUserSearchData] = useState([]);
    
      const [userSelected, setUserSelected] = useState({});
    
      const [AssignModalStatus, setAssignModalStatus] = useState(false);
    
      //loading state
      const [isLoading, setIsLoading] = useState(false);
    
      const [snackBarStatus, setSnackBarStatus] = useState(false);
      const [snackBarMessage, setSnackBarMessage] = useState("");
      const [snackBarSeverity, setSnackBarSeverity] = useState("success");
    
      const debounce = (func) => {
        let timer;
        return function (...args) {
          const context = this;
          if (timer) clearTimeout(timer);
          timer = setTimeout(() => {
            timer = null;
            func.apply(context, args);
          }, 500);
        };
      };
    
      const handleChange = async (value) => {
        try {
          let data = await (
            await axios.get(`${baseUrl}/getDetailsByNameOrEmail?name=${value}`)
          ).data;
          if (data) {
            setUserSearchData(data);
          }
        } catch (error) {}
      };
    
      const assignAsset = async () => {
        try {
          setIsLoading(true);
          let res = await axios.post(
            `${baseUrl}/createAdminCornerAssignedDetails`,
            assignFormData
          );
          if (res.status === 200) {
            setUserSelected({});
            setIsLoading(false);
            if (res.data.result === "success") {
              setSnackBarSeverity("success");
              setSnackBarMessage("Assigned Successfully");
            } else {
              setSnackBarSeverity("error");
              setSnackBarMessage(res.data.result);
            }
            setSnackBarStatus(true);
          }
        } catch (error) {
          console.error(error);
          setIsLoading(false);
          setSnackBarMessage("Oops!! Something Went Wrong");
          setSnackBarSeverity("error");
          setSnackBarStatus(true);
        }
      };
    
      const optimizedFn = useCallback(debounce(handleChange), []);
    
      const handleOnFormChange = (e) => {
        let name = e.target.name;
        let value = e.target.value;
        let tempForm = { ...assignFormData };
        tempForm[name] = value;
        setAssignFormData(tempForm);
      };
    

  return (
    <>
    <div
        style={{
          display: "flex",
          flexDirection: "row",
          height: "calc(100vh - 52px)",
        }}
      >
    

      <Box sx={{ margin: "1.5em", width: "35%" }}>
          <Box sx={{}}>
            <Typography
              variant="h6"
              sx={{ padding: "0 0 0 3%", fontWeight: "bold" }}
            >
              Admin Corner
            </Typography>
          </Box>
          <Stack style={{ position: "relative" }}>
            <Box
              sx={{ display: "flex" }}
              style={{
                position: "absolute",
                top: "-40px",
                right: "0",
                width: "70%",
              }}
            >
              <Box
                sx={{
                  flexGrow: "1",
                  display: "flex",
                  justifyContent: "flex-end",
                  paddingRight: "20px",
                  gap: "2%",
                  alignItems: "center",
                }}
              >
                <Button
                  variant="contained"
                  endIcon={<FileDownloadIcon />}
                  sx={{
                    border: "1px solid #F1F5FE",
                    backgroundColor: "#F1F5FE",
                    color: "#0288d1",
                    fontSize: "12px",
                    textTransform: "capitalize",
                    height: "max-content",
                    "&:hover": {
                      backgroundColor: "#F1F5FE",
                      color: "#0288d1",
                      border: "1px solid #0288d1",
                      boxShadow: "1px #000000",
                    },
                  }}
                  onClick={() => {
                    downloadFile();
                  }}
                >
                  Export
                </Button>
                <Button
                  variant="contained"
                  startIcon={<AddCircleIcon />}
                  sx={{
                    border: "1px solid #F1F5FE",
                    backgroundColor: "#F1F5FE",
                    color: "#0288d1",
                    fontSize: "12px",
                    textTransform: "capitalize",
                    height: "max-content",
                    "&:hover": {
                      backgroundColor: "#F1F5FE",
                      color: "#0288d1",
                      border: "1px solid #0288d1",
                      boxShadow: "1px #000000",
                    },
                  }}
                  onClick={ShowAddModal}
                >
                  {" "}
                  Add
                </Button>
              </Box>
            </Box>
            <Box style={{ padding: "0 20px", alignItems: "center" }}>
              <TableUtility
                {...options}
                height={"70vh"}
                enableDelete={false}
                enableEdit={false}
              />
            </Box>
          </Stack>
        </Box>



         <Box sx={{ margin: "1.5em", width: "65%" }}>
          <Box sx={{}}>
            <Typography
              variant="h6"
              sx={{ padding: "0 0 0 3%", fontWeight: "bold" }}
            >
              Assigned Items
            </Typography>
          </Box>
          <Stack style={{ position: "relative" }}>
            <Box
              sx={{ display: "flex" }}
              style={{
                position: "absolute",
                top: "-40px",
                right: "0",
                width: "70%",
              }}
            >
              <Box
                sx={{
                  flexGrow: "1",
                  display: "flex",
                  justifyContent: "flex-end",
                  paddingRight: "20px",
                  gap: "2%",
                  alignItems: "center",
                }}
              >
                <Button
                  variant="contained"
                  endIcon={<FileDownloadIcon />}
                  sx={{
                    border: "1px solid #F1F5FE",
                    backgroundColor: "#F1F5FE",
                    color: "#0288d1",
                    fontSize: "12px",
                    textTransform: "capitalize",
                    height: "max-content",
                    "&:hover": {
                      backgroundColor: "#F1F5FE",
                      color: "#0288d1",
                      border: "1px solid #0288d1",
                      boxShadow: "1px #000000",
                    },
                  }}
                  onClick={() => {
                    // downloadFile();
                  }}
                >
                  Export
                </Button>
                <Button
                  variant="contained"
                  startIcon={<AddCircleIcon />}
                  sx={{
                    border: "1px solid #F1F5FE",
                    backgroundColor: "#F1F5FE",
                    color: "#0288d1",
                    fontSize: "12px",
                    textTransform: "capitalize",
                    height: "max-content",
                    "&:hover": {
                      backgroundColor: "#F1F5FE",
                      color: "#0288d1",
                      border: "1px solid #0288d1",
                      boxShadow: "1px #000000",
                    },
                  }}
                  onClick={() => setAssignModalStatus(true)}
                >
                  {" "}
                  Assign
                </Button>
              </Box>
            </Box>
            <Box style={{ padding: "0 20px", alignItems: "center" }}>
              <TableUtility
                {...assignedOptions}
                height={"70vh"}
                enableDelete={false}
                enableEdit={false}
              />
            </Box>
          </Stack>
        </Box>

        <Dialog open={AssignModalStatus} sx={{ padding: "20px" }}>
          <DialogContent sx={{ padding: "10px" }} className="wbScroll">
              <Box
                sx={{
                  display: "flex",
                  alignItems: "center",
                  flexDirection: "column",
                  height: "100%",
                  justifyContent: "center",
                }}
              >
                <Box>
                  <Box style={{ padding: "0.8em 2em" }}>
                    <Backdrop
                      sx={{
                        color: "#fff",
                        zIndex: (theme) => theme.zIndex.drawer + 1,
                      }}
                      open={isLoading}
                    >
                      <CircularProgress color="inherit" />
                    </Backdrop>
                    <Autocomplete
                      onChange={(event, value) => {
                        setUserSelected(value);
                        setAssignFormData((prev) => {
                          return { ...prev, userId: value.userId };
                        });
                      }}
                      options={userSearchData}
                      getOptionLabel={(option) => option.userId}
                      freeSolo
                      disableClearable
                      renderOption={(props, option) => (
                        <ListItem {...props}>
                          <ListItemText
                            primary={`${option.firstName} ${option.lastName}`}
                            secondary={option.userId}
                          />
                        </ListItem>
                      )}
                      renderInput={(params) => {
                        console.log(params);
                        return (
                          <TextField
                            {...params}
                            placeholder="Search for User"
                            InputProps={{
                              ...params.InputProps,
                              type: "search",
                              style: {
                                padding: "0px 4px",
                                width: "17em",
                              },
                              endAdornment: <SearchOutlined color="disabled" />,
                            }}
                            onChange={(e) => optimizedFn(e.target.value)}
                          />
                        );
                      }}
                    />

                    {/* //idhar */}
                    <ThemeProvider theme={dateTheme}>
                      <Grid mt={2} container gap={2}>
                        <Grid item sm={6} xs={12}>
                          <InputLabel
                            sx={{
                              color: "#424242",
                              fontSize: "16px",
                              fontWeight: "400",
                              lineHeight: "24px",
                              letterSpacing: "0.005em",
                              marginBottom: "1%",
                              maxHeight: "32px",
                            }}
                          >
                            Name
                          </InputLabel>
                          <FormControl sx={{ width: "100%" }}>
                            <TextField
                              id="outlined-basic"
                              variant="outlined"
                              disabled
                              InputProps={inputSize}
                              value={`${userSelected?.firstName ?? ""} ${
                                userSelected?.lastName ?? ""
                              }`}
                            />
                          </FormControl>
                        </Grid>
                        <Grid item sm={6} xs={12}>
                          <InputLabel
                            sx={{
                              color: "#424242",
                              fontSize: "16px",
                              fontWeight: "400",
                              lineHeight: "24px",
                              letterSpacing: "0.005em",
                              marginBottom: "1%",
                              maxHeight: "32px",
                            }}
                          >
                            Email ID
                          </InputLabel>
                          <FormControl sx={{ width: "100%" }}>
                            <TextField
                              id="outlined-basic"
                              variant="outlined"
                              disabled
                              InputProps={inputSize}
                              value={`${userSelected?.userId ?? ""}`}
                            />
                          </FormControl>
                        </Grid>
                        <Grid item sm={6} xs={12}>
                          <Typography sx={{ color: "#424242" }}>
                            Asset Name
                          </Typography>
                          <TextField
                            select
                            SelectProps={inputSize}
                            margin="normal"
                            id="outlined-basic"
                            variant="outlined"
                            name="assetName"
                            value={assignFormData.assetName}
                            defaultValue={assignFormData.assetName}
                            onChange={handleOnFormChange}
                          >
                            {adminAssetsOptions.map((val) => {
                              return <MenuItem value={val}>{val}</MenuItem>;
                            })}
                          </TextField>
                        </Grid>
                        <Grid item sm={6} xs={12}>
                          <Typography sx={{ color: "#424242" }}>
                            Quantity
                          </Typography>
                          <TextField
                            type={"number"}
                            InputProps={inputSize}
                            margin="normal"
                            id="outlined-basic"
                            variant="outlined"
                            name="assignedQty"
                            inputProps={{ min: 0 }}
                            value={assignFormData.assignedQty}
                            onChange={handleOnFormChange}
                          />
                        </Grid>
                      </Grid>
                    </ThemeProvider>
                  </Box>
                  <Snackbar
                    anchorOrigin={{ vertical: "top", horizontal: "center" }}
                    open={snackBarStatus}
                    autoHideDuration={6000}
                    onClose={() => setSnackBarStatus(false)}
                  >
                    <Alert
                      onClose={() => setSnackBarStatus(false)}
                      severity={snackBarSeverity}
                      sx={{ width: "100%" }}
                    >
                      {snackBarMessage}
                    </Alert>
                  </Snackbar>
                </Box>
              </Box>
          </DialogContent>
          <DialogActions>
            <Button
              type="submit"
              onClick={() => assignAsset()}
              variant="contained"
              sx={{
                backgroundColor: "#0288d1",
                textTransform: "none",
                "&:hover": {
                  backgroundColor: "#140B96",
                  boxShadow: "1px #000000",
                },
              }}
              disableRipple
            >
              Submit
            </Button>
            <Button
              onClick={() => setAssignModalStatus(false)}
              variant="contained"
              sx={{
                backgroundColor: "#E0E0E0",
                textTransform: "none",
                color: "black",
                "&:hover": {
                  backgroundColor: "#E0E0E0",
                  boxShadow: "1px #000000",
                },
              }}
              disableRipple
            >
              Cancel
            </Button>
          </DialogActions>
        </Dialog>
      </div>
         {/***** SNACKBAR COMPONENT * */}
         <Snackbar
        open={SuccessStatus}
        autoHideDuration={6000}
        onClose={handleClose}
      >
        <Alert onClose={handleClose} severity="success" sx={{ width: "100%" }}>
          SUCCESS!!
        </Alert>
      </Snackbar>
      <Snackbar
        open={FailedStatus}
        autoHideDuration={6000}
        onClose={handleClose}
      >
        <Alert onClose={handleClose} severity="error" sx={{ width: "100%" }}>
          FAILED!!
        </Alert>
      </Snackbar>

      {/* *******************  */}

      {/* *modal components***  */}

      <ThemeProvider theme={dialogTheme}>
        {/* *modal DELETE***  */}

        <Dialog open={deleteModalStatus}>
          <DialogTitle sx={{ textAlign: "center" }}>
            <ErrorOutlineIcon sx={{ color: "#FF9A22", fontSize: "2.3rem" }} />
            <Typography variant="h6">Are you sure?</Typography>
            <Typography sx={{ fontSize: "0.9rem" }}>
              <span>Are you sure you want to delete this record?</span>
              <br />
              <span>This process cannot be undone.</span>
            </Typography>
          </DialogTitle>
          <DialogActions sx={{ justifyContent: "center" }}>
            <Button
              onClick={handleDelete}
              variant="contained"
              sx={{
                backgroundColor: "#CF0100",
                textTransform: "none",
                "&:hover": {
                  backgroundColor: "#B70100",
                  boxShadow: "1px #000000",
                },
              }}
              disableRipple
            >
              Delete
            </Button>
            <Button
              onClick={handleClose}
              variant="contained"
              sx={{
                backgroundColor: "#E0E0E0",
                textTransform: "none",
                color: "black",
                "&:hover": {
                  backgroundColor: "#E0E0E0",
                  boxShadow: "1px #000000",
                },
              }}
              disableRipple
            >
              Cancel
            </Button>
          </DialogActions>
        </Dialog>
        {/* *modal EDIT***  */}

        <Dialog open={editModalStatus} sx={{}}>
          <DialogContent sx={{ padding: "10px" }}>
            <Stack>
              <Grid container spacing={8} rowSpacing={3}>
                <Grid item xs={6}>
                  <Typography sx={{ color: "#424242" }}>Asset Name</Typography>
                  <TextField
                    select
                    SelectProps={inputSize}
                    margin="normal"
                    id="outlined-basic"
                    variant="outlined"
                    name="assetName"
                    defaultValue={EditFormData.assetName}
                    onChange={handleOnChange}
                  >
                    <MenuItem value={"Pen"}>Pen</MenuItem>
                    <MenuItem value={"Notebook"}>Notebook</MenuItem>
                    <MenuItem value={"Bottle"}>Bottle</MenuItem>
                    <MenuItem value={"T-shirt"}>T-shirt</MenuItem>
                    <MenuItem value={"Bag"}>Bag</MenuItem>
                  </TextField>
                </Grid>
                <Grid item xs={6}>
                  <Typography sx={{ color: "#424242" }}>Quantity</Typography>
                  <TextField
                    type={"number"}
                    InputProps={inputSize}
                    margin="normal"
                    id="outlined-basic"
                    variant="outlined"
                    name="assetQty"
                    inputProps={{ min: 0 }}
                    value={EditFormData.assetQty}
                    onChange={handleOnChange}
                  />
                </Grid>
              </Grid>
            </Stack>
          </DialogContent>
          <DialogActions>
            <Button
              onClick={handleSave}
              variant="contained"
              sx={{
                backgroundColor: "#0288d1",
                textTransform: "none",
                "&:hover": {
                  backgroundColor: "#140B96",
                  boxShadow: "1px #000000",
                },
              }}
              disableRipple
            >
              Save
            </Button>
            <Button
              onClick={handleClose}
              variant="contained"
              sx={{
                backgroundColor: "#E0E0E0",
                textTransform: "none",
                color: "black",
                "&:hover": {
                  backgroundColor: "#E0E0E0",
                  boxShadow: "1px #000000",
                },
              }}
              disableRipple
            >
              Cancel
            </Button>
          </DialogActions>
        </Dialog>

        {/* *modal ADD NEW DATA***  */}

        <Dialog open={AddModalStatus} sx={{ padding: "20px" }}>
          <DialogContent sx={{ padding: "10px" }}>
            <Stack>
              <Grid container spacing={8} rowSpacing={3}>
                <Grid item xs={6}>
                  <Typography sx={{ color: "#424242" }}>Asset Name</Typography>

                  <TextField
                    select
                    SelectProps={inputSize}
                    margin="normal"
                    id="outlined-basic"
                    variant="outlined"
                    name="assetName"
                    defaultValue={EditFormData.assetName}
                    onChange={handleOnChange}
                  >
                    {adminAssetsOptions.map((val) => {
                      return <MenuItem value={val}>{val}</MenuItem>;
                    })}
                  </TextField>
                </Grid>
                <Grid item xs={6}>
                  <Typography sx={{ color: "#424242" }}>Quantity</Typography>
                  <TextField
                    type={"number"}
                    InputProps={inputSize}
                    margin="normal"
                    id="outlined-basic"
                    variant="outlined"
                    name="assetQty"
                    inputProps={{ min: 0 }}
                    value={EditFormData.assetQty}
                    onChange={handleOnChange}
                  />
                </Grid>
              </Grid>
            </Stack>
          </DialogContent>

          <DialogActions>
            <Button
              onClick={() => createAsset()}
              variant="contained"
              sx={{
                backgroundColor: "#0288d1",
                textTransform: "none",
                "&:hover": {
                  backgroundColor: "#140B96",
                  boxShadow: "1px #000000",
                },
              }}
              disableRipple
            >
              Save
            </Button>
            <Button
              onClick={handleClose}
              variant="contained"
              sx={{
                backgroundColor: "#E0E0E0",
                textTransform: "none",
                color: "black",
                "&:hover": {
                  backgroundColor: "#E0E0E0",
                  boxShadow: "1px #000000",
                },
              }}
              disableRipple
            >
              Cancel
            </Button>
          </DialogActions>
        </Dialog>
      </ThemeProvider>
      </>
  )
}

export default Admin

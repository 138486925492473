import { createSlice } from "@reduxjs/toolkit";
export const appReducer = createSlice({
  name: "appReducer",
  initialState: {
    selectedNavIndex: 0,
    inventoryData: {
      primaryData: {
        pageNumber: 1,
        data: []
      },
      filteredData: {
        pageNumber: 1,
        data: []
      }
    },
    otherAssetInventoryData:{
      primaryData: {
        pageNumber: 1,
        data: []
      },
      filteredData: {
        pageNumber: 1,
        data: []
      }
    },
    lookUpData: {
      employeeData: {
        registeredAssetsData: null,
        otherAssetsData: null
      },
      registeredAssetData: {
        data: null
      },
      otherAssetData: {
        data: []
      }
    },
    adminCornerInventoryData: {
      primaryData: {
        pageNumber: 1,
        data: []
      },
      assignedData: {
        pageNumber: 1,
        data: []
      },
      filteredData: {
        pageNumber: 1,
        data: []
      }
    },
    intangibleAssets:{
      primaryData: {
        pageNumber: 1,
        data: []
      },
      filteredData: {
        pageNumber: 1,
        data: []
      }
    }
  },
  reducers: {
    /************************ Application Constant ************************/
    
    //Navigation related reducer
    setSelectedNavIndex: (state, action) => {
      state.selectedNavIndex = action.payload || 0;
    },

    //Inventory related reducers
    setInventoryPrimaryData: (state, action) => {
      state.inventoryData.primaryData.data = state.inventoryData.primaryData.data.concat(action.payload || [])
      state.inventoryData.primaryData.pageNumber += 1;
    },
    
    resetInventoryPrimaryData: (state, action) => {
      state.inventoryData.primaryData.data = [];
      state.inventoryData.primaryData.pageNumber = 0;
    }, 

    deleteInventoryPrimaryData: (state, action) => {
      state.inventoryData.primaryData.data = state.inventoryData.primaryData.data.filter((item) => {
        return !action.payload.includes(item.assetId)
      })
    },

    updateInventoryPrimaryData: (state, action) => {
      if (action.payload)
        state.inventoryData.primaryData.data = action.payload
    },

    setInventoryFilteredData: (state, action) => {
      state.inventoryData.filteredData.data = action.payload
    },

    setOtherAssetInventoryPrimaryData: (state, action) =>{
      state.otherAssetInventoryData.primaryData.data = state.otherAssetInventoryData.primaryData.data.concat(action.payload || [])
      state.otherAssetInventoryData.primaryData.pageNumber += 1;
    },
    updateOtherAssetInventoryPrimaryData: (state, action) => {
      if (action.payload)
        state.otherAssetInventoryData.primaryData.data = action.payload
    },
    //end of inventory related reducers

    //AdminCorner related reducer
    setAdminCornerInventoryPrimaryData: (state, action) =>{
      state.adminCornerInventoryData.primaryData.data = state.adminCornerInventoryData.primaryData.data.concat(action.payload || [])
      state.adminCornerInventoryData.primaryData.pageNumber += 1;
    },
    
    setAdminCornerInventoryAssignedData: (state, action) => {
      console.log(action.payload);
      state.adminCornerInventoryData.assignedData.data = state.adminCornerInventoryData.assignedData.data.concat(action.payload || [])
      state.adminCornerInventoryData.assignedData.pageNumber += 1;
    },
    //end of adminCorner related reducers

    //Intangibles related reducers
    setIntangibleAssetsPrimaryData: (state, action) => {
      state.intangibleAssets.primaryData.data = action.payload;
      state.intangibleAssets.primaryData.pageNumber += 1;
    },

    deleteIntangibleAssetsPrimaryData: (state, action) => {
      state.intangibleAssets.primaryData.data = state.intangibleAssets.primaryData.data.filter((item) => {
        return !action.payload.includes(item.assetId)
      })
    },

    updateIntangibleAssetsPrimaryData: (state, action) => {
      if (action.payload)
        state.intangibleAssets.primaryData.data = action.payload
    },

    setIntangibleAssetsFilteredData: (state, action) => {
      state.intangibleAssets.filteredData.data = action.payload
    },
    //end of intangibles related reducers

    //LookUp related reducers
    setLookUpEmployeeData: (state, action) => {
      if(action.payload)
      {
        state.lookUpData.employeeData.registeredAssetsData = action.payload.registeredAssetsData;
        state.lookUpData.employeeData.otherAssetsData = action.payload.otherAssetsData;
      }
    },

    setLookUpRegisteredAssetData: (state, action) => {
      if(action.payload)
      {
        state.lookUpData.registeredAssetData.data = action.payload;
      }
    }, 

    resetLookUpEmployeeData:(state)=>{
      state.lookUpData.employeeData.registeredAssetsData =null
    },
    resetLookUpRegisteredAssetData:(state)=>{
      state.lookUpData.registeredAssetData.data  =null
    },

    

    //LookUp reducers ends


  },
});

// Action creators are generated for each case reducer function
export const {
  setSelectedNavIndex,
  setInventoryPrimaryData,
  setInventoryFilteredData,
  deleteInventoryPrimaryData,
  updateInventoryPrimaryData,
  updateOtherAssetInventoryPrimaryData,
  setOtherAssetInventoryPrimaryData,
  setAdminCornerInventoryPrimaryData,
  setAdminCornerInventoryAssignedData,
  setIntangibleAssetsPrimaryData,
  setIntangibleAssetsFilteredData,
  deleteIntangibleAssetsPrimaryData,
  updateIntangibleAssetsPrimaryData,
  setLookUpEmployeeData,
  setLookUpRegisteredAssetData,
  resetInventoryPrimaryData,
  resetLookUpEmployeeData,
  resetLookUpRegisteredAssetData
} = appReducer.actions;

export default appReducer.reducer;

import React from "react";
import {
  BrowserRouter as Router,
  Switch,
  Route,
  Redirect,
} from "react-router-dom";
import Home from "./Pages/Home/Home";
import SideNavbar from "./Components/SideNavbar/SideNavbar";
import Inventory from "./Pages/Inventory/Inventory";
import LookUp from "./Pages/LookUp/LookUp";
import Reports from "./Pages/Reports/Reports";
import Management from "./Pages/Management/Management";
import AdminCorner from "./Pages/AdminCorner/AdminCorner";
import AppBarComponent from "./Components/AppBarComponent/AppBarComponent";
import "./App.css";
import { useSelector } from "react-redux/es/exports";
import { ThemeProvider } from "@emotion/react";

import CustomTheme from "./Theme/CustomTheme";
import OtherAssets from "./Components/InventoryPanels/OtherAssets";

import IntangibleAssetsTab from "./Pages/IntangibleAssets/IntangibleAssetsTab";
import UserDetails from "./Pages/UserDetails/UserDetails";

const AdminApp = () => {
  return (
    <Router>
      <ThemeProvider theme={CustomTheme}>
        <AppBarComponent />

        <div className="wbMainPageHeight">
          <div className="wbFloatLeft">
            <SideNavbar isAdmin={true} />
          </div>
          <div className="body-content wbScroll">
            <Routes />
          </div>
        </div>
      </ThemeProvider>
    </Router>
  );
};

export default AdminApp;

const Routes = () => {
  const userReducer = useSelector((state) => state.userReducer.userData);
  return (
    <Switch>
      {/* Home page */}
      <Route exact path="/home" component={Home} />

      {/* Inventory page */}
      <Route exact path="/inventory" component={Inventory} />

      {/* Management page */}
      <Route exact path="/management" component={Management} />

      {/* Reports page */}
      <Route exact path="/reports" component={Reports} />

      {/* Profile page */}
      <Route exact path="/profile" component={LookUp} />

      {/* OtherAssets page */}
      <Route exact path="/otherAssets" component={OtherAssets} />

      <Route exact path="/admincorner" component={AdminCorner} />

      <Route exact path="/intangibleAssets" component={IntangibleAssetsTab} />

      {
        userReducer?.role==="superAdmin" && (
          <Route exact path="/userDetails" component={UserDetails} />
        )
      }

      <Redirect exact from="/" to="/home" />
    </Switch>
  );
};

import React, { useEffect, useState } from "react";
import { Bar } from "react-chartjs-2";
import axios from "axios";
import { Select, MenuItem, FormControl, Paper, Box } from "@mui/material";

// Import Chart.js components
import {
  Chart as ChartJS,
  CategoryScale,
  LinearScale,
  BarElement,
  Title,
  Tooltip,
  Legend,
} from "chart.js";
import { baseUrl } from "../../Utils/serviceRequest";

// Register Chart.js components
ChartJS.register(
  CategoryScale,
  LinearScale,
  BarElement,
  Title,
  Tooltip,
  Legend
);

const LocClassificationBarCard = () => {
  const [locations, setLocations] = useState([]);
  const [selectedLocation, setSelectedLocation] = useState("");
  const [chartData, setChartData] = useState(null);
  const [data, setData] = useState([]);

  useEffect(() => {
    const fetchData = async () => {
      // debugger
      try {
        const response = await axios.get(
          `${baseUrl}/getLocationVClassifications`
        );
        const data = response.data.data;
        setData(data);

        // Extract unique locations
        const uniqueLocations = [
          ...new Set(data.map((item) => item.location)),
        ].filter((location) => location);

        setLocations(uniqueLocations);

        if (uniqueLocations.length > 0) {
          setSelectedLocation(uniqueLocations[0]);
          processChartData(data, uniqueLocations[0]);
        }
      } catch (error) {
        console.error("Error fetching data:", error);
      }
    };
    fetchData();
  }, []);

  const processChartData = (data, location) => {
    // debugger
    const filteredData = data.filter((item) => item.location === location);
    const classificationNames = [
      ...new Set(filteredData.map((item) => item.classification_name)),
    ];
    const classificationCounts = classificationNames.map((name) => {
      const item = filteredData.find((d) => d.classification_name === name);
      return item ? item.classification_count : 0;
    });
    const labels = classificationNames.map((name) => `${name}`);

    setChartData({
      labels,
      datasets: [
        {
          // label: "Classification Count",
          data: classificationCounts,
          backgroundColor: [
           '#FFE082', '#DCEDC8', '#E1BEE7', '#B2DFDB', '#FFAB91', '#D1C4E9',
    '#BCAAA4', '#CFD8DC'
          ],
          barThickness: 30,
        },
      ],
    });
  };

  const handleLocationChange = (event) => {
    const location = event.target.value;
    setSelectedLocation(location);
    processChartData(data, location);
  };

  return (
    <>
    <Box sx={{height:"470px"}}>
      <FormControl fullWidth>
        <Select
          value={selectedLocation}
          onChange={handleLocationChange}
          MenuProps={{
            PaperProps: {
              style: {
                maxHeight: 200, // Adjust the max height for the dropdown
              },
            },
          }}
        >
          {locations.map((loc) => (
            <MenuItem key={loc} value={loc}>
              {loc}
            </MenuItem>
          ))}
        </Select>
      </FormControl>

      {chartData && (
        <Paper
          elevation={3}
          sx={{
            marginTop: 2,
            height: "calc(100% - 56px)",
            display: "flex",
            flexDirection: "column",
          }}
        >
          <Box sx={{ flexGrow: 1, height: "100%" }}>
            <Bar
              data={chartData}
              options={{
                maintainAspectRatio: false,
                plugins: {
                  legend: {
                    display: false, 
                  },
                  title: {
                    display: true,
                    text: `Classification Count for ${selectedLocation}`,
                    font: { size: 16 },
                  },
                },
                scales: {
                  x: {
                    beginAtZero: true,
                    grid: {
                      offset: false,
                      display: false, // Optional: hide grid lines
                    },
                    ticks: {
                      align: "center", // Align ticks to the start (left)
                    },
                  },
                  y: {
                    beginAtZero: true,
                    grid: {
                      offset: false,
                      display: false, // Optional: hide grid lines
                    },
                  },
                },
              }}
            />
          </Box>
        </Paper>
      )}
    </Box>
    </>
  );
};

export default LocClassificationBarCard;

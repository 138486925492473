import { createSlice } from "@reduxjs/toolkit";
//Reducer for the workbox application. All user params
export const userReducer = createSlice({
  name: "userReducer",
  initialState: {
    userData: {},
    avatarInitials: ""
  },
  reducers: {
    /************************ Application Constant ************************/
    setUserData: (state, action) => {
      state.userData = action.payload;
      state.avatarInitials = `${state.userData.name.split(' ')[0][0]}${state.userData.name.split(' ')[1][0]}`
    },

    setUserRole: (state, action) => {
      state.userData = {...state.userData,  ...action.payload}
      // state.userData = {...state.userData, role: action.payload}
    }
  },
});
// Action creators are generated for each case reducer function
export const { setUserData, setUserRole } =
  userReducer.actions;
export default userReducer.reducer;

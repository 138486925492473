import React from "react";
import { useDispatch, useSelector } from "react-redux";
import TableUtility from "../TableUtility/TableUtility";
import xlsx from "json-as-xlsx";
import {
  setSelectedNavIndex,
  updateOtherAssetInventoryPrimaryData,
} from "../../redux/reducers/appReducer";
import {
  Grid,
  Paper,
  Typography,
  Button,
  ButtonGroup,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  createTheme,
  ThemeProvider,
  TextField,
  Snackbar,
  Alert,
  FormGroup,
  FormControlLabel,
  Stack,
  MenuItem,
  InputAdornment,
  FormControl,
  InputLabel,
  Select,
} from "@mui/material";
import { useState, useEffect } from "react";
import ErrorOutlineIcon from "@mui/icons-material/ErrorOutline";
import FilterAltIcon from "@mui/icons-material/FilterAlt";
import UploadFileIcon from "@mui/icons-material/UploadFile";
import AddCircleIcon from "@mui/icons-material/AddCircle";
import FileDownloadIcon from "@mui/icons-material/FileDownload";
import DeleteIcon from "@mui/icons-material/Delete";
import axios from "axios";
import { Box } from "@mui/system";
import {
  CheckBox,
  ContactsOutlined,
  SearchOutlined,
} from "@mui/icons-material";
import { baseUrl } from "../../Utils/serviceRequest";

import { setOtherAssetInventoryPrimaryData } from "../../redux/reducers/appReducer";
const OtherAssets = () => {
  const [deleteModalStatus, setDeleteModalStatus] = useState(false);
  const [editModalStatus, setEditModalStatus] = useState(false);
  const [AddModalStatus, setAddModalStatus] = useState(false);
  const [DeletedModalStatus, setDeletedModalStatus] = useState(false);
  const [SuccessStatus, setSuccessStatus] = useState(false);
  const [FailedStatus, setFailedStatus] = useState(false);
  const [prefix, setPrefix] = useState("INC/BBSR/LT");
  const [AssetType, setAssetType] = useState("All Other Assets");
  //DElETE RELATED OPERATIONS
  const [SelectedAssets, setSelectedAssets] = useState([]);
  const [SelectAll, setSelectAll] = useState(false);
  const [DeleteMethod, setDeleteMethod] = useState(null);
  const [OtherAssetsData, setOtherAssetsData] = useState([]);

  //state for search
  const [searchInput, setSearchInput] = useState("");
  const [searchedData, setSearchedData] = useState([]);
  const onChangeSearch = (e) => {
    setSearchInput(e.target.value);
    const tempData =
      appReducerState.otherAssetInventoryData.primaryData.data.filter(
        (item) => {
          return Object.keys(item).some((key) =>
            item[key]
              ?.toString()
              ?.toLowerCase()
              ?.includes(e.target.value.toLowerCase())
          );
        }
      );
    console.log(tempData);
    setSearchedData(tempData);
  };
  const downloadFile = () => {
    let data = [
      {
        sheet: "sheet1",
        columns: [
          { label: "Asset Name", value: "assetName" }, // Top level data
          { label: "Brand", value: "assetBrand" }, // Custom format
          { label: "Asset Type", value: "assetType" }, // Top level data
          { label: "Quantity", value: "assetQty" }, // Custom format
          { label: "Damaged Qty", value: "damaged" }, // Custom format
        ],
        content: appReducerState.otherAssetInventoryData.primaryData.data,
      },
    ];
    let settings = {
      fileName: "OtherAssetInventory",
    };
    xlsx(data, settings);
  };

  const dispatch = useDispatch();
  // dispatch(setSelectedNavIndex(2));
  const assetName = ["Mouse", "Headphone", "Monitor", "Projector", "Desktop"];
  const assetBrand = {
    Mouse: ["Lenovo"],
    Headphone: ["Sony", "Boat"],
    Monitor: ["Dell"],
    Projector: ["Epson", "Benq"],
    Desktop: ["Lenovo"],
  };
  const assetType = {
    Mouse: ["Wired", "Wireless"],
    Headphone: ["Wired", "Wireless"],
    Monitor: ["Wired"],
    Projector: ["Wired"],
    Desktop: ["Wired"],
  };

  const otherAssets = [
    {
      Date: "18/05/1970",
      AssetName: "Mouse",
      Brand: "Lenovo",
      StockQuantity: "19",
      Type: "New",
      Health: "Good",
    },
    {
      Date: "18/06/1970",
      AssetName: "Mouse",
      Brand: "Lenovo",
      StockQuantity: "19",
      Type: "New",
      Health: "Good",
    },
    {
      Date: "18/06/1970",
      AssetName: "Mouse",
      Brand: "HP",
      StockQuantity: "18",
      Type: "New",
      Health: "Good",
    },
    {
      Date: "18/06/1970",
      AssetName: "Headphone",
      Brand: "Sony",
      StockQuantity: "11",
      Type: "New",
      Health: "Good",
    },
  ];

  const appReducerState = useSelector((state) => state.appReducer);
  const presentAssetDate= new Date().toISOString().substring(0,10)
  const [pageNumber, setPageNumber] = useState(1);

  const fetchData = async (type) => {
    try {
      let res = await await axios.get(
        `${baseUrl}/getOtherAssetsMaster/20/${appReducerState.otherAssetInventoryData.primaryData.pageNumber}`
      );
      if (res.status === 200) {
        // setInventoryData((prev) => prev.concat(res.data));
        dispatch(setOtherAssetInventoryPrimaryData(res.data));
      }
    } catch (e) {
      console.error(e);
    }
  };

  useEffect(() => {
    // setOtherAssetsData([...Data.slice(0,10)])
    fetchData();
  }, []);
  // ***********all state variables **

  const addSelectedAsset = (e, DATA, METHOD) => {
    let value = e.target.checked;
    let method = e.target.dataset.method;
    if (method == "SELECTALL") {
      setSelectAll(true);
      setSelectedAssets([...DATA]);
      setDeleteMethod(method);
    }

    if (method == "SELECTMULTIPLE") {
      let asset = e.target.dataset.assetid;
      if (value === true) {
        setSelectedAssets([...SelectedAssets, asset]);
        setDeleteMethod(method);
      }
      if (value === false) {
        let array = SelectedAssets.filter((item) => {
          return item !== asset; //returns false if selectedasset state contains assetid
        });
        setSelectedAssets(array);
        setDeleteMethod(method);
      }
    }
  };
  //------
  const [ReceivedIdFromTable, setReceivedIdFromTable] = useState(null); // Id replaced to ReceivedIdFRomTable, ID RECEIVED FROM TABLE COMPONENT,

  const [EditFormData, setEditFormData] = useState({
    assetBrand: assetBrand["Mouse"][0],
    assetName: assetName[0],
    assetQty: 0,
    assetType: assetType["Mouse"][0],
    date:presentAssetDate
  });

  const createAsset = async () => {
    let stateData = EditFormData;
    try {
      let data = await axios.post(`${baseUrl}/createOtherAsset`, stateData);
      if (data) {
        handleClose();
       window.location.reload();
      }
    } catch (error) {
      console.error(error);
    }
  };
  const updateAsset = async () => {
    let stateData = EditFormData;
    try {
      let data = await axios.put(
        `${baseUrl}/updateOtherAssetMaster`,
        stateData
      );
      if (data) {
        handleClose();
      }
      if (data.status === 200) {
        //if 200 ? update data on ui side
        console.log(data);
        let updatedData =
          appReducerState.otherAssetInventoryData.primaryData.data.map(
            (item) => {
              if (item.id === stateData.assetId) {
                return stateData;
              }
              return item;
            }
          );
        // setInventoryData(updatedData)
        dispatch(updateOtherAssetInventoryPrimaryData(updatedData));

        setSuccessStatus(true);
      }
      if (data.status !== 200) {
        setFailedStatus(true);
      }
    } catch (error) {
      console.error(error);
    }
  };

  let handleOnChange = (e) => {
    //single function handling both updating the data and adding the data, form data is handled in a single EditFromData state reason for data structure being same
    let value = e.target.value;
    let name = e.target.name;
    let stateData = { ...EditFormData };

    stateData[name] = value;
    setEditFormData(stateData);
  };
  const handleValidations = (val) => {
    let keys = Object.keys(EditFormData);
    let count = 0;
    let add = (val) => {
      if (val === 0) {
        createAsset();
      } else if (val === 1) {
        updateAsset();
      }
    };
    keys.map((item) => {
      count++;

      if (EditFormData[item].length === 0) {
        alert(`${item.toUpperCase()} field Cannot be Empty`);
        count--;
      }
      console.log(count, keys.length);
      count == keys.length && add(val);
    });
  };

  let handleDelete = async (e) => {
    //handling the delete process, Id is received from Id state
    setDeleteModalStatus(false);
    let data;
    let selectedIds = [];

    if (DeleteMethod === "DELETEONE") {
      selectedIds = [ReceivedIdFromTable];

      setOtherAssetsData((current) =>
        current.filter((item) => {
          return !selectedIds.includes(item.assetId); //returns false if selectedasset state contains assetid
        })
      );
      setDeleteMethod(null);
      setReceivedIdFromTable(null);
    }

    if (DeleteMethod === "SELECTALL") {
      setOtherAssetsData([]);
      setSelectAll(false);
      setDeleteMethod(null);
    }

    if (DeleteMethod === "SELECTMULTIPLE") {
      selectedIds = [...SelectedAssets];
      let array = OtherAssetsData.filter((item) => {
        return !selectedIds.includes(item.assetId); //returns false if selectedasset state contains assetid
      });
      setOtherAssetsData(array);
      setSelectAll(false);
      setSelectedAssets([]);
      setDeleteMethod(null);
    }
  };
  let handleSave = (e) => {
    //  contains to functions for handling adding new data process and updating the existing data process

    let handleEdit = () => {
      //if editModalStatus is true? this functions is executed for handling updating data  process
      let updatedData = OtherAssetsData.map((item) => {
        //masterdata gets looped and id is added to the masterdata here
        return item.Id === ReceivedIdFromTable ? EditFormData : item;
      });
      // axios.post('http://localhost:9090/cw/icm/post/agingbucket/updateAgingBucket', EditFormData)
      setOtherAssetsData(updatedData); //updating masterdata with new data
      setEditFormData({
        assetBrand: "",
        assetName: "",
        assetQty: 0,
        assetType: "",
        date:presentAssetDate
        
      });
      setEditModalStatus(false); //closing the the edit modal
    };

    let handleAdd = () => {
      // axios.post('http://localhost:9090/cw/icm/post/addAgingBucket', EditFormData) // data is sent to database from the EditFormData state variable
      //   .then((response) => {
      //     if (response.status === 200) {
      //       SuccessStatus(true)
      //     }
      //     if (response.status != 200) {
      //       FailedStatus(true)
      //     }
      //   }
      setEditFormData({
        assetBrand: assetBrand["Mouse"][0],
        assetName: assetName[0],
        assetQty: 0,
        assetType: assetType["Mouse"][0],
        date:presentAssetDate

      });

      //handling updating of data on ui side **/
      // let updatedData = [...OtherAssetsData] //destructured agingbucket array
      // updatedData.push({ ...EditFormData, assetId: OtherAssetsData.length + 1 }) //pushed the new data
      // setOtherAssetsData(updatedData)
      handleClose();
    };

    editModalStatus && handleEdit();
    AddModalStatus && handleAdd();
  };

  // functions to handle display and hidding of modals*******************
  let handleClose = (e) => {
    // handling closing for all modals, and then updates the EditFormData to a blank template to make clear form input values //problem was in switching from edit process to add process.
    setDeleteModalStatus(false);
    setEditModalStatus(false);
    setAddModalStatus(false);
    setEditFormData({
      assetBrand: assetBrand["Mouse"][0],
      assetName: assetName[0],
      assetQty: 0,
      assetType: assetType["Mouse"][0],
      date:presentAssetDate
    });
  };

  let ShowDeleteModalFunc = (receivedIdFromTable, method) => {
    method === "DELETEONE" && setReceivedIdFromTable(receivedIdFromTable);
    method === "DELETEONE" && setDeleteMethod(method);
    setDeleteModalStatus(true);
  };

  //**********showing add modal ********/

  let ShowAddModal = () => {
    setAddModalStatus(true);
  };

  //handling change in display asset type
  let handleChangeForAssetType = (e) => {
    let assetType = e.target.value;
    if (assetType !== "All Other Assets") {
      let data = otherAssets.filter((item) => {
        return item.AssetName.toUpperCase() === assetType;
      });
      console.log(data);
      setOtherAssetsData([...data]);
      setAssetType(assetType);
    }
  };

  //**********showing edit modal ********/

  let ShowEditModalFunc = (receivedIdFromTable) => {
    receivedIdFromTable && setReceivedIdFromTable(receivedIdFromTable);
    OtherAssetsData.map((item) => {
      if (item.id === receivedIdFromTable) {
        setEditFormData({ ...EditFormData, ...item });
      }
    });
    setEditModalStatus(true);
  };

  //*function to get next batch of data for the table for infinite scrolling*/
  let getNextBatchOfData = () => {
    // setdataBatchCount((prev)=> {
    //   let prevState = {...prev}
    //   prevState.start +=10
    //   prevState.end +=10
    // setdataBatchCount(prevState)
    // })

    //Bhupendra working slicing code
    // let newData = [...OtherAssetsData]
    // newData.push(...Data.slice(10, 19))
    // setOtherAssetsData(newData)
    // console.log('doing good bro go on', newData)

    //new code to append data to existing list
    fetchData();
  };

  const inputSize = {
    style: {
      height: "5vh",
      maxHeight: "32px",
      width: "15em",
      padding: "2px 10px",
    },
  };

  let dialogTheme = createTheme({
    components: {
      MuiDialogTitle: {
        styleOverrides: {
          root: {
            paddingTop: "10px",
          },
        },
      },
      MuiGrid: {
        styleOverrides: {
          root: {
            paddingTop: "10px",
          },
        },
      },
      MuiPaper: {
        styleOverrides: {
          root: {
            padding: "10px",
          },
        },
      },
    },
  });
  let options = {
    getNextBatchOfData,
    IdKey: "id",
    ShowEditModalFunc,
    ShowDeleteModalFunc,
    ShowAddModal,
    addSelectedAsset,
    Data: appReducerState.otherAssetInventoryData.primaryData.data,
    headerkeys: [
      "",
      "Asset Name",
      "Brand",
      "Asset Type",
      "Quantity",
      "Damaged Quantity",
    ],
    bodykeys: [
      "",
      "assetName",
      "assetBrand",
      "assetType",
      "assetQty",
      "damaged",
    ],
    tableTitle: AssetType,
    SelectedAssets,
  };

  return (
    <>
      {/* <Paper style={{ height: 'calc(100vh - 52px - 3em)', margin: '1em', overflow: 'hidden', paddingBottom: '1rem', padding: '5px' }}> */}
      <Stack style={{ position: "relative" }}>
        <Box
          sx={{ display: "flex" }}
          style={{
            position: "absolute",
            top: "-44px",
            right: "0",
            width: "70%",
          }}
        >
          {/* <Box sx={{ flexGrow: '3' }} >
            <Typography variant='h6' sx={{ padding: '20px', fontWeight: 'bold' }}>{AssetType}</Typography>
          </Box> */}

          <Box
            sx={{
              flexGrow: "1",
              display: "flex",
              justifyContent: "flex-end",
              gap: "2%",
              alignItems: "center",
            }}
          >
            <FormControl sx={{ width: "30%", height: "5vh" }}>
              <TextField
                placeholder="Search..."
                inputProps={inputSize}
                InputProps={{
                  endAdornment: <SearchOutlined color="disabled" />,
                }}
                onChange={(e) => onChangeSearch(e)}
              />
            </FormControl>
            {/* <Button
              variant="contained"
              endIcon={<DeleteIcon sx={{ color: "red" }} />}
              sx={{
                border: "1px solid #F1F5FE",
                backgroundColor: "#F1F5FE",
                color: "#0288d1",
                fontSize: "12px",
                textTransform: "capitalize",
                height: "max-content",
                "&:hover": {
                  backgroundColor: "#F1F5FE",
                  color: "#0288d1",
                  border: "1px solid #0288d1",
                  boxShadow: "1px #000000",
                },
              }}
              onClick={ShowDeleteModalFunc}
            >
              {" "}
              Delete
            </Button> */}
            {/* <Button
              variant="contained"
              endIcon={<FilterAltIcon />}
              sx={{
                border: "1px solid #F1F5FE",
                backgroundColor: "#F1F5FE",
                color: "#0288d1",
                fontSize: "12px",
                textTransform: "capitalize",
                height: "max-content",
                "&:hover": {
                  backgroundColor: "#F1F5FE",
                  color: "#0288d1",
                  border: "1px solid #0288d1",
                  boxShadow: "1px #000000",
                },
              }}
            >
              {" "}
              Filter
            </Button> */}
            <Button
              variant="contained"
              endIcon={<FileDownloadIcon />}
              sx={{
                border: "1px solid #F1F5FE",
                backgroundColor: "#F1F5FE",
                color: "#0288d1",
                fontSize: "12px",
                textTransform: "capitalize",
                height: "max-content",
                "&:hover": {
                  backgroundColor: "#F1F5FE",
                  color: "#0288d1",
                  border: "1px solid #0288d1",
                  boxShadow: "1px #000000",
                },
              }}
              onClick={() => {
                downloadFile();
              }}
            >
              Export
            </Button>
            {/* <Button
              variant="contained"
              endIcon={<UploadFileIcon />}
              sx={{
                border: "1px solid #F1F5FE",
                backgroundColor: "#F1F5FE",
                color: "#0288d1",
                fontSize: "12px",
                textTransform: "capitalize",
                height: "max-content",
                "&:hover": {
                  backgroundColor: "#F1F5FE",
                  color: "#0288d1",
                  border: "1px solid #0288d1",
                  boxShadow: "1px #000000",
                },
              }}
            >
              {" "}
              Import
            </Button> */}
            <Button
              variant="contained"
              startIcon={<AddCircleIcon />}
              sx={{
                border: "1px solid #F1F5FE",
                backgroundColor: "#F1F5FE",
                color: "#0288d1",
                fontSize: "12px",
                textTransform: "capitalize",
                height: "max-content",
                "&:hover": {
                  backgroundColor: "#F1F5FE",
                  color: "#0288d1",
                  border: "1px solid #0288d1",
                  boxShadow: "1px #000000",
                },
              }}
              onClick={ShowAddModal}
            >
              {" "}
              Add
            </Button>
          </Box>
        </Box>
        <Box style={{ alignItems: "center" }}>
          {/* <TableUtility
            {...options}
            height={"78vh"}
            enableDelete={false}
            enableEdit={false}
          /> */}
          {searchInput !== "" ? (
            <TableUtility
              {...{ ...options, Data: searchedData }}
              height={"78vh"}
              enableDelete={false}
              enableEdit={false}
            />
          ) : (
            <TableUtility
              {...options}
              height={"78vh"}
              enableDelete={false}
              enableEdit={false}
            />
          )}
        </Box>
      </Stack>
      {/* </Paper> */}
      {/***** SNACKBAR COMPONENT * */}
      <Snackbar
        open={SuccessStatus}
        autoHideDuration={6000}
        onClose={handleClose}
      >
        <Alert onClose={handleClose} severity="success" sx={{ width: "100%" }}>
          SUCCESS!!
        </Alert>
      </Snackbar>
      <Snackbar
        open={FailedStatus}
        autoHideDuration={6000}
        onClose={handleClose}
      >
        <Alert onClose={handleClose} severity="error" sx={{ width: "100%" }}>
          FAILED!!
        </Alert>
      </Snackbar>

      {/* *******************  */}

      {/* *modal components***  */}

      <ThemeProvider theme={dialogTheme}>
        {/* *modal DELETE***  */}

        <Dialog open={deleteModalStatus}>
          <DialogTitle sx={{ textAlign: "center" }}>
            <ErrorOutlineIcon sx={{ color: "#FF9A22", fontSize: "2.3rem" }} />
            <Typography variant="h6">Are you sure?</Typography>
            <Typography sx={{ fontSize: "0.9rem" }}>
              <span>Are you sure you want to delete this record?</span>
              <br />
              <span>This process cannot be undone.</span>
            </Typography>
          </DialogTitle>
          <DialogActions sx={{ justifyContent: "center" }}>
            <Button
              onClick={handleDelete}
              variant="contained"
              sx={{
                backgroundColor: "#CF0100",
                textTransform: "none",
                "&:hover": {
                  backgroundColor: "#B70100",
                  boxShadow: "1px #000000",
                },
              }}
              disableRipple
            >
              Delete
            </Button>
            <Button
              onClick={handleClose}
              variant="contained"
              sx={{
                backgroundColor: "#E0E0E0",
                textTransform: "none",
                color: "black",
                "&:hover": {
                  backgroundColor: "#E0E0E0",
                  boxShadow: "1px #000000",
                },
              }}
              disableRipple
            >
              Cancel
            </Button>
          </DialogActions>
        </Dialog>
        {/* *modal EDIT***  */}

        <Dialog open={editModalStatus} sx={{}}>
          <DialogContent sx={{ padding: "10px" }}>
            <Stack>
              <Grid container spacing={8} rowSpacing={3}>
                {/* <Grid item xs={6}>
                  <Typography sx={{ color: '#424242' }}>
                    Date
                  </Typography>
                  <TextField type={"date"} InputProps={inputSize} margin='normal' id="outlined-basic" variant="outlined" name='date' value={EditFormData.date} onChange={handleOnChange} />
                </Grid> */}
                {/* <Grid item xs={6}>
                  <Typography sx={{ color: '#424242' }}>
                    Location
                  </Typography>
                  <TextField select sx={{ width: "max-content" }} SelectProps={inputSize} margin='normal' id="outlined-basic" l variant="outlined" name='location' defaultValue={EditFormData.location} onChange={handleOnChange}>
                    <MenuItem value={'Bhubaneswar'}>Bhubaneswar</MenuItem>
                    <MenuItem value={'Bangalore'}>Bangalore</MenuItem>
                  </TextField>
                </Grid> */}
                <Grid item xs={6}>
                  <Typography sx={{ color: "#424242" }}>Asset Name</Typography>
                  <TextField
                    select
                    SelectProps={inputSize}
                    margin="normal"
                    id="outlined-basic"
                    variant="outlined"
                    name="assetName"
                    // defaultValue={EditFormData.assetName}
                    onChange={handleOnChange}
                  >
                    <MenuItem value={"Mouse"}>Mouse</MenuItem>
                    <MenuItem value={"Headphone"}>HeadPhone</MenuItem>
                  </TextField>
                </Grid>
                <Grid item xs={6}>
                  <Typography sx={{ color: "#424242" }}>Brand</Typography>
                  <TextField
                    select
                    SelectProps={inputSize}
                    margin="normal"
                    id="outlined-basic"
                    variant="outlined"
                    name="assetBrand"
                    // defaultValue={EditFormData.assetBrand}
                    onChange={handleOnChange}
                  >
                    <MenuItem value={"Lenovo"}>Lenovo</MenuItem>
                    <MenuItem value={"HP"}>HP</MenuItem>
                  </TextField>
                </Grid>

                <Grid item xs={6}>
                  <Typography sx={{ color: "#424242" }}>Asset Type</Typography>
                  <TextField
                    select
                    SelectProps={inputSize}
                    margin="normal"
                    id="outlined-basic"
                    variant="outlined"
                    name="assetType"
                    onChange={handleOnChange}
                  >
                    <MenuItem value={"Wireless"}>Wireless</MenuItem>
                    <MenuItem value={"Wired"}>Wired</MenuItem>
                  </TextField>
                </Grid>
                <Grid item xs={6}>
                  <Typography sx={{ color: "#424242" }}>Quantity</Typography>
                  <TextField
                    type={"number"}
                    InputProps={inputSize}
                    margin="normal"
                    id="outlined-basic"
                    variant="outlined"
                    name="assetQty"
                    inputProps={{ min: 0 }}
                    value={EditFormData.assetQty}
                    onChange={handleOnChange}
                  />
                </Grid>
              </Grid>
            </Stack>
          </DialogContent>
          <DialogActions>
            <Button
              onClick={() => updateAsset()}
              variant="contained"
              sx={{
                backgroundColor: "#0288d1",
                textTransform: "none",
                "&:hover": {
                  backgroundColor: "#140B96",
                  boxShadow: "1px #000000",
                },
              }}
              disableRipple
            >
              Save
            </Button>
            <Button
              onClick={handleClose}
              variant="contained"
              sx={{
                backgroundColor: "#E0E0E0",
                textTransform: "none",
                color: "black",
                "&:hover": {
                  backgroundColor: "#E0E0E0",
                  boxShadow: "1px #000000",
                },
              }}
              disableRipple
            >
              Cancel
            </Button>
          </DialogActions>
        </Dialog>

        {/* *modal ADD NEW DATA***  */}

        <Dialog open={AddModalStatus} sx={{ padding: "20px" }}>
          <DialogContent sx={{ padding: "10px" }}>
            <Stack>
              <Grid container spacing={8} rowSpacing={3}>
                <Grid item xs={6}>
                  <Typography sx={{ color: "#424242" }}>
                    Date<span style={{ color: "red" }}>*</span>
                  </Typography>
                  <TextField
                    type={"date"}
                    InputProps={inputSize}
                    margin="normal"
                    id="outlined-basic"
                    variant="outlined"
                    name="date"
                    value={EditFormData.date}
                    defaultValue={EditFormData.date}
                    onChange={handleOnChange}
                  />
                </Grid>
                {/* <Grid item xs={6}>
                  <Typography sx={{ color: '#424242' }}>
                    Location
                  </Typography>
                  <TextField select sx={{ width: "max-content" }} SelectProps={inputSize} margin='normal' id="outlined-basic" l variant="outlined" name='location' defaultValue={EditFormData.location} onChange={handleOnChange}>
                    <MenuItem value={'Bhubaneswar'}>Bhubaneswar</MenuItem>
                    <MenuItem value={'Bangalore'}>Bangalore</MenuItem>
                  </TextField>
                </Grid> */}
                <Grid item xs={6}>
                  <Typography sx={{ color: "#424242" }}>
                    Asset Name<span style={{ color: "red" }}>*</span>
                  </Typography>
                  <TextField
                    select
                    SelectProps={inputSize}
                    margin="normal"
                    id="outlined-basic"
                    variant="outlined"
                    name="assetName"
                    value={EditFormData.assetName}
                    defaultValue={EditFormData.assetName}
                    onChange={handleOnChange}
                  >
                    {assetName.map((item) => (
                      <MenuItem key={item} value={item}>
                        {item}
                      </MenuItem>
                    ))}
                  </TextField>
                </Grid>
                <Grid item xs={6}>
                  <Typography sx={{ color: "#424242" }}>
                    Brand<span style={{ color: "red" }}>*</span>
                  </Typography>
                  <TextField
                    select
                    SelectProps={inputSize}
                    margin="normal"
                    id="outlined-basic"
                    variant="outlined"
                    name="assetBrand"
                    value={EditFormData.assetBrand}
                    defaultValue={EditFormData.assetBrand}
                    onChange={handleOnChange}
                  >
                    {assetBrand[EditFormData.assetName]?.map((item) => (
                      <MenuItem key={item} value={item}>
                        {item}
                      </MenuItem>
                    ))}
                  </TextField>
                </Grid>

                <Grid item xs={6}>
                  <Typography sx={{ color: "#424242" }}>
                    Asset Type<span style={{ color: "red" }}>*</span>
                  </Typography>
                  <TextField
                    select
                    SelectProps={inputSize}
                    margin="normal"
                    id="outlined-basic"
                    variant="outlined"
                    name="assetType"
                    value={EditFormData.assetType}
                    defaultValue={EditFormData.assetType}
                    onChange={handleOnChange}
                  >
                    {assetType[EditFormData.assetName]?.map((item) => (
                      <MenuItem key={item} value={item}>
                        {item}
                      </MenuItem>
                    ))}
                  </TextField>
                </Grid>
                <Grid item xs={6}>
                  <Typography sx={{ color: "#424242" }}>
                    Quantity<span style={{ color: "red" }}>*</span>
                  </Typography>
                  <TextField
                    type={"number"}
                    InputProps={inputSize}
                    margin="normal"
                    id="outlined-basic"
                    variant="outlined"
                    inputProps={{ min: 0 }}
                    name="assetQty"
                    value={EditFormData.assetQty}
                    onChange={handleOnChange}
                  />
                </Grid>
              </Grid>
            </Stack>
          </DialogContent>

          <DialogActions>
            <Button
              onClick={() => createAsset()}
              variant="contained"
              sx={{
                backgroundColor: "#0288d1",
                textTransform: "none",
                "&:hover": {
                  backgroundColor: "#140B96",
                  boxShadow: "1px #000000",
                },
              }}
              disableRipple
            >
              Save
            </Button>
            <Button
              onClick={handleClose}
              variant="contained"
              sx={{
                backgroundColor: "#E0E0E0",
                textTransform: "none",
                color: "black",
                "&:hover": {
                  backgroundColor: "#E0E0E0",
                  boxShadow: "1px #000000",
                },
              }}
              disableRipple
            >
              Cancel
            </Button>
          </DialogActions>
        </Dialog>
      </ThemeProvider>
    </>
  );
};

export default OtherAssets;

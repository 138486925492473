import { createSlice } from "@reduxjs/toolkit";
//Reducer for the workbox application. All global params
// const date = new Date(Date.now()).toISOString().substr(0, 10);
const currentDate = new Date();
const year = currentDate.getFullYear();
const month = String(currentDate.getMonth() + 1).padStart(2, '0'); // Months are zero-indexed
const day = String(currentDate.getDate()).padStart(2, '0');

const dateString = `${year}-${month}-${day}`;
const date = new Date(dateString);



export const formReducer = createSlice({
  name: "formReducer",
  initialState: {
    assignRegisterForm: {
      configuration: {
        processor: "",
        storage: "",
        assetType: "",
        gen: "",
        ram: "",
        brand: "",
        model: "",
      },
      employeeDetails: {
        email: "",
        firstName: "",
        lastName: "",
      },
      requestForm: {
        assetId: "",
        userId: "",
        // location: "",
        assetHandOverDate: date,
        assetEndDate: date,
        assetStDate: date,
        status: "OK",
      },
    },
  },
  reducers: {
    /************************ Application Constant ************************/
    setassignRegisterFormConfig: (state, action) => {
      state.assignRegisterForm.configuration = action.payload;
      state.assignRegisterForm.requestForm = {
        ...state.assignRegisterForm.requestForm,
        location: action.payload.location,
        assetId: action.payload.assetId,
      };
    },

    resetAssignForm: (state, action) => {
      state.assignRegisterForm.configuration = {
        processor: "",
        storage: "",
        assetType: "",
        gen: "",
        ram: "",
        brand: "",
        model: "",
      };
      state.assignRegisterForm.employeeDetails = {
        email: "",
        firstName: "",
        lastName: "",
      };
      state.assignRegisterForm.requestForm = {
        assetId: "",
        userId: "",
        location: "",
        assetHandOverDate: date,
        assetEndDate: date,
        assetStDate: date,
        status: "OK",
      };
    },

    setassignRegisterFormEmployee: (state, action) => {
      state.assignRegisterForm.employeeDetails = action.payload;
      state.assignRegisterForm.requestForm = {
        ...state.assignRegisterForm.requestForm,
        userId: action.payload.userId,
      };
    },

    resetAssignRegisterFormEmployee: (state, action) => {
      state.assignRegisterForm.employeeDetails = {
        email: "",
        firstName: "",
        lastName: "",
      };
    },
    resetAssignFormConfiguration: (state, action) => {
      state.assignRegisterForm.configuration = {
        processor: "",
        storage: "",
        assetType: "",
        gen: "",
        ram: "",
        brand: "",
        model: "",
      };
    },

    setassignRequestForm: (state, action) => {
      console.log("redux",action.payload)
      state.assignRegisterForm.requestForm = {
        ...state.assignRegisterForm.requestForm,
        ...action.payload,
      };
    },
  },
});

// Action creators are generated for each case reducer function
export const {
  setassignRegisterFormConfig,
  resetAssignForm,
  setassignRegisterFormEmployee,
  resetAssignRegisterFormEmployee,
  resetAssignFormConfiguration,
  setassignRequestForm,
} = formReducer.actions;

export default formReducer.reducer;

import * as React from 'react'
import './Secured.css'
import AdminApp from './AdminApp';
import Login from './Pages/Auth/Login';
import { useDispatch } from 'react-redux/es/exports';
import { setUserData, setUserRole } from './redux/reducers/userReducer';
import { useMsal } from "@azure/msal-react";
import { useIsAuthenticated } from '@azure/msal-react';
import axios from 'axios'
import { useState, useEffect, useMemo } from 'react';
import { baseUrl } from './Utils/serviceRequest';
import UserApp from './UserApp';
import LoginLoadingPage from './Pages/LoadingPage/LoginLoadingPage';


function Secured() {
  const isAuthenticated = useIsAuthenticated();


  const { accounts } = useMsal();
  const dispatch = useDispatch();
  const [userData, setUseLocaleData] = useState(null);

  const sendUserDetails = async () => {
    let arr = accounts[0].name.split(" ")
    let firstName = "";
    for (let i = 0; i < arr.length - 1; i++) {
      firstName += `${arr[i]}`;
    }
    let lastName = arr[arr.length - 1]
    try {
      let res = await axios.post(`${baseUrl}/createUser`,
        {
          "userId": accounts[0].username,
          "firstName": firstName,
          "lastName": lastName,
          "email": accounts[0].username
        },
        {
          headers: {
            "Content-Type": "application/json",
            "Access-Control-Allow-Origin": "*"
          }
        }
      )
      if (res.status === 200) {
        setUseLocaleData(res.data)
        // dispatch(setUserRole(res.data?.role??""))
        dispatch(setUserRole({
          role: res.data?.role ?? "",
          employeeId: res.data?.employeeId ?? "",
        }));
      }
    } catch (e) {
      console.error(e)
    }
  }

  useEffect(() => {
    if (isAuthenticated) {
      dispatch(setUserData(accounts[0]))
      sendUserDetails();
    }
  }, [isAuthenticated])



  return (
    <>
      {
        isAuthenticated ? (userData !== null ? (userData?.role === "admin" || userData?.role==="superAdmin" ? <AdminApp /> : <UserApp />) :
          <LoginLoadingPage />
        ) : <Login />
      }
    </>
  );
}

export default Secured;

import React, { useCallback, useState } from 'react'
import { Box, Button, createTheme, Divider, InputLabel, Paper, Tab, Tabs, TextField, Typography, MenuItem, Select, FormControl, Autocomplete, ListItem, ListItemText, Grid } from '@mui/material'
import { LocalizationProvider } from '@mui/x-date-pickers';
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns';
import { DatePicker } from '@mui/x-date-pickers/DatePicker';
import axios from 'axios';
import { baseUrl } from '../../Utils/serviceRequest';
import { SearchOutlined } from '@mui/icons-material';
import { ThemeProvider } from '@emotion/react';
import './AssetAssignForm.css'


const inputSize = {
    style: {
        height: "4vh",
        maxHeight: "32px",
        width: "15em",
        padding: "2px 10px",
    },
}
const dateInputSize = {
    style: {
        height: "4vh",
        maxHeight: "32px",
        padding: "2px 10px",
        paddingLeft: "0px"
    },
    disabled: true
}


let dateTheme = createTheme({
    components: {
        MuiInputBase: {
            styleOverrides: {
                root: {
                    height: '4vh',
                    maxHeight: '32px',
                }
            }
        },
        MuiOutlinedInput: {
            styleOverrides: {
                input: {
                    height: '4vh',
                    maxHeight: '32px',
                    padding: "2px 10px",
                }
            }
        },
        MuiAutocomplete: {
            styleOverrides: {
                root: {
                    height: '2vh !important',
                    maxHeight: '32px'
                }
            }
        }
    }
})

let autoCompleteTheme = createTheme({
    component: {
    }
})

const DateString = (timestamp) => {
    var time = Number(timestamp);
    console.log(time)
    var date = new Date(time).toISOString().substring(0, 10);
    return date;
};
const date = new Date(Date.now()).toISOString().substr(0, 10)
const AssetAssignForm = () => {
    const [assignFormData, setAssignFormData] = useState({
        // [{"softwareId":14,"name":"outlook","licenseStDate":1661472000000,"licenseEndDate":1692921600000,"noOfLicense":20,"licenseAssigned":10,"description":null,"price":150.0,"type":"paid","organization":"Incture","version":"1.2.2"}]
        softwareId: 0,
        name: '',
        licenseStDate: '',
        licenseEndDate: '',
        noOfLicense: '',
        licenseAssigned: '00',
        version: '',
        price: '',
        type: '',
        organization: '',
        description: null
    })


    let handleFormOnChange = (e) => {
        let value = e.target.value
        let name = e.target.name
        let stateData = { ...assignFormData }
        stateData[name] = value
        setAssignFormData(stateData)
    }

    const _assignAsset = async () => {
        console.log(assignFormData)
        try {
            let res = await axios.post(`${baseUrl}/assignLicense`, {softwareId: assignFormData.softwareId, userId: assignFormData.userId,assignedDate: assignFormData.licenseStDate,
                revokeDate: assignFormData.licenseEndDate,
            remarks: "",
            
        })
            if (res.status === 200) {
                setAssignFormData({
                    softwareId: 0,
                    name: '',
                    licenseStDate: '',
                    licenseEndDate: '',
                    noOfLicense: '',
                    licenseAssigned: '00',
                    version: '',
                    price: '',
                    type: '',
                    organization: ''
                })
            }
        } catch (error) {
            console.error(error)
        }
    }



    return (
        <>
            <Box sx={{ height: '100%', display: 'flex', flexDirection: 'column', justifyContent: 'space-between', alignItems: 'center' }}>
                <Box sx={{ marginBottom: '1em' }}>
                    <RenderConfigForm
                        assignFormData={assignFormData}
                        handleFormOnChange={handleFormOnChange}
                        setAssignFormData={setAssignFormData}
                    />
                    <RenderUserForm
                        assignFormData={assignFormData}
                        handleFormOnChange={handleFormOnChange}
                        setAssignFormData={setAssignFormData}
                    />
                </Box>
                <Button variant='contained' color='info' onClick={() => _assignAsset()}>Submit</Button>
            </Box>
        </>
    )
}

export default AssetAssignForm




const RenderUserForm = ({ assignFormData, handleFormOnChange, setAssignFormData }) => {


    const [userSearchData, setUserSearchData] = useState([])

    const [userSelected, setUserSelected] = useState({})


    const debounce = (func) => {
        let timer;
        return function (...args) {
            const context = this;
            if (timer) clearTimeout(timer);
            timer = setTimeout(() => {
                timer = null;
                func.apply(context, args);
            }, 500);
        };
    };


    const handleChange = async (value) => {
        try {
            let data = await (await axios.get(`${baseUrl}/getDetailsByNameOrEmail?name=${value}`)).data
            if (data) {
                setUserSearchData(data)

            }
        } catch (error) {

        }
    };


    const optimizedFn = useCallback(debounce(handleChange), []);

    return (
        <Box>
            <Box style={{ padding: '0.8em 2em' }}>

                <Box flexGrow={1} display={'flex'} alignItems='end' justifyContent='space-between'>
                    <Typography variant='h4' fontSize={20} style={{
                        color: "#0288d1",
                        fontWeight: '500', padding: '0.2em 1em 0.2em 0em', textAlign: 'bottom'
                    }}>Employee Details</Typography>
                    <Box sx={{ paddingBottom: '4px' }}>

                        <Autocomplete
                            onChange={(event, value) => {
                                setUserSelected(value)
                                setAssignFormData((prev) => {
                                    
                                    return { ...prev, ...value }
                                })
                            }}
                            options={userSearchData}
                            getOptionLabel={(option) => option.userId}
                            freeSolo
                            disableClearable
                            renderOption={(props, option) => (

                                <ListItem {...props}>
                                    <ListItemText primary={`${option.firstName}${option.lastName}`} secondary={option.userId} />
                                </ListItem>
                            )}
                            renderInput={(params) => {
                                console.log(params)
                                return (
                                    <TextField
                                        {...params}
                                        placeholder="Search for User"
                                        InputProps={{
                                            ...params.InputProps,
                                            type: 'search',
                                            style: {
                                                padding: '0px 4px',
                                                width: '17em',
                                            },
                                            endAdornment: <SearchOutlined color='disabled' />
                                        }}
                                        onChange={(e) => optimizedFn(e.target.value)}
                                    />
                                )
                            }}
                        />
                    </Box>
                </Box>
                <Divider />
            </Box>
            <ThemeProvider theme={dateTheme}>

                <Box sx={{ flexGrow: 1 }}>

                    <Grid container alignItems='center' rowSpacing={2} columnSpacing={12} sx={{ p: '0em 2em' }}>
                        <Grid item sm={3} xs={12}>
                            <InputLabel
                                sx={{
                                    color: "#424242",
                                    fontSize: "16px",
                                    fontWeight: "400",
                                    lineHeight: "24px",
                                    letterSpacing: "0.005em",
                                }}
                            >
                                User Id
                            </InputLabel>
                            <FormControl sx={{ width: "100%" }}>
                                <TextField
                                    inputProps={inputSize}
                                    id="outlined-basic"
                                    variant="outlined"
                                    type="name"
                                    value={userSelected.email}
                                    disabled
                                />
                            </FormControl>
                        </Grid>

                        <Grid item sm={3} xs={12}>
                            <InputLabel
                                sx={{
                                    color: "#424242",
                                    fontSize: "16px",
                                    fontWeight: "400",
                                    lineHeight: "24px",
                                    letterSpacing: "0.005em",
                                }}
                            >
                                Name
                            </InputLabel>
                            <FormControl sx={{ width: "100%" }}>
                                <TextField
                                    inputProps={inputSize}
                                    id="outlined-basic"
                                    variant="outlined"
                                    type="name"
                                    value={userSelected.firstName + '' + userSelected.lastName}
                                    disabled
                                />
                            </FormControl>
                        </Grid>

                        <Grid item sm={3} xs={12}>
                            <InputLabel
                                sx={{
                                    color: "#424242",
                                    fontSize: "16px",
                                    fontWeight: "400",
                                    lineHeight: "24px",
                                    letterSpacing: "0.005em",
                                }}
                            >
                                Assigned Date
                            </InputLabel>
                            <LocalizationProvider dateAdapter={AdapterDateFns}>
                                <DatePicker
                                    InputProps={dateInputSize}
                                    value={assignFormData.assignedDate}
                                    onChange={(newValue) => {
                                        setAssignFormData((prev) => {
                                            return {
                                                ...prev, assignedDate: `${Date.parse(newValue) ? newValue.toISOString().substr(0, 10) : { date }}`
                                            }
                                        })
                                    }}
                                    renderInput={(params) =>
                                        <FormControl sx={{ width: '100%' }}>
                                            <TextField
                                                {...params}
                                            />
                                        </FormControl>
                                    }
                                />
                            </LocalizationProvider>
                        </Grid>


                    </Grid>
                </Box>
            </ThemeProvider>
        </Box>
    )
}

const RenderConfigForm = ({ assignFormData, handleFormOnChange, setAssignFormData }) => {


    const [assetSearchData, setAssetSearchData] = useState([])

    const [assetSelected, setAssetSelected] = useState({})


    const debounce = (func) => {
        let timer;
        return function (...args) {
            const context = this;
            if (timer) clearTimeout(timer);
            timer = setTimeout(() => {
                timer = null;
                func.apply(context, args);
            }, 500);
        };
    };


    const handleChange = async (value) => {
        try {
            if(value) {
                let data = await (await axios.get(`${baseUrl}/getIntangibleMasterByName?name=${value}`)).data
                if (data) {
                    setAssetSearchData(data)
                }

            }
        } catch (error) {

        }
    };

    const optimizedFn = useCallback(debounce(handleChange), []);

    return (
        <Box>
            <Box style={{ padding: '0.8em 2em' }}>
                <Box flexGrow={1} display={'flex'} alignItems='end' justifyContent='space-between'>
                    <Typography variant='h4' fontSize={20} style={{
                        color: "#0288d1",
                        fontWeight: '500',
                        padding: '0.2em 1em 0.2em 0em', textAlign: 'bottom'
                    }}>License Details</Typography>
                    <Box sx={{ paddingBottom: '4px' }}>
                        <Autocomplete
                            onChange={(event, value) => {
                                setAssetSelected((prev) => {
                                    return {
                                        ...prev,
                                        ...value, licenseEndDate: DateString(value.licenseEndDate)
                                    }
                                }
                                )
                                setAssignFormData((prev) => {
                                    return {
                                        ...prev,
                                        ...value,
                                        licenseEndDate: DateString(value.licenseEndDate)
                                    }
                                })
                            }}
                            options={assetSearchData}
                            getOptionLabel={(option) => option.name}
                            freeSolo
                            disableClearable
                            renderOption={(props, option) => (
                                <ListItem {...props}>
                                    <ListItemText primary={`${option.name}`} />
                                </ListItem>
                            )}
                            renderInput={(params) => (
                                <TextField
                                    {...params}
                                    placeholder="Search for Intangible Asset"
                                    InputProps={{
                                        ...params.InputProps,
                                        type: 'search',
                                        style: {
                                            padding: '0px 4px',
                                            width: '17em'
                                        },
                                        endAdornment: <SearchOutlined color='disabled' />
                                    }}
                                    onChange={(e) => optimizedFn(e.target.value)}
                                />
                            )}
                        />
                    </Box>
                </Box>
                <Divider />
            </Box>
            <Box sx={{ flexGrow: 1 }}>
                <Grid container alignItems='center' rowSpacing={2} columnSpacing={12} sx={{ p: '0em 2em' }}>
                    <Grid item sm={3} xs={12}>
                        <InputLabel
                            sx={{
                                color: "#424242",
                                fontSize: "16px",
                                fontWeight: "400",
                                lineHeight: "24px",
                                letterSpacing: "0.005em",
                            }}
                        >
                            Asset Name
                        </InputLabel>
                        <FormControl sx={{ width: "100%" }}>
                            <TextField id="outlined-basic" variant="outlined"
                                inputProps={inputSize}
                                disabled
                                value={assetSelected.name}
                            />
                        </FormControl>
                    </Grid>
                    <Grid item sm={3} xs={12}>
                        <InputLabel
                            sx={{
                                color: "#424242",
                                fontSize: "16px",
                                fontWeight: "400",
                                lineHeight: "24px",
                                letterSpacing: "0.005em",
                            }}
                        >
                            Revoke Date
                        </InputLabel>
                        <FormControl sx={{ width: "100%" }}>
                            <TextField id="outlined-basic" variant="outlined"
                                inputProps={inputSize}
                                disabled
                                value={assetSelected?.licenseEndDate}
                            />
                        </FormControl>


                    </Grid>
                    <Grid item sm={3} xs={12}>
                        <InputLabel
                            sx={{
                                color: "#424242",
                                fontSize: "16px",
                                fontWeight: "400",
                                lineHeight: "24px",
                                letterSpacing: "0.005em",
                            }}
                        >
                            Version
                        </InputLabel>
                        <FormControl sx={{ width: "100%" }}>
                            <TextField id="outlined-basic" variant="outlined"
                                inputProps={inputSize}
                                disabled
                                value={assetSelected.version}
                            />
                        </FormControl>
                    </Grid>
                    <Grid item sm={3} xs={12}>
                        <InputLabel
                            sx={{
                                color: "#424242",
                                fontSize: "16px",
                                fontWeight: "400",
                                lineHeight: "24px",
                                letterSpacing: "0.005em",
                            }}
                        >
                            Number Of License
                        </InputLabel>
                        <FormControl sx={{ width: "100%" }}>
                            <TextField id="outlined-basic" variant="outlined"
                                inputProps={inputSize}
                                disabled
                                value={assetSelected.noOfLicense}
                            />
                        </FormControl>
                    </Grid>
                    <Grid item sm={3} xs={12}>
                        <InputLabel
                            sx={{
                                color: "#424242",
                                fontSize: "16px",
                                fontWeight: "400",
                                lineHeight: "24px",
                                letterSpacing: "0.005em",
                            }}
                        >
                            Price
                        </InputLabel>
                        <FormControl sx={{ width: "100%" }}>
                            <TextField id="outlined-basic" variant="outlined"
                                inputProps={inputSize}
                                disabled
                                value={assetSelected.price}
                            />
                        </FormControl>
                    </Grid>
                    <Grid item sm={3} xs={12}>
                        <InputLabel
                            sx={{
                                color: "#424242",
                                fontSize: "16px",
                                fontWeight: "400",
                                lineHeight: "24px",
                                letterSpacing: "0.005em",
                            }}
                        >
                            Available license
                        </InputLabel>
                        <FormControl sx={{ width: "100%" }}>
                            <TextField id="outlined-basic" variant="outlined"
                                inputProps={inputSize}
                                disabled
                                value={parseInt(assetSelected.noOfLicense) - parseInt(assetSelected.licenseAssigned)}
                            />
                        </FormControl>
                    </Grid>
                </Grid>
            </Box>
        </Box>
    )
}

// id: 0,
// name: '',
// licenseStDate: '',
// licenseEndDate: '',
// noOfLicense: '',
// licenseAssigned: '00',
// version: '',
// price: '',
// type: '',
// organization: ''
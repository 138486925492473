import { AppBar, Button, Toolbar, Typography } from "@mui/material";
import React, { useState } from "react";
import { useMsal } from "@azure/msal-react";
import { loginRequest } from "../../Utils/authConfig";
import LoginImage from "../../Assets/LoginImage.png";
import InctureLogo from "../../Assets/InctureLogo(1).png";
import InAm from "../../Assets/InctureLogo.png";
import LoginSideLogo from "../../Assets/LoginSideLogo.jpg";
import one from "../../Assets/LoginImages/1.png";
import two from "../../Assets/LoginImages/2.jpg";
import three from "../../Assets/LoginImages/3.png";
import four from "../../Assets/LoginImages/4.jpg";
import five from "../../Assets/LoginImages/5.png";
import six from "../../Assets/LoginImages/6.webp";
import seven from "../../Assets/LoginImages/7.png";
import eight from "../../Assets/LoginImages/8.png";

import "./Login.css";

function handleLogin(instance) {
  instance.loginRedirect(loginRequest).catch((e) => {
    console.error(e);
  });
}

const toolbarStyle = {
  minHeight: "52px",
};

const Login = () => {
  const { instance } = useMsal();

  const Element = `<li style="--i:0">
  <a href="#"><img class="icon" src=${one} /></a>
</li>
<li style="--i:1">
  <a href="#"><img class="icon" src=${two} /></ion-icon></a>
</li>
<li style="--i:2">
  <a href="#"><img class="icon" src=${three} /></ion-icon></a>
</li>
<li style="--i:3">
  <a href="#"><img class="icon" src=${four} /></ion-icon></a>
</li>
<li style="--i:4">
  <a href="#"><img class="icon" src=${five} /></ion-icon></a>
</li>
<li style="--i:5">
  <a href="#"><img class="icon" src=${six} /></ion-icon></a>
</li>
<li style="--i:6">
  <a href="#"><img class="icon" src=${seven} /></ion-icon></a>
</li>
<li style="--i:7">
  <a href="#"><img class="icon" src=${eight} /></ion-icon></a>
</li>`;

  const [toggleState, setToggleState] = useState(false);
  const handleToggle = () => {
    setToggleState(true);
  };
  const handleRemoveToggle = () => {
    setToggleState(false);
  };
  return (
    <>
      <div style={{ display: "flex", flexDirection: "row" }}>
        <div
          style={{
            padding: "0",
            height: "100vh",
            width: "250px",
            background: `url(${LoginSideLogo})`,
          }}
        ></div>
        <div
          style={{
            display: "flex",
            flexDirection: "column",
            height: "100%",
          }}
        >
          <div
            style={{
              display: "flex",
              flexDirection: "row",
              width: "100%",
              padding: "10px",
              justifyContent: "flex-end",
            }}
          >
            <img src={InctureLogo} alt="incture logo" />
          </div>
          <div
            style={{
              display: "flex",
              flexDirection: "row",
              justifyContent: "center",
              alignItems: "center",
            }}
          >
            <div
              style={{
                display: "flex",
                alignItems: "center",
                flexDirection: "row",
                justifyContent: "flex-end",

                paddingLeft: "5rem",
              }}
            >
              <img src={InAm} alt="InAm" />
            </div>
            <div
              style={{ marginLeft: "15rem" }}
              className={`menu ${toggleState ? "active" : ""}`}
            >
              <div
                className="toggle"
                onMouseOver={() => handleToggle()}
                onMouseOut={() => handleRemoveToggle()}
                onClick={() => handleLogin(instance)}
              >
                Login
              </div>
              <div
                style={{ borderRadius: "50%" }}
                dangerouslySetInnerHTML={{ __html: Element }}
              ></div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default Login;

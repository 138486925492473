import * as React from "react";
import HomeOutlinedIcon from "@mui/icons-material/HomeOutlined";
import ChatBubbleOutlineOutlinedIcon from "@mui/icons-material/ChatBubbleOutlineOutlined";
import HelpOutlineOutlinedIcon from "@mui/icons-material/HelpOutlineOutlined";
import AssessmentOutlinedIcon from "@mui/icons-material/AssessmentOutlined";
import AppsOutlinedIcon from "@mui/icons-material/AppsOutlined";
import AdminPanelSettingsOutlinedIcon from "@mui/icons-material/AdminPanelSettingsOutlined";
import WebhookOutlinedIcon from "@mui/icons-material/WebhookOutlined";
import { NavLink } from "react-router-dom";
import {
  List,
  ListItem,
  ListItemIcon,
  ListItemButton,
  Typography,
  Paper,
  ThemeProvider,
  createTheme,
  Stack,
} from "@mui/material";
import InventoryIcon from "@mui/icons-material/InventoryOutlined";
import ManagementIcon from "@mui/icons-material/ManageAccountsOutlined";
import ReportIcon from "@mui/icons-material/ReportOutlined";
import AccountIcon from "@mui/icons-material/AccountCircleOutlined";
import OtherAssetsIcon from "@mui/icons-material/NoteOutlined";
import "./SideNavbar.css";
import { setSelectedNavIndex } from "../../redux/reducers/appReducer";
import { useDispatch, useSelector } from "react-redux";
import WorkIcon from "@mui/icons-material/Work";
import CasesOutlinedIcon from "@mui/icons-material/CasesOutlined";
import TerminalIcon from "@mui/icons-material/Terminal";
import CustomTheme from "../../Theme/CustomTheme";
import { Box } from "@mui/system";
import { PersonOutline } from "@mui/icons-material";

function SideNavBar({ isAdmin = false }) {
  return (
    <div>
      <Paper
        elevation={2}
        className="side-bar"
        style={{ backgroundColor: "#262435", borderRadius: "0" }}
      >
        <div style={{ width: "100px" }}>
          <SelectedListItem isAdmin={isAdmin} />
        </div>
      </Paper>
    </div>
  );
}

let demoTheme = createTheme({
  components: {
    MuiTypography: {
      styleOverrides: {
        root: {
          color: "#000",
        },
      },
    },
  },
});

export default SideNavBar;

const SelectedListItem = ({ isAdmin }) => {
  const appReducerState = useSelector((state) => state.appReducer);
  const dispatch = useDispatch();

  const userReducer = useSelector((state) => state.userReducer.userData);

  const handleListItemClick = (event, index) => {
    dispatch(setSelectedNavIndex(index));
  };
  let [showCasesDrawerMenu, setshowCasesDrawerMenu] = React.useState(false);

  let handleShowCasesDrawer = (e) => {
    console.log(e);
    e.stopPropagation();
    e.nativeEvent.stopImmediatePropagation();
    setshowCasesDrawerMenu(true);
    console.log("doing great");
  };
  let handleCloseDrawer = () => {
    setshowCasesDrawerMenu(false);
  };
  return (
    <>
      <List
        component="nav"
        sx={{
          "&& .Mui-selected, && .Mui-selected:hover": {
            borderLeft: "5px solid #04B7CF",
            bgcolor: "#39354c",
            paddingLeft: "3px",
            "&, & .MuiListItemIcon-root": {
              color: "#DBD5D5",
            },
          },
          "& .MuiListItemButton-root:hover": {
            // bgcolor: '#797979',
            // borderLeft: '5px solid #797979'
            // '&, & .MuiListItemIcon-root': {
            //   color: 'yellow',
            // },
          },
        }}
      >
        <NavLink
          to={"/home"}
          style={{
            textDecoration: "none",
          }}
        >
          <ListItemButton
            sx={{
              padding: "0",
              paddingTop: "1vh",
              paddingBottom: "0.5vh",
              paddingLeft: "5px",
              borderLeft: "5px solid #262435",
            }}
            disableRipple
            selected={appReducerState.selectedNavIndex === 0}
            onClick={(event) => handleListItemClick(event, 0)}
          >
            <ListItem
              disablePadding
              sx={{
                display: "block",
                minHeight: 48,
                textAlign: "center !important",
                autofocus: "false",
                textDecoration: "none",
              }}
            >
              <ListItemIcon
                sx={{
                  alignContent: "center",
                  minWidth: 0,
                  justifyContent: "center",
                  color: "primary.light",
                  textDecoration: "none",
                }}
              >
                <HomeOutlinedIcon />
              </ListItemIcon>
              <Typography
                display="block"
                sx={{
                  fontSize: 12,
                  justifyContent: "center",
                  flexGrow: 1,
                  textAlign: "center !important",
                  textDecoration: "none",
                  color: "primary.light",
                }}
              >
                Home
              </Typography>
            </ListItem>
          </ListItemButton>
        </NavLink>

        {isAdmin && (
          <>
            <NavLink
              style={{
                textDecoration: "none",
              }}
              to={"/inventory"}
            >
              <ListItemButton
                disableRipple
                onClick={(event) => handleListItemClick(event, 1)}
                sx={{
                  padding: "0",
                  paddingTop: "1vh",
                  paddingBottom: "0.5vh",
                  paddingLeft: "5px",
                  borderLeft: "5px solid #262435",
                }}
                selected={appReducerState.selectedNavIndex === 1}
              >
                <ListItem
                  disablePadding
                  sx={{
                    display: "block",
                    minHeight: 48,
                    textAlign: "center !important",
                    autofocus: "false",
                  }}
                >
                  <ListItemIcon
                    sx={{
                      display: "flex",
                      alignItems: "center",
                      minWidth: 0,
                      justifyContent: "center",
                      color: "primary.light",
                    }}
                  >
                    <InventoryIcon />
                  </ListItemIcon>
                  <Typography
                    display="block"
                    sx={{
                      fontSize: 12,
                      flexGrow: 1,
                      textAlign: "center",
                      textDecoration: "none",
                      color: "primary.light",
                    }}
                  >
                    Inventory
                  </Typography>
                </ListItem>
              </ListItemButton>
            </NavLink>

            <NavLink
              to={"/management"}
              style={{
                textDecoration: "none",
              }}
            >
              <ListItemButton
                sx={{
                  padding: "0",
                  paddingTop: "1vh",
                  paddingBottom: "0.5vh",
                  paddingLeft: "5px",
                  borderLeft: "5px solid #262435",
                }}
                disableRipple
                onClick={(event) => handleListItemClick(event, 2)}
                selected={appReducerState.selectedNavIndex === 2}
              >
                <ListItem
                  disablePadding
                  sx={{
                    display: "block",
                    minHeight: 48,
                    textAlign: "center",
                    autofocus: "false",
                    alignContent: "center",
                  }}
                >
                  <ListItemIcon
                    sx={{
                      alignContent: "center",
                      justifyContent: "center",
                      color: "primary.light",
                    }}
                  >
                    <ManagementIcon />
                  </ListItemIcon>
                  <Typography
                    display="block"
                    sx={{
                      fontSize: 12,
                      justifyContent: "center",
                      flexGrow: 1,
                      textAlign: "center",
                      color: "primary.light",
                    }}
                  >
                    Management
                  </Typography>
                </ListItem>
              </ListItemButton>
            </NavLink>
            <NavLink
              to={"/reports"}
              style={{
                textDecoration: "none",
              }}
            >
              <ListItemButton
                sx={{
                  padding: "0",
                  paddingTop: "1vh",
                  paddingBottom: "0.5vh",
                  paddingLeft: "5px",
                  borderLeft: "5px solid #262435",
                }}
                disableRipple
                onClick={(event) => handleListItemClick(event, 3)}
                selected={appReducerState.selectedNavIndex === 3}
              >
                <ListItem
                  disablePadding
                  sx={{
                    display: "block",
                    minHeight: 48,
                    textAlign: "center",
                    autofocus: "false",
                  }}
                >
                  <ListItemIcon
                    sx={{
                      alignContent: "center",
                      minWidth: 0,
                      justifyContent: "center",
                      color: "primary.light",
                    }}
                  >
                    <AssessmentOutlinedIcon />
                  </ListItemIcon>
                  <Typography
                    display="block"
                    sx={{
                      fontSize: 12,
                      justifyContent: "center",
                      flexGrow: 1,
                      textAlign: "center !important",
                      color: "primary.light",
                    }}
                  >
                    Reports
                  </Typography>
                </ListItem>
              </ListItemButton>
            </NavLink>
            <NavLink
              to={"/profile"}
              style={{
                textDecoration: "none",
              }}
            >
              <ListItemButton
                sx={{
                  padding: "0",
                  paddingTop: "1vh",
                  paddingBottom: "0.5vh",
                  paddingLeft: "5px",
                  borderLeft: "5px solid #262435",
                }}
                onClick={(event) => handleListItemClick(event, 4)}
                selected={appReducerState.selectedNavIndex === 4}
                disableRipple
              >
                <ListItem
                  disablePadding
                  sx={{
                    display: "block",
                    minHeight: 48,
                    textAlign: "center",
                    autofocus: "false",
                  }}
                >
                  <ListItemIcon
                    sx={{
                      alignContent: "center",
                      minWidth: 0,
                      justifyContent: "center",
                      color: "primary.light",
                    }}
                  >
                    <AccountIcon />
                  </ListItemIcon>
                  <Typography
                    display="block"
                    sx={{
                      fontSize: 12,
                      justifyContent: "center",
                      flexGrow: 1,
                      textAlign: "center !important",
                      color: "primary.light",
                    }}
                  >
                    Look Up
                  </Typography>
                </ListItem>
              </ListItemButton>
            </NavLink>
            <NavLink
              to={"/admincorner"}
              style={{
                textDecoration: "none",
              }}
            >
              <ListItemButton
                sx={{
                  padding: "0",
                  paddingTop: "1vh",
                  paddingBottom: "0.5vh",
                  paddingLeft: "5px",
                  borderLeft: "5px solid #262435",
                }}
                onClick={(event) => handleListItemClick(event, 5)}
                selected={appReducerState.selectedNavIndex === 5}
                disableRipple
              >
                <ListItem
                  disablePadding
                  sx={{
                    display: "block",
                    minHeight: 48,
                    textAlign: "center",
                    autofocus: "false",
                  }}
                >
                  <ListItemIcon
                    sx={{
                      alignContent: "center",
                      minWidth: 0,
                      justifyContent: "center",
                      color: "primary.light",
                    }}
                  >
                    <AdminPanelSettingsOutlinedIcon />
                  </ListItemIcon>
                  <Typography
                    display="block"
                    sx={{
                      fontSize: 12,
                      justifyContent: "center",
                      flexGrow: 1,
                      textAlign: "center !important",
                      color: "primary.light",
                    }}
                  >
                    Admin Corner
                  </Typography>
                </ListItem>
              </ListItemButton>
            </NavLink>
            <NavLink
              to={"/intangibleAssets"}
              style={{
                textDecoration: "none",
              }}
            >
              <ListItemButton
                sx={{
                  padding: "0",
                  paddingTop: "1vh",
                  paddingBottom: "0.5vh",
                  paddingLeft: "5px",
                  borderLeft: "5px solid #262435",
                }}
                disableRipple
                selected={appReducerState.selectedNavIndex === 6}
                onClick={(event) => handleListItemClick(event, 6)}
              >
                <ListItem
                  disablePadding
                  sx={{
                    display: "block",
                    minHeight: 48,
                    textAlign: "center !important",
                    autofocus: "false",
                    textDecoration: "none",
                  }}
                >
                  <ListItemIcon
                    sx={{
                      alignContent: "center",
                      minWidth: 0,
                      justifyContent: "center",
                      color: "primary.light",
                      textDecoration: "none",
                    }}
                  >
                    <WebhookOutlinedIcon />
                  </ListItemIcon>
                  <Typography
                    display="block"
                    sx={{
                      fontSize: 12,
                      justifyContent: "center",
                      flexGrow: 1,
                      textAlign: "center !important",
                      textDecoration: "none",
                      color: "primary.light",
                    }}
                  >
                    Intangible Assets
                  </Typography>
                </ListItem>
              </ListItemButton>
            </NavLink>
            {userReducer?.role === "superAdmin" && (
              <NavLink
                to={"/userDetails"}
                style={{
                  textDecoration: "none",
                }}
              >
                <ListItemButton
                  sx={{
                    padding: "0",
                    paddingTop: "1vh",
                    paddingBottom: "0.5vh",
                    paddingLeft: "5px",
                    borderLeft: "5px solid #262435",
                  }}
                  disableRipple
                  selected={appReducerState.selectedNavIndex === 7}
                  onClick={(event) => handleListItemClick(event, 7)}
                >
                  <ListItem
                    disablePadding
                    sx={{
                      display: "block",
                      minHeight: 48,
                      textAlign: "center !important",
                      autofocus: "false",
                      textDecoration: "none",
                    }}
                  >
                    <ListItemIcon
                      sx={{
                        alignContent: "center",
                        minWidth: 0,
                        justifyContent: "center",
                        color: "primary.light",
                        textDecoration: "none",
                      }}
                    >
                      <PersonOutline />
                    </ListItemIcon>
                    <Typography
                      display="block"
                      sx={{
                        fontSize: 12,
                        justifyContent: "center",
                        flexGrow: 1,
                        textAlign: "center !important",
                        textDecoration: "none",
                        color: "primary.light",
                      }}
                    >
                      User Details
                    </Typography>
                  </ListItem>
                </ListItemButton>
              </NavLink>
            )}
          </>
        )}

        {/* cases  */}
        {!isAdmin && (
          <>
            <NavLink
              to={"/cases"}
              onMouseOver={handleShowCasesDrawer}
              onMouseLeave={handleCloseDrawer} //allows to close drawer when navigating to other menu links
              style={{
                textDecoration: "none",
              }}
            >
              <ListItemButton
                sx={{
                  padding: "0",
                  paddingTop: "1vh",
                  paddingBottom: "0.5vh",
                  paddingLeft: "5px",
                  borderLeft: "5px solid #262435",
                }}
                onClick={(event) => handleListItemClick(event, 6)}
                selected={appReducerState.selectedNavIndex === 6}
                disableRipple
              >
                <ListItem
                  disablePadding
                  sx={{
                    display: "block",
                    minHeight: 48,
                    textAlign: "center",
                    autofocus: "false",
                  }}
                >
                  <ListItemIcon
                    sx={{
                      alignContent: "center",
                      minWidth: 0,
                      justifyContent: "center",
                      color: "primary.light",
                    }}
                  >
                    <CasesOutlinedIcon />
                  </ListItemIcon>
                  <Typography
                    display="block"
                    sx={{
                      fontSize: 12,
                      justifyContent: "center",
                      flexGrow: 1,
                      textAlign: "center !important",
                      color: "primary.light",
                    }}
                  >
                    Cases
                  </Typography>
                </ListItem>
              </ListItemButton>
            </NavLink>
          </>
        )}
        {/* <NavLink
                    to={'/cases'}
                    onMouseOver={handleShowCasesDrawer}
                    onMouseLeave={handleCloseDrawer} //allows to close drawer when navigating to other menu links


                    style={{
                        textDecoration: "none",
                    }}>
                    <ListItemButton

                    sx={{
                            padding: "0",
                            paddingTop: "1vh",
                            paddingBottom: "0.5vh",
                            paddingLeft: '5px',
                            borderLeft: "5px solid #262435"
                        }}
                        onClick={(event) => handleListItemClick(event, 6)}
                        selected={appReducerState.selectedNavIndex === 6}
                        disableRipple
                    >
                        <ListItem

                            disablePadding
                            sx={{
                                display: "block",
                                minHeight: 48,
                                textAlign: "center",
                                autofocus: "false"
                            }}
                        >
                            <ListItemIcon
                            
                                sx={{
                                    alignContent: "center",
                                    minWidth: 0,
                                    justifyContent: "center",
                                    color: "primary.light"
                                }}
                            >
                                <CasesOutlinedIcon />
                            </ListItemIcon>
                            <Typography
                                display="block"
                                sx={{
                                    fontSize: 12,
                                    justifyContent: "center",
                                    flexGrow: 1,
                                    textAlign: "center !important",
                                    color: "primary.light"
                                }}
                            >
                                Cases
                            </Typography>
                        </ListItem>
                    </ListItemButton>
                </NavLink> */}

        {/* cases sidedrawer  */}

        {/* <Stack
                    onMouseLeave={handleCloseDrawer}
                    onMouseOver={handleShowCasesDrawer}

                    sx={{
                        height: 'calc(100vh - 52px)',
                        width: '300px',
                        backgroundColor: '#797979',
                        position: 'absolute',
                        zIndex: showCasesDrawerMenu ? '5' : '-5',
                        top: '0',
                        left: '100%'
                    }}
                >
                    <NavLink
                        to={"/otherAssets"} style={{
                            textDecoration: "none",
                        }}>
                        <ListItemButton sx={{
                            padding: "0",
                            paddingTop: "1vh",
                            paddingBottom: "0.5vh",
                            '&:hover': {
                                backgroundColor: '#262435'
                            }
                        }}
                            onClick={(event) => handleListItemClick(event, 5)}
                            selected={appReducerState.selectedNavIndex === 5}
                            disableRipple
                        >
                            <ListItem
                                disablePadding
                                sx={{
                                    display: "block",
                                    minHeight: 48,
                                    textAlign: "center",
                                    autofocus: "false"
                                }}
                            >

                                <Typography
                                    display="block"
                                    sx={{
                                        fontSize: 12,
                                        justifyContent: "center",
                                        flexGrow: 1,
                                        textAlign: "center !important",
                                        color: "white"
                                    }}
                                >
                                    My Cases
                                </Typography>
                            </ListItem>
                        </ListItemButton>
                    </NavLink>
                    <NavLink
                        to={"/otherAssets"} style={{
                            textDecoration: "none",
                        }}>
                        <ListItemButton sx={{
                            padding: "0",
                            paddingTop: "1vh",
                            paddingBottom: "0.5vh",
                            '&:hover': {
                                backgroundColor: '#262435'
                            }
                        }}
                            onClick={(event) => handleListItemClick(event, 5)}
                            selected={appReducerState.selectedNavIndex === 5}
                            disableRipple
                        >
                            <ListItem
                                disablePadding
                                sx={{
                                    display: "block",
                                    minHeight: 48,
                                    textAlign: "center",
                                    autofocus: "false"
                                }}
                            >

                                <Typography
                                    display="block"
                                    sx={{
                                        fontSize: 12,
                                        justifyContent: "center",
                                        flexGrow: 1,
                                        textAlign: "center !important",
                                        color: "white"

                                    }}
                                >
                                    Open Cases
                                </Typography>
                            </ListItem>
                        </ListItemButton>
                    </NavLink>
                    <NavLink
                        to={"/otherAssets"} style={{
                            textDecoration: "none",
                        }}>
                        <ListItemButton sx={{
                            padding: "0",
                            paddingTop: "1vh",
                            paddingBottom: "0.5vh",
                            '&:hover': {
                                backgroundColor: '#262435'
                            }
                        }}
                            onClick={(event) => handleListItemClick(event, 5)}
                            selected={appReducerState.selectedNavIndex === 5}
                            disableRipple
                        >
                            <ListItem
                                disablePadding
                                sx={{
                                    display: "block",
                                    minHeight: 48,
                                    textAlign: "center",
                                    autofocus: "false"
                                }}
                            >

                                <Typography
                                    display="block"
                                    sx={{
                                        fontSize: 12,
                                        justifyContent: "center",
                                        flexGrow: 1,
                                        textAlign: "center !important",
                                        color: "white"
                                    }}
                                >
                                    My Cases
                                </Typography>
                            </ListItem>
                        </ListItemButton>
                    </NavLink>
                    <NavLink
                        to={"/otherAssets"} style={{
                            textDecoration: "none",
                        }}>
                        <ListItemButton sx={{
                            padding: "0",
                            paddingTop: "1vh",
                            paddingBottom: "0.5vh",
                            '&:hover': {
                                backgroundColor: '#262435'
                            }
                        }}
                            onClick={(event) => handleListItemClick(event, 5)}
                            selected={appReducerState.selectedNavIndex === 5}
                            disableRipple
                        >
                            <ListItem
                                disablePadding
                                sx={{
                                    display: "block",
                                    minHeight: 48,
                                    textAlign: "center",
                                    autofocus: "false"
                                }}
                            >

                                <Typography
                                    display="block"
                                    sx={{
                                        fontSize: 12,
                                        justifyContent: "center",
                                        flexGrow: 1,
                                        textAlign: "center !important",
                                        color: "white"
                                    }}
                                >
                                    Unpicked
                                </Typography>
                            </ListItem>
                        </ListItemButton>
                    </NavLink>
                    <NavLink
                        to={"/otherAssets"} style={{
                            textDecoration: "none",
                        }}>
                        <ListItemButton sx={{
                            padding: "0",
                            paddingTop: "1vh",
                            paddingBottom: "0.5vh",
                            '&:hover': {
                                backgroundColor: '#262435'
                            }
                        }}
                            onClick={(event) => handleListItemClick(event, 5)}
                            selected={appReducerState.selectedNavIndex === 5}
                            disableRipple
                        >
                            <ListItem
                                disablePadding
                                sx={{
                                    display: "block",
                                    minHeight: 48,
                                    textAlign: "center",
                                    autofocus: "false"
                                }}
                            >

                                <Typography
                                    display="block"
                                    sx={{
                                        fontSize: 12,
                                        justifyContent: "center",
                                        flexGrow: 1,
                                        textAlign: "center !important",
                                        color: "white"
                                    }}
                                >
                                    All Cases
                                </Typography>
                            </ListItem>
                        </ListItemButton>
                    </NavLink>
                    <NavLink
                        to={"/otherAssets"} style={{
                            textDecoration: "none",
                        }}>
                        <ListItemButton sx={{
                            padding: "0",
                            paddingTop: "1vh",
                            paddingBottom: "0.5vh",
                            '&:hover': {
                                backgroundColor: '#262435'
                            }
                        }}
                            onClick={(event) => handleListItemClick(event, 5)}
                            selected={appReducerState.selectedNavIndex === 5}
                            disableRipple
                        >
                            <ListItem
                                disablePadding
                                sx={{
                                    display: "block",
                                    minHeight: 48,
                                    textAlign: "center",
                                    autofocus: "false"
                                }}
                            >

                                <Typography
                                    display="block"
                                    sx={{
                                        fontSize: 12,
                                        justifyContent: "center",
                                        flexGrow: 1,
                                        textAlign: "center !important",
                                        color: "white"
                                    }}
                                >
                                    My Requests
                                </Typography>
                            </ListItem>
                        </ListItemButton>
                    </NavLink>
                </Stack> */}
        {/* *******drawer************  */}
      </List>
      {/* <List component="nav">
                <ListItem
                    disablePadding
                    sx={{
                        display: "block",
                        minHeight: 48,
                        textAlign: "center",
                        autofocus: "false",
                        marginTop: "2.5vh"
                    }}
                >
                    <ListItemIcon
                        sx={{
                            alignContent: "center",
                            minWidth: 0,
                            justifyContent: "center",
                            marginBottom: "1.5vh",
                            color: "primary.light"
                        }}
                    >
                        <ChatBubbleOutlineOutlinedIcon />
                    </ListItemIcon>
                    <br></br>

                    <ListItemIcon
                        sx={{
                            alignContent: "center",
                            minWidth: 0,
                            justifyContent: "center",
                            color: "primary.light"
                        }}
                    >
                        <AppsOutlinedIcon />
                    </ListItemIcon>

                </ListItem>
            </List> */}
    </>
  );
};

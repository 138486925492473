import {
    Alert,
  Box,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  Grid,
  Menu,
  MenuItem,
  Paper,
  Skeleton,
  Snackbar,
  Stack,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  TextField,
  Typography,
} from "@mui/material";
import React, { useEffect, useState } from "react";
import MoreVertIcon from "@mui/icons-material/MoreVert";
import AddCircleIcon from "@mui/icons-material/AddCircle";
import axios from "axios";
import { baseUrl } from "../../Utils/serviceRequest";

function AssetTypeMaster() {
//   const assetMaster = [
//     { name: "laptop", classification: "it asset" },
//     { name: "keyboard", classification: "it asset" },
//     { name: "mouse", classification: "it asset" },
//   ];
//   const classification = ["it asset", "admin"];
const [assetMaster,setAssetMaster]= useState([])
const [classification,setClassification]= useState([])
const[loading,setLoading]=useState(true)

const [snackBarStatus, setSnackBarStatus] = useState(false);
      const [snackBarMessage, setSnackBarMessage] = useState("");
      const [snackBarSeverity, setSnackBarSeverity] = useState("success");

  const inputSize = {
    style: {
      height: "5vh",
      maxHeight: "32px",
      width: "15em",
      padding: "2px 10px",
    },
  };

  const [showAdd, setShowAdd] = useState({
    open:false,
    val:""
  });
  const [editFormData, setEditFormData] = useState({
    assetTypeName: "",
    classificationName: "",
    assetClassificationId:""
  });

  const [showMenu, setShowMenu] = useState(false);
  const [anchorEl, setAnchorEl] = useState(null);
  const [selectedRow, setSelectedRow] = useState(null);
  const handleMenuOpen = (event, row) => {
    // debugger
    console.log("row",row)
    // setRowData(rowData)
    setShowMenu(true);
    setAnchorEl(event.currentTarget);
    setSelectedRow(row);
  };
  const handleClose = () => {
    setEditFormData({
        assetTypeName: "",
        classificationName: "",
    })
    setShowMenu(false);
    setShowAdd({
        open:false,
        val:""
    }
    );
  };

  const handleOnChange = (e) => {
    const name = e.target.name;
    const value=e.target.value;
    const selectedClassification = classification.find(
        (option) => option.classificationName === value
      );
      const assetClassificationId = selectedClassification ? selectedClassification.assetClassificationId : null;
    setEditFormData((prev) => ({
      ...prev,
      [name]: value,
      assetClassificationId: name === "classificationName" ? assetClassificationId : prev.assetClassificationId,    }));
  };
  ///////////add api
  const createAsset = async() => {
    
    // alert("save");
    console.log("123", editFormData);
    if(editFormData.assetTypeName===""){
        setSnackBarMessage("Name field is empty")
        setSnackBarSeverity("error")
        setSnackBarStatus(true)
    }else{

        const data={
            assetTypeName:editFormData.assetTypeName,
            assetClassificationId:editFormData.assetClassificationId
        }
       
          try {
            const res= await axios.post(`${baseUrl}/createType`,data)
            console.log("add response",res)
            if(res.data.result==="success"){
                handleData()
                setEditFormData({
                    assetTypeName: "",
                    classificationName: "",
                })
                handleClose()
                setSnackBarMessage("Data Added Successfully")
                setSnackBarSeverity("success")
                setSnackBarStatus(true)
            }
            else if(res.data.result==="failed"){
                setSnackBarMessage(res.data.message)
                setSnackBarSeverity("error")
                setSnackBarStatus(true) 
            }
          } catch (error) {
            console.log(error.message)
          }
    }
  };
  ////////update api
  const handleUpdate = async() => {
    // alert("update");
    console.log("123", editFormData);
    // debugger
    if(editFormData.assetTypeName===""){
        setSnackBarMessage("Name field is empty")
        setSnackBarSeverity("error")
        setSnackBarStatus(true)
    }else if(editFormData.classificationName===""||editFormData.classificationName===undefined){
        setSnackBarMessage("classification field is empty")
        setSnackBarSeverity("error")
        setSnackBarStatus(true) 
    }else{
    const data={
        assetTypeName:editFormData.assetTypeName,
        assetClassificationId:editFormData.assetClassificationId
    }
    try {
        const res= await axios.put(`${baseUrl}/updateTypeMaster/${selectedRow.assetTypeId}`,data)
        console.log("add response",res)
        if(res.data.result==="success"){
            handleData()
            setEditFormData({
                assetTypeName: "",
                classificationName: "",
            })
            handleClose()
            setSnackBarMessage("Data Updated Successfully")
            setSnackBarSeverity("success")
            setSnackBarStatus(true)

        }
        else if(res.data.result==="failed"){
          setSnackBarMessage(res.data.message)
          setSnackBarSeverity("error")
          setSnackBarStatus(true) 
      }
        else{
            setSnackBarMessage("Some thing went wrong")
            setSnackBarSeverity("error")
            setSnackBarStatus(true) 
        }

      } catch (error) {
        console.log(error.message)
      }
    }
  };
//////////delete api
  const handleDelete=async()=>{
    console.log("delete",selectedRow)
    try {
        const res= await axios.delete(`${baseUrl}/deleteTypeMaster/${selectedRow.assetTypeId}`)
        console.log("delete response",res)
        if(res.data.result==="success"){
             
            handleData()
            setSnackBarMessage("Data Deleted Successfully")
            setSnackBarSeverity("success")
            setSnackBarStatus(true)
            handleClose()
            setEditFormData({
                assetTypeName: "",
                classificationName: "",
            })

        }
        else{
            setSnackBarMessage("Some thing went wrong")
            setSnackBarSeverity("error")
            setSnackBarStatus(true) 
        }
    } catch (error) {
        console.log(error.message)
    }
  }
////////get asset api
  const handleData=async()=>{
    
    try {
      const response= await axios.get(`${baseUrl}/allAssetTypes`)
    //   console.log("AssetMaster",response.data)
      if(response.data.result==="success"){
       setAssetMaster(response.data.data)
       setLoading(false)
      }
    } catch (error) {
     console.log(error.message)
    }
 
   }


  useEffect(()=>{
    ////////get classification api
    const handleDataClassification=async()=>{
    
        try {
          const response= await axios.get(`${baseUrl}/allClassifications`)
        //   console.log("classification",response.data)
          if(response.data.result==="success"){
           setClassification(response.data.data)
           setLoading(false)
          }
        } catch (error) {
         console.log(error.message)
        }
     
       }
   
       handleData()
       handleDataClassification()
  },[])
  useEffect(() => {
    if (selectedRow) {
      setEditFormData({
        assetTypeName: selectedRow?.assetTypeName,
        classificationName: selectedRow.classificationMaster?.classificationName,
        assetClassificationId:selectedRow.classificationMaster?.assetClassificationId
      }); // Initialize form data with selected row when selectedRow changes
    }
  }, [selectedRow]);

  return (
    <>
     
      <Box sx={{ margin: "auto", width: "60%", marginTop: "20px" }}>
{loading? <Box
      sx={{
        display: 'flex',
        flexDirection: 'column',
        gap: '1rem',
      }}>
      <Skeleton variant='rounded' sx={{ width: '100%' }} height={60} />
      <Skeleton variant='rounded' sx={{ width: '100%' }} height={30} />
      <Skeleton variant='rounded' sx={{ width: '100%' }} height={30} />
      <Skeleton variant='rounded' sx={{ width: '100%' }} height={30} />
    </Box>:

        <TableContainer component={Paper}>
          <Table>
            <TableHead>
              <TableRow sx={{ backgroundColor: "#F1F5FE" }}>
                <TableCell sx={{ fontWeight: "bold" }}>Sl. No.</TableCell>
                <TableCell sx={{ fontWeight: "bold" }}> Name</TableCell>
                <TableCell sx={{ fontWeight: "bold" }}>
                  Classification
                </TableCell>
                <TableCell sx={{ fontWeight: "bold" }}>Action</TableCell>
              </TableRow>
            </TableHead>

            <TableBody>
              {assetMaster?.map((assetMaster, index) => (
                <TableRow key={index}>
                  <TableCell>{index + 1}</TableCell>
                  <TableCell>{assetMaster.assetTypeName}</TableCell>
                  <TableCell>{assetMaster.classificationMaster?.classificationName??"-"}</TableCell>
                  <TableCell>
                    <Button onClick={(e)=>handleMenuOpen(e,assetMaster)}>
                      <MoreVertIcon />
                    </Button>
                    <Menu
                      anchorEl={anchorEl}
                      open={showMenu}
                      onClose={handleClose}
                    >
                      <MenuItem
                         onClick={()=>(setShowAdd({
                            open:true,
                            val:"update"
                         }))}
                      >
                        Update
                      </MenuItem>

                      <MenuItem
                        onClick={
                          handleDelete
                        }
                      >
                        Delete
                      </MenuItem>
                    </Menu>
                  </TableCell>
                </TableRow>
              ))}
            </TableBody>
          </Table>
          <Box sx={{ display: "flex", justifyContent: "end" }}>
            <Button
              variant="contained"
              startIcon={<AddCircleIcon />}
              sx={{
                margin: "1rem",
                border: "1px solid #F1F5FE",
                backgroundColor: "#F1F5FE",
                color: "#0288d1",
                fontSize: "12px",
                textTransform: "capitalize",
                height: "max-content",
                "&:hover": {
                  backgroundColor: "#F1F5FE",
                  color: "#0288d1",
                  border: "1px solid #0288d1",
                  boxShadow: "1px #000000",
                },
              }}
              onClick={()=>(setShowAdd({
                open:true,
                val:"Add"
             }))}
            >
              Add
            </Button>
          </Box>
        </TableContainer>
}
      </Box>
      {/* *modal ADD NEW DATA***  */}
      <Dialog open={showAdd.val} sx={{ padding: "20px" }}>
        <DialogContent sx={{ padding: "10px" }}>
          <Stack>
            <Grid container spacing={8} rowSpacing={3}>
              <Grid item xs={6}>
                <Typography sx={{ color: "#424242" }}>Name<span style={{ color: "red" }}>*</span></Typography>
                <TextField
                  type={"text"}
                  InputProps={inputSize}
                  margin="normal"
                  id="outlined-basic"
                  variant="outlined"
                  name="assetTypeName"
                  inputProps={{ min: 0 }}
                  value={editFormData.assetTypeName}
                //   defaultValue={selectedRow.assetTypeName}
                  onChange={handleOnChange}
                  autoComplete="off"
                />
              </Grid>
              <Grid item xs={6}>
                <Typography sx={{ color: "#424242" }}>
                  {" "}
                  Classification<span style={{ color: "red" }}>*</span>
                </Typography>
                

                <TextField
                  select
                  SelectProps={inputSize}
                  margin="normal"
                  id="outlined-basic"
                  variant="outlined"
                  name="classificationName"
                  value={editFormData.classificationName}
                //   defaultValue={selectedRow.classificationName}
                  onChange={handleOnChange}
                >
                  {classification.map((val) => {
                    return <MenuItem value={val.classificationName}>{val.classificationName}</MenuItem>;
                  })}
                </TextField>
              </Grid>
            </Grid>
          </Stack>
        </DialogContent>

        <DialogActions>
          <Button
            onClick={() =>{
               if(showAdd.val==="Add") {
                createAsset()
               }
               else{
                handleUpdate()
               }
            } }
            variant="contained"
            sx={{
              backgroundColor: "#0288d1",
              textTransform: "none",
              "&:hover": {
                backgroundColor: "#140B96",
                boxShadow: "1px #000000",
              },
            }}
            disableRipple
          >
            Save
          </Button>
          <Button
            onClick={handleClose}
            variant="contained"
            sx={{
              backgroundColor: "#E0E0E0",
              textTransform: "none",
              color: "black",
              "&:hover": {
                backgroundColor: "#E0E0E0",
                boxShadow: "1px #000000",
              },
            }}
            disableRipple
          >
            Cancel
          </Button>
        </DialogActions>
      </Dialog>
      <Snackbar
                    anchorOrigin={{ vertical: "top", horizontal: "center" }}
                    open={snackBarStatus}
                    autoHideDuration={4000}
                    onClose={() => setSnackBarStatus(false)}
                  >
                    <Alert
                      onClose={() => setSnackBarStatus(false)}
                      severity={snackBarSeverity}
                      sx={{ width: "100%" }}
                    >
                      {snackBarMessage}
                    </Alert>
                  </Snackbar>
    </>
  );
}

export default AssetTypeMaster;

import React from "react";
import {
  BrowserRouter as Router,
  Switch,
  Route,
  Redirect,
} from "react-router-dom";
import AppBarComponent from "./Components/AppBarComponent/AppBarComponent";
import "./App.css";
import { ThemeProvider } from "@emotion/react";
import CustomTheme from "./Theme/CustomTheme";
import UserHome from "./Pages/UserHome/UserHome";
import UserCases from "./Pages/UserCases/UserCases";
import SideNavBar from "./Components/SideNavbar/SideNavbar";
import { useSelector } from "react-redux/es/exports";


const UserApp = () => {

  return (
    <>
        <Router>
          <ThemeProvider theme={CustomTheme}>
            <AppBarComponent />

            <div className="wbMainPageHeight">
              <div className="wbFloatLeft">
                <SideNavBar />
              </div>
              <div className="body-content wbScroll">
                <Routes />
              </div>
            </div>
          </ThemeProvider>
        </Router>
    </>
  );
};

export default UserApp;

const Routes = () => {
  return (
    <Switch>
      {/* Home page */}
      <Route exact path="/home" component={UserHome} />

      {/* Inventory page */}
      <Route exact path="/cases" component={UserCases} />

      <Redirect exact from="/" to="/home" />
    </Switch>
  );
};

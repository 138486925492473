import { Avatar, Paper, Typography } from "@mui/material";
import { Box } from "@mui/system";
import React from "react";
import Bhargab from "../../Assets/bhargab_the_admin.jpg";
import ZohoLogo from "../../Assets/zohoLogo.png";

const UserCases = () => {
  return (
    // <Paper style={{margin: 'auto', display: 'flex', justifyContent: 'center', alignItems: 'center', width: '70%', height: '50vh'}}>
    //   <Avatar src={Bhargab} sx={{height: '200px', width: '200px'}}/>
    //   <div>
    //       <h2>
    //           Go ask bhargab to make you admin
    //       </h2>
    //       <a style={{color: 'red'}} href="mailto:bhargab.sarma@incture.com">bhargab.sarma@incture.com</a>
    //   </div>
    // </Paper>
    <Box
      style={{
        display: "flex",
        height: "calc(100vh - 52px)",
        width: "100%",
        flexDirection: "column",
        justifyContent: "center",
        alignItems: "center",
      }}
    >
      <Paper
        sx={{
          padding: "1em",
          display: "flex",
          flexDirection: "column",
          justifyContent: "center",
          alignItems: "center",
        }}
      >
        <img src={ZohoLogo} height={"50px"} style={{ marginBottom: "10px" }} />
        <Typography variant="h4" fontSize={18} style={{ color: "grey" }}>
          Zoho Cases Coming Soon...
        </Typography>
      </Paper>
    </Box>
  );
};

export default UserCases;

import { SearchOutlined } from "@mui/icons-material";
import {
  Autocomplete,
  Box,
  Button,
  Checkbox,
  createTheme,
  Divider,
  FormControl,
  FormControlLabel,
  Grid,
  InputLabel,
  ListItem,
  ListItemText,
  MenuItem,
  Select,
  TextField,
  ThemeProvider,
  Typography,
  Switch,
  Stack,
  Snackbar,
  Alert,
  Backdrop,
  CircularProgress,
} from "@mui/material";
import { DatePicker, LocalizationProvider } from "@mui/x-date-pickers";
import { AdapterDateFns } from "@mui/x-date-pickers/AdapterDateFns";
import axios from "axios";
import React, { useCallback, useState } from "react";
import { baseUrl } from "../../Utils/serviceRequest";
import AutoCompleteSearch from "../SearchComponent/AutoCompleteSearch";
import AssetAssignForm from "./AssetAssignForm";

const date = new Date(Date.now()).toISOString().substr(0, 10);

let dateTheme = createTheme({
  components: {
    MuiInputBase: {
      styleOverrides: {
        root: {
          height: "4vh",
          maxHeight: "32px",
        },
      },
    },
    MuiOutlinedInput: {
      styleOverrides: {
        input: {
          height: "4vh",
          maxHeight: "32px",
          padding: "2px 10px",
        },
      },
    },
  },
});

const dateInputSize = {
  style: {
    height: "4vh",
    maxHeight: "32px",
    width: "17em",
    padding: "2px 10px",
  },
  disabled: true,
};

const inputSize = {
  style: {
    height: "4vh",
    maxHeight: "32px",
    width: "17em",
    padding: "2px 10px",
  },
};

const OtherAssetReturnForm = () => {
  const [returnFormData, setReturnFormData] = useState({
    id: "",
    assetBrand: "",
    assetName: "",
    assetType: "",
    returnedDate: date,
    isDamaged: false,
  });

  const [isDamaged, setIsDamaged] = useState(false);

  const [userSearchData, setUserSearchData] = useState([]);

  const [userOtherAssetsSelected, setUserOtherAssetsSelected] = useState([]);
  const [otherAssetSelected, setOtherAssetSelected] = useState({});

  //loading state
  const [isLoading, setIsLoading] = useState(false);

  //snackbar states
  const [snackBarStatus, setSnackBarStatus] = useState(false);
  const [snackBarMessage, setSnackBarMessage] = useState("");
  const [snackBarSeverity, setSnackBarSeverity] = useState("success");

  const debounce = (func) => {
    let timer;
    return function (...args) {
      const context = this;
      if (timer) clearTimeout(timer);
      timer = setTimeout(() => {
        timer = null;
        func.apply(context, args);
      }, 500);
    };
  };

  const handleChange = async (value) => {
    try {
      let data = await (
        await axios.get(`${baseUrl}/getDetailsByNameOrEmail?name=${value}`)
      ).data;
      if (data) {
        setUserSearchData(data);
      }
    } catch (error) {}
  };

  const getOtherAssetsofUser = async (value) => {
    try {
      let data = await (
        await axios.get(
          `${baseUrl}/getOtherAssetAssignedDetailsUsingParameter/${value}`
        )
      ).data;
      if (data) {
       
        setUserOtherAssetsSelected(data);
      }
    } catch (error) {}
  };

  const returnAsset = async () => {
    try {
      setIsLoading(true);

      let data = await (
        await axios.put(`${baseUrl}/returnOtherAsset`, returnFormData)
      ).data;
      if (data) {
        setReturnFormData({
          id: "",
          assetBrand: "",
          assetName: "",
          assetType: "",
          returnedDate: date,
          isDamaged: false,
        });
        setIsLoading(false);
        setSnackBarMessage("Returned Successfully");
        setSnackBarSeverity("success");
        setSnackBarStatus(true);
      }
    } catch (error) {
      setIsLoading(false);
      console.error(error);
      setSnackBarMessage("Oops! Something Went Wrong");
      setSnackBarSeverity("success");
      setSnackBarStatus(true);
    }
  };

  const optimizedFn = useCallback(debounce(handleChange), []);


  return (
    <Box>
      <Backdrop
        sx={{ color: "#fff", zIndex: (theme) => theme.zIndex.drawer + 1 }}
        open={isLoading}
      >
        <CircularProgress color="inherit" />
      </Backdrop>
      <Snackbar
        anchorOrigin={{ vertical: "top", horizontal: "center" }}
        open={snackBarStatus}
        autoHideDuration={6000}
        onClose={() => setSnackBarStatus(false)}
      >
        <Alert
          onClose={() => setSnackBarStatus(false)}
          severity={snackBarSeverity}
          sx={{ width: "100%" }}
        >
          {snackBarMessage}
        </Alert>
      </Snackbar>
      <Box style={{ padding: "0.8em 2em" }}>

        <AutoCompleteSearch
          autoCompleteonChange={(value) => {
            getOtherAssetsofUser(value.userId);
          }}
          textOnChange={(val) => optimizedFn(val)}
          options={userSearchData}
          getOptionLabel={"userId"}
          firstNameOption={"firstName"}
          lastNameOption={"lastName"}
          secondaryTextOption={"userId"}
          placeholder={"Search for User"}
        />

        {/* //idhar */}
        <ThemeProvider theme={dateTheme}>
          <Grid mt={2} container spacing={8} rowSpacing={3}>
          <Grid item sm={4} xs={6}>
              <InputLabel
                sx={{
                  color: "#424242",
                  fontSize: "16px",
                  fontWeight: "400",
                  lineHeight: "24px",
                  letterSpacing: "0.005em",
                  marginBottom: "1%",
                }}
              >
                Name
              </InputLabel>
              <FormControl sx={{ width: "100%" }}>
                <TextField
                  id="outlined-basic"
                  variant="outlined"
                  InputProps={inputSize}
                  disabled
                  value={
                    userOtherAssetsSelected[0]?.firstName !== undefined
                      ? `${userOtherAssetsSelected[0]?.firstName ?? ""} ${
                          userOtherAssetsSelected[0]?.lastName ?? ""
                        }`
                      : userOtherAssetsSelected[0]?.firstName ?? ""
                  }
                />
              </FormControl>
            </Grid>

            <Grid item sm={4} xs={6}>
              <InputLabel
                sx={{
                  color: "#424242",
                  fontSize: "16px",
                  fontWeight: "400",
                  lineHeight: "24px",
                  letterSpacing: "0.005em",
                  marginBottom: "1%",
                }}
              >
                Employee ID
              </InputLabel>
              <FormControl sx={{ width: "100%" }}>
                <TextField
                  id="outlined-basic"
                  variant="outlined"
                  InputProps={inputSize}
                  disabled
                  value={userOtherAssetsSelected[0]?.employeeId ?? ""}
                />
              </FormControl>
            </Grid>
            <Grid item sm={4} xs={6}>
              <InputLabel
                sx={{
                  color: "#424242",
                  fontSize: "16px",
                  fontWeight: "400",
                  lineHeight: "24px",
                  letterSpacing: "0.005em",
                  marginBottom: "1%",
                }}
              >
               Email ID
              </InputLabel>
              <FormControl sx={{ width: "100%" }}>
                <TextField
                  id="outlined-basic"
                  variant="outlined"
                  InputProps={inputSize}
                  disabled
                  value={userOtherAssetsSelected[0]?.email ?? ""}
                />
              </FormControl>
            </Grid>
          
          
            <Grid item sm={4} xs={6}>
              <InputLabel
                sx={{
                  color: "#424242",
                  fontSize: "16px",
                  fontWeight: "400",
                  lineHeight: "24px",
                  letterSpacing: "0.005em",
                  marginBottom: "1%",
                }}
              >
                Return Date
              </InputLabel>
              <LocalizationProvider dateAdapter={AdapterDateFns}>
                <DatePicker
                  InputProps={dateInputSize}
                  value={returnFormData.returnedDate}
                  onChange={(newValue) => {
                    setReturnFormData((prev) => {
                      return {
                        ...prev,
                        returnedDate: `${
                          Date.parse(newValue)
                            ? newValue.toISOString().substr(0, 10)
                            : { date }
                        }`,
                      };
                    });
                  }}
                  renderInput={(params) => (
                    <FormControl sx={{ width: "100%" }}>
                      <TextField {...params} />
                    </FormControl>
                  )}
                />
              </LocalizationProvider>
            </Grid>
            <Grid item sm={4} xs={6}>
              <Typography sx={{ color: "#424242", marginBottom: "1%" }}>
                Select Asset
              </Typography>
              <Select
                SelectProps={inputSize}
                style={{ width: "17em" }}
                margin="normal"
                id="outlined-basic"
                variant="outlined"
                defaultValue={returnFormData.assetName}
              >
                {userOtherAssetsSelected.map((item) => {
                  return (
                    <MenuItem value={item.assetName}>
                      <ListItem
                        sx={{ padding: "0" }}
                        onClick={() =>
                          setReturnFormData((prev) => {
                            return {
                              ...prev,
                              id: item["id"],
                              assetName: item["assetName"],
                              assetBrand: item["assetBrand"],
                              assetType: item["assetType"],
                            };
                          })
                        }
                      >
                        <ListItemText
                          primary={`${item.assetName}`}
                          secondary={`Brand: ${item.assetBrand}Type: ${item.assetType}`}
                        />
                      </ListItem>
                    </MenuItem>
                  );
                })}
              </Select>
            </Grid>
            <Grid item sm={4} xs={6}>
              <InputLabel
                sx={{
                  color: "#424242",
                  fontSize: "16px",
                  fontWeight: "400",
                  lineHeight: "24px",
                  letterSpacing: "0.005em",
                  marginBottom: "1%",
                }}
              >
                Asset Name
              </InputLabel>
              <FormControl sx={{ width: "100%" }}>
                <TextField
                  id="outlined-basic"
                  variant="outlined"
                  InputProps={inputSize}
                  disabled
                  value={returnFormData.assetName}
                />
              </FormControl>
            </Grid>

            <Grid item sm={4} xs={6}>
              <InputLabel
                sx={{
                  color: "#424242",
                  fontSize: "16px",
                  fontWeight: "400",
                  lineHeight: "24px",
                  letterSpacing: "0.005em",
                  marginBottom: "1%",
                }}
              >
                Asset Brand
              </InputLabel>
              <FormControl sx={{ width: "100%" }}>
                <TextField
                  id="outlined-basic"
                  variant="outlined"
                  InputProps={inputSize}
                  disabled
                  value={returnFormData.assetBrand}
                />
              </FormControl>
            </Grid>
            <Grid item sm={4} xs={6}>
              <InputLabel
                sx={{
                  color: "#424242",
                  fontSize: "16px",
                  fontWeight: "400",
                  lineHeight: "24px",
                  letterSpacing: "0.005em",
                  marginBottom: "1%",
                }}
              >
                Asset Type
              </InputLabel>
              <FormControl sx={{ width: "100%" }}>
                <TextField
                  id="outlined-basic"
                  variant="outlined"
                  InputProps={inputSize}
                  disabled
                  value={returnFormData.assetType}
                />
              </FormControl>
            </Grid>
            <Grid item sm={4} xs={6}>
              <Box style={{ alignItems: "left", marginTop: "5%" }}>
                <Stack direction="row" spacing={1} alignItems="center">
                  <Typography>Damage</Typography>
                  <FormControlLabel
                    control={
                      <Switch
                        name="isBbsr"
                        checked={isDamaged}
                        onChange={(e) => {
                          setIsDamaged(e.target.checked);
                          setReturnFormData((prev) => {
                            return {
                              ...prev,
                              isDamaged: e.target.checked,
                            };
                          });
                        }}
                        inputProps={{ "aria-label": "controlled" }}
                        sx={{ "& .MuiSvgIcon-root": { fontSize: 18 } }}
                      />
                    }
                  />
                </Stack>
              </Box>
            </Grid>
          </Grid>
        </ThemeProvider>
        <Box
          style={{
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            marginTop: "3%",
          }}
        >
          <Button
            variant="contained"
            color="info"
            onClick={() => returnAsset()}
          >
            Submit
          </Button>
        </Box>
      </Box>
    </Box>
  );
};

export default OtherAssetReturnForm;

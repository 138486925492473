import {
  Grid,
  Tab,
  Tabs,
  Avatar,
  Typography,
  Select,
  Paper,
  Autocomplete,
  ListItem,
  ListItemText,
  TextField,
  Box,
  CircularProgress,
  Button,
  Dialog,
  DialogContent,
  Stack,
  DialogActions,
  MenuItem,
  Snackbar,
  Alert,
  Container,
  Divider,
} from "@mui/material";
import React, { useCallback, useEffect, useState } from "react";
import TableUtility from "../../Components/TableUtility/TableUtility";
import "./profilestyle.css";
import avatarPhoto from "../../Assets/profilei.png";
import {
  resetLookUpEmployeeData,
  resetLookUpRegisteredAssetData,
  setLookUpEmployeeData,
  setLookUpRegisteredAssetData,
  setSelectedNavIndex,
} from "../../redux/reducers/appReducer";
import { useDispatch, useSelector } from "react-redux";
import { SearchOutlined } from "@mui/icons-material";
import { baseUrl } from "../../Utils/serviceRequest";
import axios from "axios";
import PersonSearchIcon from "@mui/icons-material/PersonSearch";
import ManageSearchIcon from "@mui/icons-material/ManageSearch";
import AutoCompleteSearch from "../../Components/SearchComponent/AutoCompleteSearch";

const roleOptions = ["user", "admin", "superAdmin"];

const inputSize = {
  style: {
    height: "5vh",
    maxHeight: "32px",
    width: "15em",
    padding: "2px 10px",
  },
};

const LookUp = () => {
  const dispatch = useDispatch();
  dispatch(setSelectedNavIndex(4));

  const [value, setValue] = useState(0);
  const handleTab = (e, val) => {
    setValue(val);
  };

  return (
    <Box sx={{ padding: "1em" }}>
      <Tabs
        value={value}
        onChange={handleTab}
        sx={{
          "& button": {
            fontWeight: "200",
            fontSize: "16px",
            borderRadius: "4px 4px 0 0",
            border: "solid 1px #ddd",
            padding: "8px 14px",
            textTransform: "none",
          },
          "& button.Mui-selected": {
            fontWeight: "bold",
            fontSize: "16px",
            backgroundColor: "#f1f5fe",
            color: "#0288d1",
            boxShadow: "0",
            padding: "0",
            padding: "8px 14px",
            boxShadow: "rgb(0 0 0 / 25%) 0px 4px 4px 2px",
          },
        }}
        style={{ width: "100%", minHeight: "30px" }}
        TabIndicatorProps={{ hidden: true }}
      >
        <Tab label="Search By Employee ID" />
        <Tab label="Search By Asset Tag" />
      </Tabs>
      {/* </div> */}

      <TabPanel value={value} index={0}>
        <Paper
          className="wbScroll"
          sx={{
            height: "calc(77vh)",
          }}
        >
          <LookUpbyProfile />
        </Paper>
      </TabPanel>
      <TabPanel value={value} index={1}>
        <Paper
          className="wbScroll"
          sx={{
            height: "calc(77vh)",
          }}
        >
          <LookUpbyLaptopTag />
        </Paper>
      </TabPanel>
    </Box>
  );
};
function TabPanel(props) {
  const { children, value, index } = props;
  return <div>{value === index && <div>{children}</div>}</div>;
}

export default LookUp;

const LookUpbyProfile = () => {
  const dispatch = useDispatch();
  // const [userProfileData, setUserProfileData] = useState(null);
  const appReducer = useSelector(
    (state) => state.appReducer.lookUpData.employeeData
  );
  const userReducer = useSelector((state) => state.userReducer.userData);
  const userProfileRegisteredData = appReducer.registeredAssetsData;
  const userProfileOtherAssetData = appReducer.otherAssetsData;
  const [isLoading, setIsLoading] = useState(false);

  ///
  // const date = new Date(parseInt(1712601000000));
  // console.log(date, "date");
  // const formattedDate = `${date.getDate()}/${
  //   date.getMonth() + 1
  // }/${date.getFullYear()}`;
  // console.log(formattedDate, "formattedDate");
  ////

  const DateString = (timestamp) => {
    if (timestamp !== null) {
      // var time = Number(timestamp);
      var time = Number(timestamp);
      var date = new Date(time + 5.5 * 60 * 60 * 1000)
        .toISOString()
        .substring(0, 10);
      return date;
    }
    return "-";
  };

  //snackbar states
  const [snackBarStatus, setSnackBarStatus] = useState(false);
  const [snackBarMessage, setSnackBarMessage] = useState("");
  const [snackBarSeverity, setSnackBarSeverity] = useState("success");

  //role changes related states
  const [roleModalStatus, setRoleModalStatus] = useState(false);
  const [roleSelected, setRoleSelected] = useState(roleOptions[0]);

  const changeUserRole = async () => {
    try {
      let res = await axios.put(`${baseUrl}/changeRoleOfUser`, {
        role: roleSelected,
        userId: userSelected,
      });
      if (res.status === 200) {
        setRoleModalStatus(false);
        setSnackBarMessage(res.data.result);
        setSnackBarSeverity("success");
        setSnackBarStatus(true);
      } else {
        setRoleModalStatus(false);
        setSnackBarMessage(res.data.result);
        setSnackBarSeverity("error");
        setSnackBarStatus(true);
      }
    } catch (error) {
      console.error(error);
      setRoleModalStatus(false);
      setSnackBarMessage("Oops! Something Went Wrong");
      setSnackBarSeverity("error");
      setSnackBarStatus(true);
    }
  };

  const fetchUserAssetAllocation = async (userId) => {
    // debugger;
    try {
      setIsLoading(true);
      setUserSelected(userId);
      let registeredData = (
        await axios.get(`${baseUrl}/getAssetAllocationByUserId/${userId}`)
      ).data;
      console.log("data 123", registeredData);
      let otherData = (
        await axios.get(`${baseUrl}/getOtherAssetAssignedDetails/${userId}`)
      ).data;
      if (registeredData && otherData) {
        registeredData.forEach((element) => {
          element.assetHandOverDate = DateString(element.assetHandOverDate);
          element.assetReturnedDate = DateString(element.assetReturnedDate);
        });
        otherData.forEach((element) => {
          element.handoverDate = DateString(element.handoverDate);
          element.returnedDate = DateString(element.returnedDate);
        });
        dispatch(
          setLookUpEmployeeData({
            registeredAssetsData: registeredData,
            otherAssetsData: otherData,
          })
        );
        setIsLoading(false);
      }
    } catch (error) {
      setIsLoading(false);
      console.error(error);
    }
  };

  const [userSearchData, setUserSearchData] = useState([]);
  const [inputValue, setInputValue] = useState("");
  const [selectedValue, setSelectedValue] = useState(null);

  const [userSelected, setUserSelected] = useState("");

  const debounce = (func) => {
    let timer;
    return function (...args) {
      const context = this;
      if (timer) clearTimeout(timer);
      timer = setTimeout(() => {
        timer = null;
        func.apply(context, args);
      }, 500);
    };
  };

  const [searchText, setSearchText] = useState("");

  const handleChange = async (value) => {
    setSearchText(value);
    if (value !== "" && value !== "inc") {
      try {
        let data = await (
          await axios.get(`${baseUrl}/getDetailsByNameOrEmail?name=${value}`)
        ).data;
        if (data) {
          setUserSearchData(data);
        }
      } catch (error) {}
    }
  };

  //tab state
  const [value, setValue] = useState(0);

  const handleTabChange = (event, newValue) => {
    setValue(newValue);
  };

  const optimizedFn = useCallback(debounce(handleChange), []);

  useEffect(() => {
    dispatch(resetLookUpEmployeeData());
  }, []);

  return (
    <>
      <Snackbar
        anchorOrigin={{ vertical: "top", horizontal: "center" }}
        open={snackBarStatus}
        autoHideDuration={6000}
        onClose={() => setSnackBarStatus(false)}
      >
        <Alert
          onClose={() => setSnackBarStatus(false)}
          severity={snackBarSeverity}
          sx={{ width: "100%" }}
        >
          {snackBarMessage}
        </Alert>
      </Snackbar>
      {!isLoading ? (
        <div className="parent">
          <div className="child1">
            <Box
              sx={{
                display: "flex",
                alignItems: "center",
                justifyContent: "space-evenly",
                // width:"80%",
                p: 2,
                border: "1px solid #ddd",
                borderRadius: 2,
                backgroundColor: "rgb(248,248,248)",
              }}
            >
              {userProfileRegisteredData && (
                <>
                  <Grid
                    container
                    spacing={2}
                    sx={{ width: "50%", marginLeft: "25px" }}
                  >
                    <Grid item xs={12}>
                      <Box
                        spacing={2}
                        sx={{
                          display: "flex",
                          flexDirection: "row",
                          justifyContent: "flex-start",
                          alignItems: "center",
                        }}
                      >
                        <Typography variant="h6">
                          <strong>Name :</strong>
                        </Typography>
                        <Typography variant="body1" sx={{ fontSize: "20px" }}>
                          {userProfileRegisteredData?.[0]?.userName ?? ""}{" "}
                        </Typography>
                      </Box>
                    </Grid>

                    <Grid item xs={12}>
                      <Box
                        spacing={2}
                        sx={{
                          display: "flex",
                          flexDirection: "row",
                          justifyContent: "flex-start",
                        }}
                      >
                        <Typography variant="h6">
                          <strong>Email :</strong>
                        </Typography>
                        <Typography variant="body1" sx={{ fontSize: "20px" }}>
                          {userProfileRegisteredData?.[0]?.email ?? ""}{" "}
                        </Typography>
                      </Box>
                    </Grid>
                    <Grid item xs={12}>
                      <Box
                        spacing={2}
                        sx={{
                          display: "flex",
                          flexDirection: "row",
                          justifyContent: "flex-start",
                        }}
                      >
                        <Typography variant="h6">
                          <strong>Emp Code :</strong>
                        </Typography>
                        <Typography variant="body1" sx={{ fontSize: "20px" }}>
                          {userProfileRegisteredData?.[0]?.employeeId ?? ""}
                        </Typography>
                      </Box>
                    </Grid>
                    <Grid item xs={12}>
                      <Box
                        spacing={2}
                        sx={{
                          display: "flex",
                          flexDirection: "row",
                          justifyContent: "flex-start",
                        }}
                      >
                        <Typography variant="h6">
                          <strong>User Location :</strong>
                        </Typography>
                        <Typography variant="body1" sx={{ fontSize: "20px" }}>
                          {" "}
                          {userProfileRegisteredData?.[0]?.userLocation ?? ""}
                        </Typography>
                      </Box>
                    </Grid>
                  </Grid>

                  <Divider
                    orientation="vertical"
                    flexItem
                    sx={{ margin: "15px" }}
                  />
                </>
              )}

              <Stack>
                <Box
                  style={{
                    padding: "0.8em 0.5em 0em 0em",
                    flex: 1,
                    bgcolor: "#cfe8fc",
                  }}
                >
                  {/* <AutoCompleteSearch
                autoCompleteonChange={(value) => {
                  fetchUserAssetAllocation(value.email);
                }}
                textOnChange={(val) => optimizedFn(val)}
                options={userSearchData}
                getOptionLabel={
                  searchText.toLowerCase().startsWith("inc")
                    ? "employeeId"
                    : "email"
                }
                primaryTextOption={"email"}
                secondaryTextOption={"employeeId"}
                placeholder={"Search for User"}
                width={"16em"}
              /> */}

                  <Autocomplete
                    disableClearable
                    sx={{ height: "70px", width: "240px" }}
                    value={selectedValue}
                    onChange={(event, value) => {
                      setSelectedValue(value);
                      if (value) {
                        fetchUserAssetAllocation(value.employeeId);
                      }
                    }}
                    onInputChange={(event, value) => {
                      // debugger
                      setInputValue(value);
                      optimizedFn(value);
                      if (value === "") {
                        setSelectedValue(null); // Clear the selected value when input is cleared
                        dispatch(resetLookUpEmployeeData());
                      }
                    }}
                    inputValue={inputValue}
                    options={userSearchData}
                    getOptionLabel={(option) =>
                      `${option.employeeId} ${option.firstName} ${option.lastName}`
                    }
                    renderInput={(params) => (
                      <TextField
                        {...params}
                        label="Search for User"
                        variant="outlined"
                        margin="dense"
                      />
                    )}
                  />
                </Box>

                {userProfileRegisteredData && (
                  <>
                    {/* <Typography variant="p" align="center">
                  {userProfileRegisteredData?.[0]?.employeeId ?? ""}
                  <br />
                  {`${userProfileRegisteredData?.[0]?.firstName ?? ""} ${
                    userProfileRegisteredData?.[0]?.lastName ?? ""
                  }`}
                  <br />

                  {userProfileRegisteredData?.[0]?.email ?? ""}
                </Typography> */}
                    {userReducer.role === "superAdmin" && (
                      <Button
                        variant="contained"
                        sx={{
                          marginTop: "0.5em",
                          border: "1px solid #F1F5FE",
                          backgroundColor: "#F1F5FE",
                          color: "#0288d1",
                          fontSize: "12px",
                          width: "97%",
                          //  justifyContent:"center",
                          textTransform: "capitalize",
                          height: "max-content",
                          "&:hover": {
                            backgroundColor: "#F1F5FE",
                            color: "#0288d1",
                            border: "1px solid #0288d1",
                            boxShadow: "1px #000000",
                          },
                        }}
                        onClick={() => setRoleModalStatus(true)}
                      >
                        Change Role
                      </Button>
                    )}
                  </>
                )}

                {/* {userProfileRegisteredData && (
            <Box
                style={{
                  flex: 10,
                  display: "flex",
                  flexDirection: "column",
                  alignItems: "center",
                  // alignItems: "flex-start",
                  justifyContent: "center",
                }}
              >
                <Avatar
                  src={avatarPhoto}
                  sx={{
                    height: "83.3px",
                    width: "83.3px",
                    marginBottom: "0.5em",
                  }}
                />
                </Box>
  )} */}
              </Stack>
              {/* <Divider orientation="vertical" flexItem sx={{margin:"15px"}} /> */}

              {userProfileRegisteredData && (
                <Box
                  style={{
                    flex: 10,
                    display: "flex",
                    flexDirection: "column",
                    alignItems: "center",
                    // alignItems: "flex-start",
                    justifyContent: "center",
                  }}
                >
                  <Avatar
                    src={avatarPhoto}
                    sx={{
                      height: "150.3px",
                      width: "150.3px",
                      marginBottom: "0.5em",
                    }}
                  />
                </Box>
              )}

              {/* {userProfileRegisteredData && (
             <>
               
                {userReducer.role === "superAdmin" && (
                  <Button
                    variant="contained"
                    sx={{
                      marginTop: "0.5em",
                      border: "1px solid #F1F5FE",
                      backgroundColor: "#F1F5FE",
                      color: "#0288d1",
                      fontSize: "12px",
                    //  justifyContent:"center",
                      textTransform: "capitalize",
                      height: "max-content",
                      "&:hover": {
                        backgroundColor: "#F1F5FE",
                        color: "#0288d1",
                        border: "1px solid #0288d1",
                        boxShadow: "1px #000000",
                      },
                    }}
                    onClick={() => setRoleModalStatus(true)}
                  >
                    Change Role
                  </Button>
                )}
              </>
            )} */}
            </Box>
          </div>
          {userProfileRegisteredData && userProfileOtherAssetData ? (
            <div className="child2">
              <Tabs
                value={value}
                onChange={handleTabChange}
                sx={{
                  // padding: "20px 0px 0px 20px",
                  "& button": {
                    fontWeight: "200",
                    fontSize: "16px",
                    borderRadius: "4px 4px 0 0",
                    border: "solid 1px #ddd",
                    padding: "8px 14px",
                    textTransform: "none",
                  },
                  "& button.Mui-selected": {
                    fontWeight: "bold",
                    fontSize: "16px",
                    backgroundColor: "#f1f5fe",
                    color: "#0288d1",
                    boxShadow: "0",
                    padding: "0",
                    padding: "8px 14px",
                    boxShadow: "rgb(0 0 0 / 25%) 0px 4px 4px 2px",
                  },
                }}
                style={{ width: "100%", minHeight: "30px" }}
                TabIndicatorProps={{ hidden: true }}
              >
                <Tab label="Registered Assets" />
                <Tab label="Other Assets" />
              </Tabs>
              <InternalTabPanel value={value} index={0}>
                <TableUtility
                  Data={userProfileRegisteredData}
                  headerkeys={[
                    "",
                    "Tag No",
                    "Asset Type",
                    "Make",
                    "Model",
                    "Assign Date",
                    "Assigned By",
                    "Assigned Location",
                    "Return Date",
                    "Returned To",
                    "Return Location",
                  ]}
                  bodykeys={[
                    "",
                    "assetId",
                    "assetType",
                    "brand",
                    "model",
                    "assetStDate",
                    "assignedBy",
                    "baseLocation",
                    "assetReturnedDate",
                    "returnedBy",
                    "returnLocation",
                  ]}
                  enableDelete={false}
                  enableEdit={false}
                  height={"65vh"}
                />
              </InternalTabPanel>
              <InternalTabPanel value={value} index={1}>
                <TableUtility
                  Data={userProfileOtherAssetData}
                  headerkeys={[
                    "",
                    "HandOver Date",
                    "Returned Date",
                    "Asset Name",
                    "Asset Brand",
                    "Asset Type",
                  ]}
                  bodykeys={[
                    "",
                    "handoverDate",
                    "returnedDate",
                    "assetName",
                    "assetBrand",
                    "assetType",
                  ]}
                  enableDelete={false}
                  enableEdit={false}
                  height={"65vh"}
                />
              </InternalTabPanel>
            </div>
          ) : (
            <Box
              sx={{
                display: "flex",
                flexDirection: "column",
                justifyContent: "center",
                alignItems: "center",
                width: "100%",
              }}
            >
              <Paper elevation={0} sx={{ borderRadius: "6px" }}>
                <Box
                  sx={{
                    padding: "4em",
                    backgroundColor: "#F8F8F8",
                    borderRadius: "6px",
                    display: "flex",
                    flexDirection: "column",
                    justifyContent: "center",
                    alignItems: "center",
                  }}
                >
                  <PersonSearchIcon
                    style={{ color: "grey" }}
                    fontSize="large"
                  />
                  <Typography>SEARCH FOR A USER</Typography>
                </Box>
              </Paper>
            </Box>
          )}
        </div>
      ) : (
        <Box
          flexGrow={1}
          display="flex"
          flexDirection="column"
          alignItems="center"
          justifyContent="center"
          sx={{ height: "62vh" }}
        >
          <CircularProgress />
          <Typography color="#0288d1">Loading...</Typography>
        </Box>
      )}
      <Dialog open={roleModalStatus} sx={{ padding: "20px" }}>
        <DialogContent sx={{ padding: "10px" }} className="wbScroll">
          <Stack>
            <Grid container spacing={8} rowSpacing={3}>
              <Grid item xs={6}>
                <Typography sx={{ color: "#424242" }}>Select Role</Typography>
                <TextField
                  select
                  InputProps={inputSize}
                  margin="normal"
                  id="outlined-basic"
                  variant="outlined"
                  name="role"
                  value={roleSelected}
                  onChange={(e) => setRoleSelected(e.target.value)}
                >
                  {roleOptions?.map((item) => (
                    <MenuItem key={item} value={item}>
                      {item}
                    </MenuItem>
                  ))}
                </TextField>
              </Grid>
            </Grid>
          </Stack>
        </DialogContent>
        <DialogActions>
          <Button
            type="submit"
            onClick={() => changeUserRole()}
            variant="contained"
            sx={{
              backgroundColor: "#0288d1",
              textTransform: "none",
              "&:hover": {
                backgroundColor: "#140B96",
                boxShadow: "1px #000000",
              },
            }}
            disableRipple
          >
            Save
          </Button>
          <Button
            onClick={() => setRoleModalStatus(false)}
            variant="contained"
            sx={{
              backgroundColor: "#E0E0E0",
              textTransform: "none",
              color: "black",
              "&:hover": {
                backgroundColor: "#E0E0E0",
                boxShadow: "1px #000000",
              },
            }}
            disableRipple
          >
            Cancel
          </Button>
        </DialogActions>
      </Dialog>
    </>
  );
};

function InternalTabPanel(props) {
  const { children, value, index } = props;
  return <>{value === index && <>{children}</>}</>;
}

const LookUpbyLaptopTag = () => {
  const dispatch = useDispatch();
  const [laptopSearchData, setLaptopSearchData] = useState([]);
  const appReducer = useSelector(
    (state) => state.appReducer.lookUpData.registeredAssetData
  );
  const laptopSelected = appReducer.data;
  const [isLoading, setIsLoading] = useState(false);
  const [inputValue, setInputValue] = useState("");
  const [selectedValue, setSelectedValue] = useState(null);

  const DateString = (timestamp) => {
    // var time = Number(timestamp);
    // var date = new Date(time).toISOString().substring(0, 10);
    // return date;
    if (timestamp !== null) {
      // var time = Number(timestamp);
      var time = Number(timestamp);
      var date = new Date(time + 5.5 * 60 * 60 * 1000)
        .toISOString()
        .substring(0, 10);
      return date;
    }
    return "-";
  };

  const debounce = (func) => {
    let timer;
    return function (...args) {
      const context = this;
      if (timer) clearTimeout(timer);
      timer = setTimeout(() => {
        timer = null;
        func.apply(context, args);
      }, 500);
    };
  };

  const handleChange = async (value) => {
    try {
      let data = await (
        await axios.get(
          `${baseUrl}/getAssetAllocationDistinctAssetId?assetId=${value}`
        )
      ).data;
      if (data) {
        setLaptopSearchData(data);
      }
    } catch (error) {
      console.error(error);
    }
  };

  const fetchUserAssetAllocation = async (assetId) => {
    try {
      setIsLoading(true);
      let data = await (
        await axios.get(
          `${baseUrl}/getAssetAllocationByAssetId?assetId=${assetId}`
        )
      ).data;
      if (data) {
        data.forEach((element) => {
          element.assetHandOverDate = DateString(element.assetHandOverDate);
          element.assetReturnedDate = DateString(element.assetReturnedDate);
        });
        dispatch(setLookUpRegisteredAssetData(data));
        setIsLoading(false);
      }
    } catch (error) {
      setIsLoading(false);
      console.error(error);
    }
  };

  const optimizedFn = useCallback(debounce(handleChange), []);

  useEffect(() => {
    dispatch(resetLookUpRegisteredAssetData());
  }, []);

  return (
    <>
      <Box style={{ padding: "0.8em 0.5em 0.8em 1%" }}>
        {/* <AutoCompleteSearch
          autoCompleteonChange={(value) => {
            fetchUserAssetAllocation(value);
          }}
          textOnChange={(val) => optimizedFn(val)}
          options={laptopSearchData}
          placeholder={"Search for Asset"}
          width={"16em"}
        /> */}

        <Autocomplete
          disableClearable
          sx={{ height: "70px", width: "240px" }}
          // value={selectedValue}
          onChange={(event, value) => {
            setSelectedValue(value);
            if (value) {
              fetchUserAssetAllocation(value);
            }
          }}
          onInputChange={(event, value) => {
            setInputValue(value);
            optimizedFn(value);
            if (value === "") {
              setSelectedValue(null); // Clear the selected value when input is cleared
              dispatch(resetLookUpRegisteredAssetData());
            }
          }}
          inputValue={inputValue}
          options={laptopSearchData}
          // getOptionLabel={(option) =>
          //   `${option.employeeId} ${option.firstName} ${option.lastName}`
          // }
          renderInput={(params) => (
            <TextField
              {...params}
              label="Search for Asset"
              variant="outlined"
              margin="dense"
            />
          )}
        />
      </Box>
      {!isLoading ? (
        laptopSelected ? (
          <div style={{ marginLeft: "1%", marginRight: "1%" }}>
            <TableUtility
              Data={laptopSelected}
              headerkeys={[
                "",
                "Asset ID",
                "Employee ID",
                "Email",
                "Make",
                "Model",
                "Assign   Date",
                "End Date",
                "Return Date",
              ]}
              bodykeys={[
                "",
                "assetId",
                "employeeId",
                "email",
                "brand",
                "model",
                "assetStDate",
                "assetEndDate",
                "assetReturnedDate",
              ]}
              enableDelete={false}
              enableEdit={false}
              height={"62vh"}
            />
          </div>
        ) : (
          <Box
            sx={{
              display: "flex",
              flexDirection: "column",
              height: "62vh",
              justifyContent: "center",
              alignItems: "center",
            }}
          >
            <Paper elevation={0} sx={{ borderRadius: "6px" }}>
              <Box
                sx={{
                  padding: "4em",
                  backgroundColor: "#F8F8F8",
                  borderRadius: "6px",
                  display: "flex",
                  flexDirection: "column",
                  justifyContent: "center",
                  alignItems: "center",
                }}
              >
                <ManageSearchIcon style={{ color: "grey" }} fontSize="large" />
                <Typography>SEARCH FOR AN ASSET</Typography>
              </Box>
            </Paper>
          </Box>
        )
      ) : (
        <Box
          flexGrow={1}
          display="flex"
          flexDirection="column"
          alignItems="center"
          justifyContent="center"
          sx={{ height: "62vh" }}
        >
          <CircularProgress />
          <Typography color="#0288d1">Loading...</Typography>
        </Box>
      )}
    </>
  );
};

import { SearchOutlined } from "@mui/icons-material";
import {
  Alert,
  Autocomplete,
  Backdrop,
  Box,
  Button,
  CircularProgress,
  createTheme,
  Divider,
  FormControl,
  Grid,
  InputLabel,
  ListItem,
  ListItemText,
  MenuItem,
  Snackbar,
  TextField,
  ThemeProvider,
  Typography,
} from "@mui/material";
import { DatePicker, LocalizationProvider } from "@mui/x-date-pickers";
import { AdapterDateFns } from "@mui/x-date-pickers/AdapterDateFns";
import axios from "axios";
import React, { useCallback, useState } from "react";
import { baseUrl } from "../../Utils/serviceRequest";
import AutoCompleteSearch from "../SearchComponent/AutoCompleteSearch";
import AssetAssignForm from "./AssetAssignForm";

const date = new Date(Date.now()).toISOString().substr(0, 10);

const otherAssetsOptions = {
  name: ["Mouse", "Headphone", "Monitor", "Projector", "Desktop"],
  brand: {
    Mouse: ["Lenovo"],
    Headphone: ["Sony", "Boat"],
    Monitor: ["Dell"],
    Projector: ["Epson", "Benq"],
    Desktop: ["Lenovo"],
  },
  type: {
    Mouse: ["Wired", "Wireless"],
    Headphone: ["Wired", "Wireless"],
    Monitor: ["Wired"],
    Projector: ["Wired"],
    Desktop: ["Wired"],
  },
};

const dateInputSize = {
  style: {
    height: "4vh",
    maxHeight: "32px",
    padding: "2px 10px",
    paddingLeft: "0px",
    width: "17em",
  },
  disabled: true,
};

let dateTheme = createTheme({
  components: {
    MuiInputBase: {
      styleOverrides: {
        root: {
          height: "4vh",
          maxHeight: "32px",
        },
      },
    },
    MuiOutlinedInput: {
      styleOverrides: {
        input: {
          height: "4vh",
          maxHeight: "32px",
          padding: "2px 10px",
        },
      },
    },
  },
});

const inputSize = {
  style: {
    height: "4vh",
    maxHeight: "32px",
    width: "17em",
    padding: "2px 10px",
  },
};

const AssignOtherAssetForm = () => {
  const [assignFormData, setAssignFormData] = useState({
    userId: "",
    assetName: otherAssetsOptions.name[0],
    assetBrand: otherAssetsOptions.brand["Mouse"][0],
    assetType: otherAssetsOptions.type["Mouse"][0],
    handoverDate: date,
  });

  const [userSearchData, setUserSearchData] = useState([]);

  const [userSelected, setUserSelected] = useState({});

  //loading state
  const [isLoading, setIsLoading] = useState(false);

  const [snackBarStatus, setSnackBarStatus] = useState(false);
  const [snackBarMessage, setSnackBarMessage] = useState("");
  const [snackBarSeverity, setSnackBarSeverity] = useState("success");

  const debounce = (func) => {
    let timer;
    return function (...args) {
      const context = this;
      if (timer) clearTimeout(timer);
      timer = setTimeout(() => {
        timer = null;
        func.apply(context, args);
      }, 500);
    };
  };

  const handleChange = async (value) => {
    try {
      let data = await (
        await axios.get(`${baseUrl}/getDetailsByNameOrEmail?name=${value}`)
      ).data;
      if (data) {
        setUserSearchData(data);
      }
    } catch (error) {}
  };

  const assignAsset = async () => {
    try {
      setIsLoading(true);
      let res = await axios.post(`${baseUrl}/assignOtherAsset`, assignFormData);
      if (res.status === 200) {
        setUserSelected({});
        setIsLoading(false);
        if (res.data.result === "out of stock") {
          setSnackBarSeverity("error");
          setSnackBarMessage(res.data.result);
        } else {
          setSnackBarSeverity("success");
          setSnackBarMessage("Assigned Successfully");
        }
        setSnackBarStatus(true);
      }
    } catch (error) {
      console.error(error);
      setIsLoading(false);
      setSnackBarMessage("Oops!! Something Went Wrong");
      setSnackBarSeverity("error");
      setSnackBarStatus(true);
    }
  };

  const optimizedFn = useCallback(debounce(handleChange), []);

  const handleOnChange = (e) => {
    let name = e.target.name;
    let value = e.target.value;
    let tempForm = { ...assignFormData };
    tempForm[name] = value;
    if (name === "assetName") {
      tempForm["assetBrand"] = otherAssetsOptions.brand[value][0];
      tempForm["assetType"] = otherAssetsOptions.type[value][0];
    }
    setAssignFormData(tempForm);
  };
  return (
    <Box>
      <Box style={{ padding: "0.8em 2em" }}>
        <Backdrop
          sx={{ color: "#fff", zIndex: (theme) => theme.zIndex.drawer + 1 }}
          open={isLoading}
        >
          <CircularProgress color="inherit" />
        </Backdrop>
         <AutoCompleteSearch
              autoCompleteonChange={(value) => {
                setUserSelected(value);
                setAssignFormData((prev) => {
                  return { ...prev, userId: value.userId };
                });
              }}
              textOnChange={(val) => optimizedFn(val)}
              options={userSearchData}
              getOptionLabel={"userId"}
              firstNameOption={"firstName"}
              lastNameOption={"lastName"}
              secondaryTextOption={"userId"}
              

              placeholder={"Search for User"}
            />

        {/* //idhar */}
        <ThemeProvider theme={dateTheme}>
          <Grid mt={2} container spacing={8} rowSpacing={3}>
            <Grid item sm={4} xs={12}>
              <InputLabel
                sx={{
                  color: "#424242",
                  fontSize: "16px",
                  fontWeight: "400",
                  lineHeight: "24px",
                  letterSpacing: "0.005em",
                  marginBottom: "1%",
                  maxHeight: "32px",
                }}
              >
                Name
              </InputLabel>
              <FormControl sx={{ width: "100%" }}>
                <TextField
                  id="outlined-basic"
                  variant="outlined"
                  disabled
                  InputProps={inputSize}
                  value={`${userSelected?.firstName ?? ""} ${
                    userSelected?.lastName ?? ""
                  }`}
                />
              </FormControl>
            </Grid>
            <Grid item sm={4} xs={12}>
              <InputLabel
                sx={{
                  color: "#424242",
                  fontSize: "16px",
                  fontWeight: "400",
                  lineHeight: "24px",
                  letterSpacing: "0.005em",
                  marginBottom: "1%",
                  maxHeight: "32px",
                }}
              >
                Employee ID
              </InputLabel>
              <FormControl sx={{ width: "100%" }}>
                <TextField
                  id="outlined-basic"
                  variant="outlined"
                  disabled
                  InputProps={inputSize}
                  value={`${userSelected?.employeeId ?? ""}`}
                />
              </FormControl>
            </Grid>
            
            <Grid item sm={4} xs={12}>
              <InputLabel
                sx={{
                  color: "#424242",
                  fontSize: "16px",
                  fontWeight: "400",
                  lineHeight: "24px",
                  letterSpacing: "0.005em",
                  marginBottom: "1%",
                  maxHeight: "32px",
                }}
              >
                Email ID
              </InputLabel>
              <FormControl sx={{ width: "100%" }}>
                <TextField
                  id="outlined-basic"
                  variant="outlined"
                  disabled
                  InputProps={inputSize}
                  value={`${userSelected?.userId ?? ""}`}
                />
              </FormControl>
            </Grid>
            <Grid item sm={4} xs={12}>
              <InputLabel
                sx={{
                  color: "#424242",
                  fontSize: "16px",
                  fontWeight: "400",
                  lineHeight: "24px",
                  letterSpacing: "0.005em",
                  marginBottom: "1%",
                  maxHeight: "32px",
                }}
              >
                Select Date
              </InputLabel>
              <LocalizationProvider dateAdapter={AdapterDateFns}>
                <DatePicker
                  InputProps={dateInputSize}
                  value={assignFormData.handoverDate}
                  onChange={(newValue) => {
                    setAssignFormData((prev) => {
                      return {
                        ...prev,
                        handoverDate: `${
                          Date.parse(newValue)
                            ? newValue.toISOString().substr(0, 10)
                            : { date }
                        }`,
                      };
                    });
                  }}
                  renderInput={(params) => (
                    <FormControl sx={{ width: "100%" }}>
                      <TextField {...params} />
                    </FormControl>
                  )}
                />
              </LocalizationProvider>
            </Grid>
            <Grid item sm={4} xs={12}>
              <Typography sx={{ color: "#424242" }}>Asset Name</Typography>
              <TextField
                select
                SelectProps={inputSize}
                margin="normal"
                id="outlined-basic"
                variant="outlined"
                name="assetName"
                value={assignFormData.assetName}
                defaultValue={assignFormData.assetName}
                onChange={handleOnChange}
              >
                {otherAssetsOptions.name.map((assetName) => (
                  <MenuItem value={assetName}>{assetName}</MenuItem>
                ))}
              </TextField>
            </Grid>
            <Grid item sm={4} xs={12}>
              <Typography sx={{ color: "#424242" }}>Brand</Typography>
              <TextField
                select
                SelectProps={inputSize}
                margin="normal"
                id="outlined-basic"
                variant="outlined"
                name="assetBrand"
                value={assignFormData.assetBrand}
                defaultValue={assignFormData.assetBrand}
                onChange={handleOnChange}
              >
                {otherAssetsOptions.brand[assignFormData.assetName].map(
                  (brand) => (
                    <MenuItem value={brand}>{brand}</MenuItem>
                  )
                )}
              </TextField>
            </Grid>

            <Grid item sm={4} xs={12}>
              <Typography sx={{ color: "#424242" }}>Asset Type</Typography>
              <TextField
                select
                SelectProps={inputSize}
                margin="normal"
                id="outlined-basic"
                variant="outlined"
                name="assetType"
                value={assignFormData.assetType}
                defaultValue={assignFormData.assetType}
                onChange={handleOnChange}
              >
                {otherAssetsOptions.type[assignFormData.assetName].map(
                  (type) => (
                    <MenuItem value={type}>{type}</MenuItem>
                  )
                )}
              </TextField>
            </Grid>
          </Grid>
        </ThemeProvider>
        <Box
          style={{
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            marginTop: "5%",
          }}
        >
          <Button
            variant="contained"
            color="info"
            onClick={() => assignAsset()}
          >
            Submit
          </Button>
        </Box>
      </Box>
      <Snackbar
        anchorOrigin={{ vertical: "top", horizontal: "center" }}
        open={snackBarStatus}
        autoHideDuration={6000}
        onClose={() => setSnackBarStatus(false)}
      >
        <Alert
          onClose={() => setSnackBarStatus(false)}
          severity={snackBarSeverity}
          sx={{ width: "100%" }}
        >
          {snackBarMessage}
        </Alert>
      </Snackbar>
    </Box>
  );
};

export default AssignOtherAssetForm;

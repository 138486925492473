import React, { useCallback, useState } from "react";
import {
  Box,
  Button,
  createTheme,
  Divider,
  InputLabel,
  TextField,
  Typography,
  FormControl,
  Autocomplete,
  ListItem,
  ListItemText,
  Grid,
  Backdrop,
  Select,
  CircularProgress,
  Snackbar,
  Alert,
  MenuItem,
} from "@mui/material";
import { LocalizationProvider } from "@mui/x-date-pickers";
import { AdapterDateFns } from "@mui/x-date-pickers/AdapterDateFns";
import { DatePicker } from "@mui/x-date-pickers/DatePicker";
import axios from "axios";
import { baseUrl } from "../../Utils/serviceRequest";
import { SearchOutlined } from "@mui/icons-material";
import { ThemeProvider } from "@emotion/react";
import "./AssetAssignForm.css";
import { useDispatch } from "react-redux";
import { useSelector } from "react-redux/es/exports";
import {
  resetAssignForm,
  resetAssignFormConfiguration,
  resetAssignRegisterFormEmployee,
  setassignRegisterFormConfig,
  setassignRegisterFormEmployee,
  setassignRequestForm,
} from "../../redux/reducers/formReducers";
import AutoCompleteSearch from "../SearchComponent/AutoCompleteSearch";
import { useEffect } from "react";

const inputSize = {
  style: {
    height: "4vh",
    maxHeight: "32px",
    // width: "13.55em",
    padding: "2px 10px",
  },
  MenuProps: {
    PaperProps: {
      style: {
        maxHeight: 300, // Set your preferred max height
        overflowY: "auto", // Enable vertical scrolling
      },
    },
  },
};
const dateInputSize = {
  style: {
    height: "4vh",
    maxHeight: "32px",
    padding: "2px 10px",
    paddingLeft: "0px",
  },
  disabled: true,
};

let dateTheme = createTheme({
  components: {
    MuiInputBase: {
      styleOverrides: {
        root: {
          height: "4vh",
          maxHeight: "32px",
        },
      },
    },
    MuiOutlinedInput: {
      styleOverrides: {
        input: {
          height: "4vh",
          maxHeight: "32px",
          padding: "2px 10px",
        },
      },
    },
    MuiAutocomplete: {
      styleOverrides: {
        root: {
          height: "2vh !important",
          maxHeight: "32px",
        },
      },
    },
  },
});

let autoCompleteTheme = createTheme({
  component: {},
});

const date = new Date(Date.now()).toISOString().substr(0, 10);
const AssetAssignForm = () => {
  const dispatch = useDispatch();

  //loading state
  const [isLoading, setIsLoading] = useState(false);

  //snackbar states
  const [snackBarStatus, setSnackBarStatus] = useState(false);
  const [snackBarMessage, setSnackBarMessage] = useState("");
  const [snackBarSeverity, setSnackBarSeverity] = useState("success");
  const [baseLocation, setBaseLocation] = useState("");

  const assignFormData = useSelector(
    (state) => state.formReducer.assignRegisterForm.requestForm
  );

  // console.warn(assignFormData,"data assign form")

  const currentUser = useSelector((state) => state.userReducer.userData);
  // console.log(currentUser,"12")
  // const assignedBY = `${currentUser?.employeeId ?? ""}-${currentUser?.name ?? ""}`
  // console.log("12 assigned by: ",assignedBY);

  let userSelected = useSelector(
    (state) => state.formReducer.assignRegisterForm.employeeDetails
  );
  console.log("1 userselected", userSelected);

  const _assignAsset = async () => {
    if (assignFormData.userId === "" || assignFormData.assetId === "") {
      setSnackBarMessage("Please Select Asset And User");
      setSnackBarSeverity("error");
      setSnackBarStatus(true);
      return;
    }
    try {
      setIsLoading(true);
      let res = await axios.post(`${baseUrl}/createAssetAllocaiton`, {
        ...assignFormData,
        // assignedBy: assignedBY,
        assignedBy: currentUser?.employeeId ?? "",
        currentLocation: userSelected?.userLocation ?? "",
        baseLocation: baseLocation,
        userId: userSelected?.employeeId ?? "",
      });
      if (res.status === 200) {
        dispatch(resetAssignForm());
        setIsLoading(false);
        setSnackBarMessage("Asset Assigned");
        setSnackBarSeverity("success");
        setSnackBarStatus(true);
        setBaseLocation("");
      } else if (res.status !== 200) {
        dispatch(resetAssignForm());
        setIsLoading(false);
        setSnackBarMessage("Oops! Something Went Wrong");
        setSnackBarSeverity("error");
        setSnackBarStatus(true);
      }
    } catch (error) {
      setIsLoading(false);
      setSnackBarMessage("Oops! Something Went Wrong");
      setSnackBarSeverity("error");
      console.error(error);
      setSnackBarStatus(true);
    }
  };

  useEffect(() => {
    dispatch(resetAssignForm());
  }, []);

  return (
    <>
      <Box
        sx={{
          height: "100%",
          display: "flex",
          flexDirection: "column",
          justifyContent: "space-between",
          alignItems: "center",
        }}
      >
        <Box sx={{ marginBottom: "1em" }}>
          <RenderConfigForm
            baseLocation={baseLocation}
            setBaseLocation={setBaseLocation}
          />
          <RenderUserForm assignFormData={assignFormData} />
        </Box>
        <Button variant="contained" color="info" onClick={() => _assignAsset()}>
          Submit
        </Button>
        <Backdrop
          sx={{ color: "#fff", zIndex: (theme) => theme.zIndex.drawer + 1 }}
          open={isLoading}
        >
          <CircularProgress color="inherit" />
        </Backdrop>
        <Snackbar
          anchorOrigin={{ vertical: "top", horizontal: "center" }}
          open={snackBarStatus}
          autoHideDuration={6000}
          onClose={() => setSnackBarStatus(false)}
        >
          <Alert
            onClose={() => setSnackBarStatus(false)}
            severity={snackBarSeverity}
            sx={{ width: "100%" }}
          >
            {snackBarMessage}
          </Alert>
        </Snackbar>
      </Box>
    </>
  );
};

export default AssetAssignForm;

const RenderUserForm = ({ assignFormData }) => {
  const dispatch = useDispatch();

  const userSelected = useSelector(
    (state) => state.formReducer.assignRegisterForm.employeeDetails
  );

  const currentUser = useSelector((state) => state.userReducer.userData);
  // console.log("current user",currentUser)

  const [userSearchData, setUserSearchData] = useState([]);
  const [inputValue, setInputValue] = useState("");
  const [selectedValue, setSelectedValue] = useState(null);

  const debounce = (func) => {
    let timer;
    return function (...args) {
      const context = this;
      if (timer) clearTimeout(timer);
      timer = setTimeout(() => {
        timer = null;
        func.apply(context, args);
      }, 500);
    };
  };

  const handleChange = async (value) => {
    // debugger
    try {
      if (value === "") {
        // dispatch(setassignRegisterFormEmployee())
        dispatch(resetAssignRegisterFormEmployee());
        // setUserSearchData()
      }
      let data = await (
        await axios.get(`${baseUrl}/getDetailsByNameOrEmail?name=${value}`)
      ).data;
      if (data) {
        setUserSearchData(data);
      }
    } catch (error) {}
  };
  // console.log(userSearchData, "1234");

  const optimizedFn = useCallback(debounce(handleChange), []);

  return (
    <Box>
      <Box style={{ padding: "0.8em 2em" }}>
        <Box
          flexGrow={1}
          display={"flex"}
          alignItems="end"
          justifyContent="space-between"
        >
          <Typography
            variant="h4"
            fontSize={20}
            style={{
              color: "#0288d1",
              fontWeight: "500",
              padding: "0.2em 1em 0.2em 0em",
              textAlign: "bottom",
            }}
          >
            Employee Details
          </Typography>
          <Box sx={{ paddingBottom: "4px" }}>
            {/* <AutoCompleteSearch
              autoCompleteonChange={(val) =>
                dispatch(setassignRegisterFormEmployee(val))
              }
              textOnChange={(val) => optimizedFn(val)}
              options={userSearchData}
              getOptionLabel={"employeeId"}
              // getOptionLabel={(option) => `${option.employeeId}  ${option.firstName} ${option.lastName}`}
              firstNameOption={"firstName"}
              lastNameOption={"lastName"}
              secondaryTextOption={"employeeId"}
              placeholder={"Search for User"}
            /> */}

            {/* <Autocomplete
                  disableClearable
                  sx={{ height: '70px', width: '240px' }}
                  options={regionList}
                  getOptionLabel={(option) => option.regionName}
                  onChange={(event, value) => setCode(value.regionId)}
                  renderInput={(params) => (
                    <TextField
                      {...params}
                      label='Account Region'
                      variant='outlined'
                      margin='dense'
                    />
                  )}
                /> */}

            <Autocomplete
              // disableClearable
              sx={{ height: "70px", width: "240px" }}
              value={selectedValue}
              onChange={(event, value) => {
                setSelectedValue(value);
                dispatch(setassignRegisterFormEmployee(value))
              }}
              onInputChange={(event, value) => {
                setInputValue(value);
                optimizedFn(value);
                if (value === '') {
                  setSelectedValue(null); // Clear the selected value when input is cleared
                }
              }}
              inputValue={inputValue}
              // onChange={(event, value) =>
              //   dispatch(setassignRegisterFormEmployee(value))
              // }
              // onInputChange={(event, value) => optimizedFn(value)}
              options={userSearchData}
              getOptionLabel={(option) =>
                `${option.employeeId} ${option.firstName} ${option.lastName}`
              }
              renderInput={(params) => (
                <TextField
                  {...params}
                  label="Search for User"
                  variant="outlined"
                  margin="dense"
                />
              )}
            />
          </Box>
        </Box>
        <Divider />
      </Box>
      <ThemeProvider theme={dateTheme}>
        <Box sx={{ flexGrow: 1 }}>
          <Grid
            container
            alignItems="center"
            rowSpacing={2}
            columnSpacing={12}
            sx={{ p: "0em 2em" }}
          >
            <GridFormTextInput
              sm={3}
              xs={12}
              value={userSelected?.email ?? ""}
              inputProps={inputSize}
              label={"Email ID"}
            />
            <GridFormTextInput
              sm={3}
              xs={12}
              value={
                userSelected?.firstName !== undefined
                  ? `${userSelected?.firstName ?? ""} ${
                      userSelected?.lastName ?? ""
                    }`
                  : userSearchData?.firstName ?? ""
              }
              inputProps={inputSize}
              label={"Name"}
            />
            <GridFormTextInput
              sm={3}
              xs={12}
              value={userSelected?.employeeId ?? ""}
              inputProps={inputSize}
              label={"Employee ID"}
            />
            {/* <GridFromDateInput
              sm={3}
              xs={12}
              label="Start Date"
              value={assignFormData.assetStDate}
              onChange={(newValue) => {
                dispatch(
                  setassignRequestForm({
                    assetStDate: `${
                      Date.parse(newValue)
                        ? newValue.toISOString().substr(0, 10)
                        : { date }
                    }`,
                  })
                );
              }}
            /> */}
            {/* <GridFromDateInput
              sm={3}
              xs={12}
              label="End Date"
              value={assignFormData.assetEndDate}
              onChange={(newValue) => {
                dispatch(
                  setassignRequestForm({
                    assetEndDate: `${
                      Date.parse(newValue)
                        ? newValue.toISOString().substr(0, 10)
                        : { date }
                    }`,
                  })
                );
              }}
            /> */}
            <GridFromDateInput
              sm={3}
              xs={12}
              label="Hand Over Date"
              value={assignFormData.assetHandOverDate}
              onChange={(newValue) => {
                console.log("date date", newValue);
                const timestamp = new Date(newValue).getTime(); // Get Unix timestamp
                const date = new Date(timestamp); // Create Date object from timestamp
                const formattedDate = `${date.getFullYear()}-${
                  date.getMonth() + 1
                }-${date.getDate()}`;
                console.log("handover date", formattedDate);
                dispatch(
                  setassignRequestForm({
                    assetHandOverDate: timestamp ? timestamp : null, // Use timestamp directly

                    // assetHandOverDate: `${
                    //   Date.parse(newValue)
                    //     ? newValue.toISOString().substr(0, 10)
                    //     : { date }
                    // }`,
                  })
                );
              }}
            />
            <GridFormTextInput
              sm={3}
              xs={12}
              value={currentUser?.employeeId ?? ""}
              inputProps={inputSize}
              label={"Assigned By"}
            />

            {/* new changes for location */}
            {/* <GridFormTextInput
              sm={3}
              xs={12}
              disabled={true}
              value={assignFormData?.location ?? ""}
              inputProps={inputSize}
              label={"Location"}
            ></GridFormTextInput> */}
            {/* 
            <GridDropDownItem
              sm={3}
              xs={12}
              label={"Current Location"}
              disabled={true}
              inputProps={inputSize}
              //onChang={handleOnChange}
              // name={"location"}
              defaultValue={userSelected?.userLocation ?? ""}
              menuItems={{
                Bhubaneswar: "Bhubaneswar",
                Bangalore: "Bangalore",
              }}
            /> */}

            {/* new changes */}
            <GridFormTextInput
              sm={3}
              xs={12}
              value={userSelected?.userLocation ?? ""}
              inputProps={inputSize}
              label={"Current Location"}
            />

            {/* radio button */}
            {/* <Grid item sm={3} xs={12}>
              <InputLabel
                sx={{
                  color: "#424242",
                  fontSize: "16px",
                  fontWeight: "400",
                  lineHeight: "24px",
                  letterSpacing: "0.005em",
                }}
              >
                Asset active
              </InputLabel>

              <TextField
                // select
                disabled={true}
                sx={{ width: "max-content", marginTop: "0px" }}
                SelectProps={inputSize}
                margin="normal"
                id="outlined-basic"
                variant="outlined"
                value={userSelected?.isActive ?? ""}
              ></TextField>
            </Grid> */}

            {/* <Grid item sm={3} xs={12}>
              <InputLabel
                sx={{
                  color: "#424242",
                  fontSize: "16px",
                  fontWeight: "400",
                  lineHeight: "24px",
                  letterSpacing: "0.005em",
                }}
              >
                Current Location
              </InputLabel>

              <TextField
                // select
                disabled={true}
                sx={{ width: "max-content", marginTop: "0px" }}
                SelectProps={inputSize}
                margin="normal"
                id="outlined-basic"
                variant="outlined"
                value={userSelected?.userLocation ?? ""}
              >
            
              </TextField>

             
            </Grid> */}

            {/* end */}
          </Grid>
        </Box>
      </ThemeProvider>
    </Box>
  );
};

const RenderConfigForm = ({ baseLocation, setBaseLocation }) => {
  const dispatch = useDispatch();

  const userSelected = useSelector(
    (state) => state.formReducer.assignRegisterForm.employeeDetails
  );

  console.log("2 user selected", userSelected);

  // const [baseLocation, setBaseLocation] = useState("");

  const [location, setLocation] = useState([]);

  ////location api///

  useEffect(() => {
    const getLocation = async () => {
      try {
        let data = await await axios.get(
          "https://topas-backend.cherrywork.com/asset-api/getTopasLocations"
        );
        console.log(data, "data");
        console.log(data.data.data, "data1");
        if (data) {
          setLocation(data.data.data);
        }
      } catch (error) {}
    };

    console.log(location, "location");

    getLocation();
  }, []);

  const assetSelected = useSelector(
    (state) => state.formReducer.assignRegisterForm.configuration
  );

  const [assetSearchData, setAssetSearchData] = useState([]);

  const [inputValue, setInputValue] = useState("");
  const [selectedValue, setSelectedValue] = useState(null);

  const debounce = (func) => {
    let timer;
    return function (...args) {
      const context = this;
      if (timer) clearTimeout(timer);
      timer = setTimeout(() => {
        timer = null;
        func.apply(context, args);
      }, 500);
    };
  };

  const handleChange = async (value) => {
    // debugger
    try {
      if (value === "") {
        dispatch(resetAssignFormConfiguration());
      }
      let data = await (
        await axios.get(`${baseUrl}/getDetailsByAssetId?assetId=${value}`)
      ).data;
      if (data) {
        setAssetSearchData(data);
      }
    } catch (error) {}
  };

  const optimizedFn = useCallback(debounce(handleChange), []);

  return (
    <Box>
      <Box style={{ padding: "0.8em 2em" }}>
        <Box
          flexGrow={1}
          display={"flex"}
          alignItems="end"
          justifyContent="space-between"
        >
          <Typography
            variant="h4"
            fontSize={20}
            style={{
              color: "#0288d1",
              fontWeight: "500",
              padding: "0.2em 1em 0.2em 0em",
              textAlign: "bottom",
            }}
          >
            Configuration
          </Typography>
          <Box sx={{ paddingBottom: "4px" }}>
            {/* <AutoCompleteSearch
              autoCompleteonChange={(val) =>
                dispatch(setassignRegisterFormConfig(val))
              }
              textOnChange={(val) => optimizedFn(val)}
              options={assetSearchData}
              getOptionLabel={"assetId"}
              primaryTextOption={"assetId"}
              placeholder={"Search for Asset"}
            /> */}
            <Autocomplete
              // disableClearable
              sx={{ height: "70px", width: "240px" }}
              value={selectedValue}
              onChange={(event, value) => {
                setSelectedValue(value);
                dispatch(setassignRegisterFormConfig(value));
              }}
              onInputChange={(event, value) => {
                setInputValue(value);
                optimizedFn(value);
                if (value === "") {
                  setSelectedValue(null); 
                }
              }}
              inputValue={inputValue}
              // onChange={(event, value) =>
              //   dispatch(setassignRegisterFormConfig(value))
              // }

              // onInputChange={(event, value) => optimizedFn(value)}

              options={assetSearchData}
              // getOptionLabel={"assetId"}
              getOptionLabel={(option) => `${option.assetId} `}
              renderInput={(params) => (
                <TextField
                  {...params}
                  label="Search for Asset"
                  variant="outlined"
                  margin="dense"
                />
              )}
              clearOnBlur
              handleHomeEndKeys
            />
          </Box>
        </Box>
        <Divider />
      </Box>
      <Box sx={{ flexGrow: 1 }}>
        <Grid
          container
          alignItems="center"
          rowSpacing={2}
          columnSpacing={12}
          sx={{ p: "0em 2em" }}
        >
          <GridFormTextInput
            sm={3}
            xs={12}
            value={assetSelected?.processor ?? ""}
            inputProps={inputSize}
            label={"Processor"}
          />
          <GridFormTextInput
            sm={3}
            xs={12}
            value={assetSelected?.storage ?? ""}
            inputProps={inputSize}
            label={"Storage"}
          />
          <GridFormTextInput
            sm={3}
            xs={12}
            value={assetSelected.assetType?.assetTypeName ?? ""}
            inputProps={inputSize}
            label={"Type"}
          />
          <GridFormTextInput
            sm={3}
            xs={12}
            value={assetSelected?.gen ?? ""}
            inputProps={inputSize}
            label={"Generation"}
          />
          <GridFormTextInput
            sm={3}
            xs={12}
            value={assetSelected?.ram ?? ""}
            inputProps={inputSize}
            label={"RAM"}
          />
          <GridFormTextInput
            sm={3}
            xs={12}
            value={assetSelected.brand?.brandName ?? ""}
            inputProps={inputSize}
            label={"Brand"}
          />
          <GridFormTextInput
            sm={3}
            xs={12}
            value={assetSelected?.model ?? ""}
            inputProps={inputSize}
            label={"Model"}
          />

          {/* new code location added */}
          {/* <GridDropDownItem
            sm={3}
            xs={12}
            label={"Base Location"}
            disabled={true}
            inputProps={inputSize}
            //onChang={handleOnChange}
            // name={"location"}
            defaultValue={assetSelected?.location ?? ""}
            menuItems={{
              Bhubaneswar: "Bhubaneswar",
              Bangalore: "Bangalore",
            }}
          /> */}

          {/* <Grid item sm={3} xs={12}>
           
            <InputLabel
              sx={{
                color: "#424242",
                fontSize: "16px",
                fontWeight: "400",
                lineHeight: "24px",
                letterSpacing: "0.005em",
              }}
            >
              Current Location
            </InputLabel>

            <TextField
              select
              disabled={true}
              sx={{ width: "max-content", marginTop: "0px" }}
              SelectProps={inputSize}
              margin="normal"
              id="outlined-basic"
              variant="outlined"
              value={userSelected?.userLocation ?? ""}
            >
              {location.map((option) => {
                return (
                  <MenuItem key={option.label} value={option}>
                    {option}
                  </MenuItem>
                );
              })}
            </TextField>

            
          </Grid> */}

          <Grid item sm={3} xs={12}>
            {/* new changes */}
            <InputLabel
              sx={{
                color: "#424242",
                fontSize: "16px",
                fontWeight: "400",
                lineHeight: "24px",
                letterSpacing: "0.005em",
              }}
            >
              Base Location
            </InputLabel>

            {/* <FormControl sx={{ width: "100%" }}>
              <Select
                name="baseLocation"
                value={baseLocation}
                onChange={(e) => {
                  setBaseLocation(e.target.value);
                }}
                SelectDisplayProps={inputSize}
                sx={{ width: "100%" }}
              >
                {location?.map((option) => (
                  <MenuItem key={option} value={option}>
                    {option}
                  </MenuItem>
                ))}
              </Select>
            </FormControl> */}

            <FormControl sx={{ width: "100%" }}>
              <TextField
                select
                disabled={false}
                sx={{ width: "100%", marginTop: "0px" }}
                SelectProps={inputSize}
                id="outlined-basic"
                variant="outlined"
                value={baseLocation}
                onChange={(e) => {
                  setBaseLocation(e.target.value);
                }}
              >
                {location.map((option) => {
                  return (
                    <MenuItem key={option.label} value={option}>
                      {option}
                    </MenuItem>
                  );
                })}
              </TextField>
            </FormControl>

            {/* <TextField
              select
              disabled={false}
              sx={{ width: "100%", marginTop: "0px" }}
              SelectProps={inputSize}
              margin="normal"
              id="outlined-basic"
              variant="outlined"
              value={baseLocation}
              onChange={(e) => {
                setBaseLocation(e.target.value);
              }}
            >
              {location.map((option) => {
                return (
                  <MenuItem key={option.label} value={option}>
                    {option}
                  </MenuItem>
                );
              })}
            </TextField> */}

            {/* end */}
          </Grid>
        </Grid>
      </Box>
    </Box>
  );
};

const GridFromDateInput = ({
  sm = 3,
  xs = 12,
  label = "",
  onChange = null,
  value = date,
}) => {
  return (
    <Grid item sm={sm} xs={xs}>
      <InputLabel
        sx={{
          color: "#424242",
          fontSize: "16px",
          fontWeight: "400",
          lineHeight: "24px",
          letterSpacing: "0.005em",
        }}
      >
        {label}
      </InputLabel>
      <LocalizationProvider dateAdapter={AdapterDateFns}>
        <DatePicker
          InputProps={dateInputSize}
          value={value}
          onChange={onChange}
          renderInput={(params) => (
            <FormControl sx={{ width: "100%" }}>
              <TextField {...params} />
            </FormControl>
          )}
        />
      </LocalizationProvider>
    </Grid>
  );
};

const GridFormTextInput = ({
  sm = 3,
  xs = 12,
  value = "",
  label = "",
  inputProps,
}) => {
  return (
    <Grid item sm={sm} xs={xs}>
      <InputLabel
        sx={{
          color: "#424242",
          fontSize: "16px",
          fontWeight: "400",
          lineHeight: "24px",
          letterSpacing: "0.005em",
        }}
      >
        {label}
      </InputLabel>
      <FormControl sx={{ width: "100%" }}>
        <TextField
          inputProps={inputProps}
          id="outlined-basic"
          variant="outlined"
          type="email"
          value={value ?? ""}
          disabled
        />
      </FormControl>
    </Grid>
  );
};

//////new

{
  /* <GridFormTextInput
              sm={3}
              xs={12}
              disabled={true}
              value={assignFormData?.location ?? ""}
              inputProps={inputSize}
              label={"Location"}
            ></GridFormTextInput> */
}

// const GridDropDownItem = ({
//   // xs = 6,
//   sm = 3,
//   xs = 12,
//   // inputProps,
//   label,
//   disabled = false,
//   onChange,
//   // name,
//   defaultValue,
//   menuItems = {},
// }) => {
//   return (
//     <Grid item xs={xs} sm={sm}>
//       {/* <Typography sx={{ color: "#424242" }}>
//         {label}
//         <span style={{ color: "red" }}>*</span>
//       </Typography> */}
//       <InputLabel
//         sx={{
//           color: "#424242",
//           fontSize: "16px",
//           fontWeight: "400",
//           lineHeight: "24px",
//           letterSpacing: "0.005em",
//         }}
//       >
//         {label}
//       </InputLabel>

//       <TextField
//         sx={{ marginTop: "0px" }}
//         select
//         disabled={disabled}
//         // sx={{ width: "max-content" }}
//         SelectProps={inputSize}
//         margin="normal"
//         id="outlined-basic"
//         variant="outlined"
//         // inputProps={inputProps}
//         defaultValue={defaultValue}
//         onChange={onChange}
//       >
//         {Object.keys(menuItems).map((key) => {
//           return <MenuItem value={`${key}`}>{menuItems[key]}</MenuItem>;
//         })}
//       </TextField>
//     </Grid>
//   );
// };

import axios from "axios";
import { baseUrl } from "../../Utils/serviceRequest";

export const fetchPaginatedData = async ({ queryKey }) => {
    const [_key, { pageSize, page }] = queryKey;
    try {
        let res = await axios.get(
            `${baseUrl}/getAssets/${pageSize}/${page}`
        );
        if (res.status === 200 && res.data.result === "success") {
            // setPageState((prev) => ({ ...prev, isLoading: false, data: res.data.data, total: res.data.count }))
            return res.data;
        }
    } catch (e) {
        console.error(e);
    }
};

export const fetchFilteredPaginatedData = async (pageSize, page, payload) => {
    try {
        let res = await axios.post(
            `${baseUrl}/filterAssets/${pageSize}/${page}`, payload
        );
        if (res.status === 200 && res.data.result === "success") {
            // setPageState((prev) => ({ ...prev, isLoading: false, data: res.data.data, total: res.data.count }))
            return res.data;
        }
    } catch (e) {
        console.error(e);
    }
};


export const createAssetAPI = async (payload) => {
    // debugger
    try {
        let data = await axios.post(`${baseUrl}/createAsset`, payload);
        return data;
    } catch (error) {
        console.error(error);
    }
}


export const deleteAsset = async (SelectedAssets) => {
    try {
        console.log(SelectedAssets)
        let response = await axios.delete(`${baseUrl}/deleteAsset`, {
            data: { id: SelectedAssets },
        });
        if (response.status === 200) {
            return response;
        }
    } catch (err) {
        console.error(err)
    }
}

import React, { useEffect, useState } from 'react';
import { Bar } from 'react-chartjs-2';
import axios from 'axios';
import { Select, MenuItem, FormControl, Paper, Box, InputLabel } from '@mui/material';

// Import Chart.js components
import {
  Chart as ChartJS,
  CategoryScale,
  LinearScale,
  BarElement,
  Title,
  Tooltip,
  Legend,
} from 'chart.js';
import { baseUrl } from '../../Utils/serviceRequest';

// Register Chart.js components
ChartJS.register(
  CategoryScale,
  LinearScale,
  BarElement,
  Title,
  Tooltip,
  Legend
);

const LocAssetBarChart = () => {
  const [locations, setLocations] = useState([]);
  const [selectedLocation, setSelectedLocation] = useState('');
  const [chartData, setChartData] = useState(null);
  const [data, setData] = useState([]);

  useEffect(() => {
    const fetchData = async () => {
      try {
        const response = await axios.get(`${baseUrl}/getLocationWiseAssetType`); 
        const data = response.data.data;
        setData(data);

        // Extract unique locations
        const uniqueLocations = [...new Set(data.map(item => item.location))].filter(location => location);
        
        setLocations(uniqueLocations);

        if (uniqueLocations.length > 0) {
          setSelectedLocation(uniqueLocations[0]);
          processChartData(data, uniqueLocations[0]);
        }
      } catch (error) {
        console.error('Error fetching data:', error);
      }
    };
    fetchData();
  }, []);

  const processChartData = (data, location) => {
    const filteredData = data.filter(item => item.location === location);
    const assetTypes = [...new Set(filteredData.map(item => item.asset_type_name))];
    const assetCounts = assetTypes.map(type => {
      const item = filteredData.find(d => d.asset_type_name === type);
      return item ? item.asset_count : 0;
    });
    const labels = assetTypes.map(type => `${type}`);

    setChartData({
      labels,
      datasets: [{
        label: 'Asset Count',
        data: assetCounts,
        backgroundColor: ['#FFE082', '#DCEDC8', '#E1BEE7', '#B2DFDB', '#FFAB91', '#D1C4E9',
    '#BCAAA4', '#CFD8DC'],
        barThickness: 30
      }]
    });
  };

  const handleLocationChange = (event) => {
    const location = event.target.value;
    setSelectedLocation(location);
    processChartData(data, location);
  };

  return (
    <>
    <Box
     sx={{height:"470px"}}  
     >
      <FormControl fullWidth>
        <Select
          value={selectedLocation}
          onChange={handleLocationChange}
          MenuProps={{
            PaperProps: {
              style: {
                maxHeight: 200, // Adjust the max height for the dropdown
              },
            },
          }}
        >
          {locations.map((loc) => (
            <MenuItem key={loc} value={loc}>
              {loc}
            </MenuItem>
          ))}
        </Select>
      </FormControl>

      {chartData && (
         <Paper
         elevation={3}
         sx={{
           marginTop: 2,
           height: "calc(100% - 56px)",
           display: "flex",
           flexDirection: "column",
         }}
       >
         <Box sx={{ flexGrow: 1, height: "100%" }}>
          <Bar 
            data={chartData} 
            // height={"100%"}
            options={{ 
              maintainAspectRatio: false,
              plugins: {
                legend: {
                  display: false, 
                },
                title: {
                  display: true,
                  text: `Asset Count for ${selectedLocation}`,
                  font: { size: 16 }
                }
              },
              scales: {
                x: {
                  beginAtZero: true,
                  grid: {
                    offset: false,
                    display: false // Optional: hide grid lines
                  },
                  ticks: {
                    align: 'center' // Align ticks to the start (left)
                  }
                },
                y: {
                  beginAtZero: true,
                  grid: {
                    offset: false,
                    display: false // Optional: hide grid lines
                  }
                }
              }
            }} 
          />
         </Box>
         </Paper>
      )}
    </Box>
    </>
  );
};

export default LocAssetBarChart;










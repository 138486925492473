import React, { useEffect, useState, useCallback } from 'react';
import { Doughnut } from 'react-chartjs-2';
import { Paper, Box, Button } from '@mui/material';
import axios from 'axios';
import { baseUrl } from '../../Utils/serviceRequest';

const DoughnutGraphChart = () => {
  const [chartData, setChartData] = useState(null);
  const [detailData, setDetailData] = useState(null);
  const [data, setData] = useState([]);
  const [selectedLoc, setSelectedLoc] = useState(null);
  const [aspectRatio, setAspectRatio] = useState(1.5);

  const colors = [
    '#FFCC80', '#80DEEA', '#FFD54F', '#C8E6C9', '#FFF176', '#9FA8DA', '#A5D6A7', '#F48FB1',
    '#FFCDD2', '#B3E5FC', '#FFE082', '#DCEDC8', '#E1BEE7', '#B2DFDB', '#FFAB91', '#D1C4E9',
    '#BCAAA4', '#CFD8DC'
  ];

  const handleResize = useCallback(() => {
    if (window.innerWidth < 1715) {
      setAspectRatio(1);
    }
   else if (window.innerWidth < 1850) {
      setAspectRatio(1.1);
    }
    else if (window.innerWidth < 2000) {
      setAspectRatio(1.2);
    } else if (window.innerWidth < 2200) {
      // debugger
      setAspectRatio(1.3);
    } else {
      setAspectRatio(1.5);
    }
  }, []);

  useEffect(() => {
    window.addEventListener('resize', handleResize);
    handleResize(); // Initial check
    return () => window.removeEventListener('resize', handleResize);
  }, [handleResize]);

  useEffect(() => {
    const fetchData = async () => {
      try {
        const response = await axios.get(`${baseUrl}/getLocationWiseStatus`);
        setData(response.data.data);
        processChartData(response.data.data);
      } catch (error) {
        console.error('Error fetching data:', error);
      }
    };
    fetchData();
  }, []);

  const processChartData = (data) => {
    const aggregatedData = data.reduce((acc, item) => {
      const location = item.location === null || item.location === undefined || item.location === 'undefined' ? 'Unknown' : item.location;
      if (!acc[location]) {
        acc[location] = { ...item };
        acc[location].location = location;
      } else {
        acc[location].Assigned += item.Assigned;
        acc[location].Disposed += item.Disposed;
        acc[location].Donated += item.Donated;
        acc[location].LostAndRecovered += item.LostAndRecovered;
        acc[location].Spare += item.Spare;
        acc[location].Damaged += item.Damaged;
      }
      return acc;
    }, {});

    const locations = Object.keys(aggregatedData);
    const assignedCounts = locations.map(loc => aggregatedData[loc].Assigned);

    setChartData({
      labels: locations,
      datasets: [{
        label: 'Assigned',
        data: assignedCounts,
        backgroundColor: colors,
      }]
    });
  };

  const handleClick = (event, elements) => {
    if (!elements.length) return;

    const { index } = elements[0];
    const selectedLocation = chartData.labels[index];
    setSelectedLoc(selectedLocation);

    const aggregatedData = data.reduce((acc, item) => {
      const location = item.location === null || item.location === undefined || item.location === 'undefined' ? 'Unknown' : item.location;
      if (!acc[location]) {
        acc[location] = { ...item };
        acc[location].location = location;
      } else {
        acc[location].Assigned += item.Assigned;
        acc[location].Disposed += item.Disposed;
        acc[location].Donated += item.Donated;
        acc[location].LostAndRecovered += item.LostAndRecovered;
        acc[location].Spare += item.Spare;
        acc[location].Damaged += item.Damaged;
      }
      return acc;
    }, {});

    const selectedData = aggregatedData[selectedLocation];

    if (selectedData) {
      const detailLabels = ['Assigned', 'Disposed', 'Donated', 'LostAndRecovered', 'Spare', 'Damaged'];
      const detailValues = [
        selectedData.Assigned,
        selectedData.Disposed,
        selectedData.Donated,
        selectedData.LostAndRecovered,
        selectedData.Spare,
        selectedData.Damaged
      ];

      setDetailData({
        labels: detailLabels,
        datasets: [{
          data: detailValues,
          backgroundColor: colors
        }]
      });
    }
  };

  return (
    <>
      {detailData ? (
        <Paper elevation={3} sx={{ padding: 2, height: '455px', width: '90%' }}>
          <Box sx={{ display: 'flex', justifyContent: 'flex-end' }}>
            <Button variant="outlined" onClick={() => setDetailData(null)}>
              Back
            </Button>
          </Box>
          <Box sx={{ height: '250px' }}>
            <Doughnut
              data={detailData}
              options={{
                plugins: {
                  legend: {
                    display: true,
                    position: "top",
                    labels: {
                      font: {
                        size: 12,
                      },
                    },
                  },
                  title: {
                    display: true,
                    text: `Details for ${selectedLoc}`,
                    font: {
                      size: 16,
                    },
                  }
                },
                maintainAspectRatio: true,
                responsive: true,
                aspectRatio: aspectRatio,
              }}
            />
          </Box>
        </Paper>
      ) : (
        <Paper elevation={3} sx={{ padding: 2, height: '455px', width: '90%' }}>
          {chartData && (
            <Box sx={{ height: '250px' }}>
              <Doughnut
                data={chartData}
                options={{
                  onClick: handleClick,
                  plugins: {
                    legend: {
                      display: true,
                      position: "top",
                      labels: {
                        font: {
                          size: 12,
                        },
                      },
                    },
                    title: {
                      display: true,
                      text: 'Assets by Location',
                      font: {
                        size: 16,
                      },
                    }
                  },
                  // maintainAspectRatio: true,
                  responsive: true,
                  aspectRatio: aspectRatio,
                }}
              />
            </Box>
          )}
        </Paper>
      )}
    </>
  );
};

export default DoughnutGraphChart;

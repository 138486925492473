import { ThemeProvider } from "@emotion/react";
import {
  Box,
  Button,
  createTheme,
  Divider,
  InputLabel,
  Paper,
  Tab,
  Tabs,
  TextField,
  Typography,
  MenuItem,
  Select,
  FormControl,
} from "@mui/material";

import React, { useState } from "react";
import { useDispatch } from "react-redux";
import AssetAssignForm from "../../Components/FormComponents/AssetAssignForm";
import AssetReturnForm from "../../Components/FormComponents/AssetReturnForm";
import AssignOtherAssetForm from "../../Components/FormComponents/AssignOtherAssetForm";
import OtherAssetReturnForm from "../../Components/FormComponents/OtherAssetReturnForm";
import { setSelectedNavIndex } from "../../redux/reducers/appReducer";
import "./Management.css";

let tabTheme = createTheme({
  components: {
    MuiTab: {
      styleOverrides: {
        root: {
          minHeight: "20px",
        },
      },
    },
  },
});

const Management = () => {
  const dispatch = useDispatch();
  dispatch(setSelectedNavIndex(2));

  const [value, setValue] = useState(0);

  const handleChange = (event, newValue) => {
    setValue(newValue);
  };

  function TabPanel(props) {
    const { children, value, index } = props;
    return <>{value === index && <>{children}</>}</>;
  }

  return (
    <ThemeProvider theme={tabTheme}>
      <Box sx={{ padding: "1em" }}>
        <Box>
          <Tabs
            value={value}
            onChange={handleChange}
            sx={{
              "& button": {
                fontWeight: "200",
                fontSize: "16px",
                borderRadius: "4px 4px 0 0",
                border: "solid 1px #ddd",
                padding: "8px 14px",
                textTransform: "none",
              },
              "& button.Mui-selected": {
                fontWeight: "bold",
                fontSize: "16px",
                backgroundColor: "#f1f5fe",
                color: "#0288d1",
                boxShadow: "0",
                padding: "0",
                padding: "8px 14px",
                boxShadow: "rgb(0 0 0 / 25%) 0px 4px 4px 2px",
              },
            }}
            style={{ width: "100%", minHeight: "30px" }}
            TabIndicatorProps={{ hidden: true }}
          >
            <Tab label="Assign Asset" />
            <Tab label="Return Asset" />
            {/* <Tab label="Assign Other Asset" />
            <Tab label="Return Other Asset" /> */}
          </Tabs>
        </Box>
        <TabPanel value={value} index={0}>
          <Paper
            className="wbScroll"
            sx={{
              height: "calc(77vh)",
            }}
            style={{ overflow: "auto", paddingBottom: "1em" }}
          >
            <AssetAssignForm />
          </Paper>
        </TabPanel>
        <TabPanel value={value} index={1}>
          <Paper
            className="wbScroll"
            sx={{
              height: "calc(77vh)",
            }}
            style={{ overflow: "auto", paddingBottom: "1em" }}
          >
            <AssetReturnForm />
          </Paper>
        </TabPanel>
        <TabPanel value={value} index={2}>
          <Paper
            className="wbScroll"
            sx={{
              height: "calc(77vh)",
            }}
            style={{ overflow: "auto", paddingBottom: "1em" }}
          >
            <AssignOtherAssetForm />
          </Paper>
        </TabPanel>
        <TabPanel value={value} index={3}>
          <Paper
            className="wbScroll"
            sx={{
              height: "calc(77vh)",
            }}
            style={{ overflow: "auto", paddingBottom: "1em" }}
          >
            <OtherAssetReturnForm />
          </Paper>
        </TabPanel>
      </Box>
    </ThemeProvider>
  );
};

export default Management;

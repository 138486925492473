import React, { useCallback } from "react";
import { useDispatch } from "react-redux";
import TableUtility from "../../Components/TableUtility/TableUtility";
import { setSelectedNavIndex } from "../../redux/reducers/appReducer";
import axios from "axios";
import { baseUrl } from "../../Utils/serviceRequest";
import { useState } from "react";
import { useEffect } from "react";
import {
  Box,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  Grid,
  Stack,
  TextField,
  Typography,
  MenuItem,
  Snackbar,
  Alert,
  FormControl,
} from "@mui/material";
import { SearchOutlined } from "@mui/icons-material";

const roleOptions = ["user", "admin", "superAdmin"];

const inputSize = {
  style: {
    height: "5vh",
    maxHeight: "32px",
    width: "10em",
    padding: "2px 10px",
  },
};

const UserDetails = () => {
  const dispatch = useDispatch();
  dispatch(setSelectedNavIndex(7));

  const [userDetailsList, setUserDetailsList] = useState([]);
  const [pageNumber, setPageNumber] = useState(1);
  const fetchData = async () => {
    try {
      let res = await axios.get(
        `${baseUrl}/getUserDetailsWithRole/50/${pageNumber}`
      );
      if (res.status === 200) {
        setUserDetailsList((prev) => prev.concat(res.data));
        setPageNumber((prev) => prev + 1);
      }
    } catch (error) {
      console.error(error);
    }
  };

  useEffect(() => {
    fetchData();
  }, []);

  //state to handle edit dialog
  const [editModalStatus, setEditModalStatus] = useState(false);
  const [ReceivedIdFromTable, setReceivedIdFromTable] = useState(null);

  //snackbar states
  const [snackBarStatus, setSnackBarStatus] = useState(false);
  const [snackBarMessage, setSnackBarMessage] = useState("");
  const [snackBarSeverity, setSnackBarSeverity] = useState("success");

  //state to store role of selected user
  const [roleSelected, setRoleSelected] = useState({
    userId: "",
    role: "",
  });

  let ShowEditModalFunc = (receivedIdFromTable) => {
    let func = () => {
      receivedIdFromTable && setReceivedIdFromTable(receivedIdFromTable);
      let tempList;
      if (searchInput !== "") {
        tempList = searchedData;
      } else {
        tempList = userDetailsList;
      }
      tempList.map((item) => {
        if (item.email === receivedIdFromTable) {
          setRoleSelected({
            userId: item?.email ?? "",
            role: item?.role ?? "",
          });
        }
      });
      setEditModalStatus(true);
    };
    func();
  };

  const getNextBatchOfData = () => {
    fetchData();
  };

  let options = {
    getNextBatchOfData,
    Data: userDetailsList,
    headerkeys: ["", "Email ID", "First Name", "Last Name", "Role", ""],
    bodykeys: ["", "email", "firstName", "lastName", "role"],
    IdKey: "email",
    ShowEditModalFunc,
    enableDelete: false,
    enableEdit: true,
    height: "80vh",
  };

  const changeUserRole = async () => {
    try {
      let res = await axios.put(`${baseUrl}/changeRoleOfUser`, roleSelected);
      if (res.status === 200) {
        setEditModalStatus(false);
        setSnackBarMessage(res.data.result);
        setSnackBarSeverity("success");
        setSnackBarStatus(true);
      } else {
        setEditModalStatus(false);
        setSnackBarMessage(res.data.result);
        setSnackBarSeverity("error");
        setSnackBarStatus(true);
      }
    } catch (error) {
      console.error(error);
      setEditModalStatus(false);
      setSnackBarMessage("Oops! Something Went Wrong");
      setSnackBarSeverity("error");
      setSnackBarStatus(true);
    }
  };

  //state for search
  const [searchInput, setSearchInput] = useState("");
  const [searchedData, setSearchedData] = useState([]);
  const handleChange = async (e) => {
    setSearchInput(e.target.value);
    // const tempData = userDetailsList.filter((item) => {
    //   return Object.keys(item).some((key) =>
    //     item[key]
    //       .toString()
    //       .toLowerCase()
    //       .includes(e.target.value.toLowerCase())
    //   );
    // });
    try {
      let res = await axios.get(
        `${baseUrl}/getUserDetailsWithRoleByUsing/${e.target.value}`
      );
      if (res.status === 200) {
        setSearchedData(res.data);
      }
    } catch (error) {}

    // console.log(tempData);
    // setSearchedData(tempData);
  };

  const debounce = (func) => {
    let timer;
    return function (...args) {
      const context = this;
      if (timer) clearTimeout(timer);
      timer = setTimeout(() => {
        timer = null;
        func.apply(context, args);
      }, 500);
    };
  };

  const optimizedFn = useCallback(debounce(handleChange), []);

  return (
    <Box sx={{ padding: "1em" }}>
      <Box
        sx={{
          flexGrow: "1",
          display: "flex",
          justifyContent: "flex-end",
          gap: "2%",
          alignItems: "center",
        }}
      >
        <FormControl sx={{ height: "6vh" }}>
          <TextField
            placeholder="Search..."
            inputProps={inputSize}
            InputProps={{
              endAdornment: <SearchOutlined color="disabled" />,
            }}
            onChange={(e) => optimizedFn(e)}
          />
        </FormControl>
      </Box>
      <Box alignItems="center" display="flex" style={{ padding: "0" }}>
        {searchInput !== "" ? (
          <TableUtility {...{ ...options, Data: searchedData }} />
        ) : (
          <TableUtility {...options} />
        )}
      </Box>
      {/* dialog box to update user role */}
      <Dialog open={editModalStatus} sx={{ padding: "20px" }}>
        <DialogContent sx={{ padding: "10px" }} className="wbScroll">
          <Stack>
            <Grid container spacing={8} rowSpacing={3}>
              <Grid item xs={6}>
                <Typography sx={{ color: "#424242" }}>Select Role</Typography>
                <TextField
                  select
                  InputProps={inputSize}
                  margin="normal"
                  id="outlined-basic"
                  variant="outlined"
                  name="role"
                  value={roleSelected.role}
                  onChange={(e) =>
                    setRoleSelected((prev) => {
                      return { ...prev, role: e.target.value };
                    })
                  }
                >
                  {roleOptions?.map((item) => (
                    <MenuItem key={item} value={item}>
                      {item}
                    </MenuItem>
                  ))}
                </TextField>
              </Grid>
            </Grid>
          </Stack>
        </DialogContent>
        <DialogActions>
          <Button
            type="submit"
            onClick={() => changeUserRole()}
            variant="contained"
            sx={{
              backgroundColor: "#0288d1",
              textTransform: "none",
              "&:hover": {
                backgroundColor: "#140B96",
                boxShadow: "1px #000000",
              },
            }}
            disableRipple
          >
            Save
          </Button>
          <Button
            onClick={() => setEditModalStatus(false)}
            variant="contained"
            sx={{
              backgroundColor: "#E0E0E0",
              textTransform: "none",
              color: "black",
              "&:hover": {
                backgroundColor: "#E0E0E0",
                boxShadow: "1px #000000",
              },
            }}
            disableRipple
          >
            Cancel
          </Button>
        </DialogActions>
      </Dialog>

      {/* snackbar for success and error message */}
      <Snackbar
        anchorOrigin={{ vertical: "top", horizontal: "center" }}
        open={snackBarStatus}
        autoHideDuration={6000}
        onClose={() => setSnackBarStatus(false)}
      >
        <Alert
          onClose={() => setSnackBarStatus(false)}
          severity={snackBarSeverity}
          sx={{ width: "100%" }}
        >
          {snackBarMessage}
        </Alert>
      </Snackbar>
    </Box>
  );
};

export default UserDetails;

import React from "react";
import { Paper, Tabs, Tab } from "@mui/material";
import TableUtility from "../../Components/TableUtility/TableUtility";
import {
  Grid,
  Typography,
  Button,
  Checkbox,
  Collapse,
  List,
  ListItem,
  ButtonGroup,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  createTheme,
  ThemeProvider,
  TextField,
  Snackbar,
  Alert,
  FormGroup,
  FormControlLabel,
  Stack,
  MenuItem,
  InputAdornment,
  Slide,
  Divider,
  IconButton,
} from "@mui/material";
import { useState, useEffect } from "react";
import ErrorOutlineIcon from "@mui/icons-material/ErrorOutline";
import FilterAltIcon from "@mui/icons-material/FilterAlt";
import UploadFileIcon from "@mui/icons-material/UploadFile";
import AddCircleIcon from "@mui/icons-material/AddCircle";
import FileDownloadIcon from "@mui/icons-material/FileDownload";
import DeleteIcon from "@mui/icons-material/Delete";
import axios from "axios";
import { Box } from "@mui/system";
import { baseUrl } from "../../Utils/serviceRequest";
import { useDispatch, useSelector } from "react-redux";
import xlsx from "json-as-xlsx";
import { FilterAltOff } from "@mui/icons-material";
import {
  deleteIntangibleAssetsPrimaryData,
  setIntangibleAssetsFilteredData,
  setIntangibleAssetsPrimaryData,
  setSelectedNavIndex,
  updateIntangibleAssetsPrimaryData,
} from "../../redux/reducers/appReducer";
import DraggableFileUploader from "../../Components/DraggableComponent/DraggableFileUploader";

import "./IntangibleAssets.css";

const IntangibleAssets = () => {
  const dispatch = useDispatch();

 
  const handleValidations = (val) => {
    let keys = Object.keys(EditFormData);
    let count = 0;
    let add = (val) => {
      if (val === 0) {
        createAsset();
      } else if (val === 1) {
        updateAsset();
      }
    };
    keys.map((item) => {
      count++;

      if (EditFormData[item].length === 0) {
        alert(`${item.toUpperCase()} field Cannot be Empty`);
        count--;
      }
      console.log(count, keys.length);
      count == keys.length && add(val);
    });
  };

  /* Validations End */

  // **master data********************
  //IntangibleAssetsData is the source for the data sent to the table. data is stored by fetching the data from the database

  const appReducerState = useSelector((state) => state.appReducer);

  const fetchData = async () => {
    try {
      let res = await axios.get(`${baseUrl}/getIntangibleMaster`);
      if (res.status === 200) {
        dispatch(setIntangibleAssetsPrimaryData(res.data));
      }
    } catch (e) {
      console.error(e);
    }
  };

  useEffect(() => {
    fetchData();
  }, []);

  // ***********all state variables **

  const [deleteModalStatus, setDeleteModalStatus] = useState(false);
  const [editModalStatus, setEditModalStatus] = useState(false);
  const [AddModalStatus, setAddModalStatus] = useState(false);
  const [SuccessStatus, setSuccessStatus] = useState(false);
  const [FailedStatus, setFailedStatus] = useState(false);
  const [importStatus, setImportStatus] = useState(false);
  const [filterListStatus, setFilterListStatus] = useState(false);

  const [selectedFile, setSelectedFile] = useState(null);

  //checkbox state variables for filtering data
  const [filterOptions, setFilterOptions] = useState({
    isBbsr: {
      checked: false,
      name: "Bhubaneswar",
      type: "location",
    },
    isBang: {
      checked: false,
      name: "Bangalore",
      type: "location",
    },
    isMobile: {
      checked: false,
      name: "Mobile",
      type: "assetType",
    },
    isLaptop: {
      checked: false,
      name: "Laptop",
      type: "assetType",
    },
    isLenovo: {
      checked: false,
      name: "Lenovo",
      type: "brand",
    },
    isDell: {
      checked: false,
      name: "Dell",
      type: "brand",
    },
    isHP: {
      checked: false,
      name: "HP",
      type: "brand",
    },
    isApple: {
      checked: false,
      name: "Apple",
      type: "brand",
    },
  });

  const [filteredOptionList, setFilteredOptionList] = useState([]);

  const handleOnFilterChange = (e) => {
    let name = e.target.name;
    let value = e.target.checked;
    let tempState = { ...filterOptions };
    tempState[name]["checked"] = value;
    setFilterOptions(tempState);
    if (value === true)
      setFilteredOptionList((prev) => prev.concat(filterOptions[name]));
    else
      setFilteredOptionList((prev) =>
        prev.filter((val) => val !== filterOptions[name])
      );
  };

  const clearAllFilter = () => {
    setFilterOptions({
      isBbsr: {
        checked: false,
        name: "Bhubaneswar",
        type: "location",
      },
      isBang: {
        checked: false,
        name: "Bangalore",
        type: "location",
      },
      isMobile: {
        checked: false,
        name: "Mobile",
        type: "assetType",
      },
      isLaptop: {
        checked: false,
        name: "Laptop",
        type: "assetType",
      },
      isLenovo: {
        checked: false,
        name: "Lenovo",
        type: "brand",
      },
      isDell: {
        checked: false,
        name: "Dell",
        type: "brand",
      },
      isHP: {
        checked: false,
        name: "HP",
        type: "brand",
      },
      isApple: {
        checked: false,
        name: "Apple",
        type: "brand",
      },
    });
    setFilteredOptionList([]);
  };

  //functionality to get filtered data
  const [filteredData, setFilteredData] = useState([]);

  const setFilteredDataFunc = () => {
    const filteredRows = appReducerState.inventoryData.primaryData.data.filter(
      (row) => {
        let selected = true;
        filteredOptionList.forEach((element) => {
          if (element["name"] !== row[element["type"]]) {
            selected = false;
            return;
          }
        });
        return selected;
      }
    );
    setFilteredData(filteredRows);
    dispatch(setIntangibleAssetsFilteredData(filteredRows));
  };

  useEffect(() => {
    setFilteredDataFunc();
  }, [filteredOptionList]);

  //checkbox filtering ends

  const [ReceivedIdFromTable, setReceivedIdFromTable] = useState(null); //data updated from id received by the showDeleteModal functions provided to table component

  //DElETE RELATED OPERATIONS
  const [SelectedAssets, setSelectedAssets] = useState([]);
  const [SelectAll, setSelectAll] = useState(false);
  const [DeleteMethod, setDeleteMethod] = useState(null);
  const addSelectedAsset = (e, DATA, METHOD) => {
    let value = e.target.checked;
    let method = e.target.dataset.method;
    let id = e.target.dataset.id;
    if (SelectedAssets.includes(parseInt(id)) == true) {
      console.log("yes");
      let data = [...SelectedAssets];
      data.pop(parseInt(id));
      console.log(data);
      setSelectedAssets(data);
    }

    if (method == "SELECTALL") {
      console.log(DATA, "SDAASDASDAS");
      // SelectAll ? setSelectAll(false) : setSelectAll(true)
      setSelectedAssets([...DATA]);
      setDeleteMethod(method);
    }

    if (method == "SELECTMULTIPLE") {
      let id = e.target.dataset.id;
      if (value === true) {
        setSelectedAssets([...SelectedAssets, id]);
        setDeleteMethod(method);
      }
      if (value === false) {
        let array = SelectedAssets.filter((item) => {
          return item !== id; //returns false if selectedasset state contains assetid
        });
        setSelectedAssets(array);
        setDeleteMethod(method);
      }
    }
  };
  //------

  const [EditFormData, setEditFormData] = useState({
    id: 0,
    name: "",
    licenseStDate: "",
    licenseEndDate: "",
    noOfLicense: "",
    licenseAssigned: "00",
    version: "",
    price: "",
    type: "",
    organization: "",
  });

  // ******all state variables ends**
  // ***********functions to handle update, delete & add data **

  const uploadFile = async (e) => {
    try {
      const formData = new FormData();
      formData.append("file", selectedFile);
      formData.append("sheetName", "assign");
      let data = await axios.post(`${baseUrl}/asset/upload`, formData);
      if (data.status === 200) setSuccessStatus(true);
      handleClose(e);
      if (data.status !== 200) setFailedStatus(true);
    } catch (error) {
      console.error(error);
      setFailedStatus(true);
    }
  };

  const createAsset = async () => {
    let stateData = { ...EditFormData };
    try {
      let data = await axios.post(
        `${baseUrl}/createIntangibleMaster`,
        stateData
      );
      if (data) {
        handleClose();
      }
      if (data.status === 200) {
        //if 200 ? update data on ui side
        dispatch(
          updateIntangibleAssetsPrimaryData([
            ...appReducerState.intangibleAssets.primaryData.data,
            { ...stateData },
          ])
        );
        setSuccessStatus(true);
      }
      if (data.status !== 200) {
        setFailedStatus(true);
      }
    } catch (error) {
      console.error(error);
    }
  };

  const updateAsset = async () => {
    let stateData = EditFormData;
    try {
      let data = await axios.put(
        `${baseUrl}/updateIntangibleMaster`,
        stateData
      );
      if (data) {
        handleClose();
      }
      if (data.status === 200) {
        //if 200 ? update data on ui side
        console.log(data);
        let updatedData = appReducerState.intangibleAssets.primaryData.data.map(
          (item) => {
            if (item.id === stateData.id) {
              return stateData;
            }
            return item;
          }
        );
        // setIntangibleAssetsData(updatedData)
        dispatch(updateIntangibleAssetsPrimaryData(updatedData));

        setSuccessStatus(true);
      }
      if (data.status !== 200) {
        setFailedStatus(true);
      }
    } catch (error) {
      console.error(error);
    }
  };

  let handleOnChange = (e) => {
    //single function handling both updating the data and adding the data, form data is handled in a single EditFromData state reason for data structure being same
    let value = e.target.value;
    let name = e.target.name;
    let stateData = { ...EditFormData };
    stateData[name] = value;
    setEditFormData(stateData);
    console.log(EditFormData);
  };

  let handleDelete = async (e) => {
    //handling the delete process, Id is received from Id state
    //bhupendra's multi delete
    let DeleteApi = async (payload) => {
      let response = await axios.delete(`${baseUrl}/deleteIntangibleAsset`, {
        data: { id: payload },
      });
      console.log(response);
      if (response.status === 200) {
        dispatch(deleteIntangibleAssetsPrimaryData(payload));
        setSuccessStatus(true);
      }
      if (response.status !== 200) {
        setFailedStatus(true);
      }
    };

    try {
      setDeleteModalStatus(false);
      let selectedIds = [];

      if (DeleteMethod === "DELETEONE") {
        selectedIds = [ReceivedIdFromTable];
        DeleteApi(selectedIds);
        setDeleteMethod(null);
        setReceivedIdFromTable(null);
        console.log("deleteone");
      }

      if (DeleteMethod === "SELECTALL") {
        selectedIds = [...SelectedAssets];
        DeleteApi(selectedIds);
        setDeleteMethod(null);
        setReceivedIdFromTable(null);
        setSelectAll(false);
      }

      if (DeleteMethod === "SELECTMULTIPLE") {
        selectedIds = [...SelectedAssets];
        DeleteApi(selectedIds);
        setSelectedAssets([]);
        setDeleteMethod(null);
      }
    } catch (e) {
      console.log("error in delete method", e);
    }
  };

  // functions to handle display and hidding of modals*******************

  let handleClose = (e) => {
    // handling closing for all modals, and then updates the EditFormData to a blank template to make clear form input values //problem was in switching from edit process to add process.
    setDeleteModalStatus(false);
    setEditModalStatus(false);
    setAddModalStatus(false);
    setEditFormData({
      id: 0,
      name: "",
      licenseStDate: "",
      licenseEndDate: "",
      noOfLicense: "",
      licenseAssigned: "00",
      version: "",
      price: "",
      type: "",
      organization: "",
    });
    setImportStatus(false);
  };
  //**********showing delete modal ********/
  let ShowDeleteModalFunc = (receivedIdFromTable, method) => {
    method === "DELETEONE" && setReceivedIdFromTable(receivedIdFromTable);
    method === "DELETEONE" && setDeleteMethod(method);
    setDeleteModalStatus(true);
  };
  //****/

  //**********showing import modal ********/
  let ShowImportModalFunc = () => {
    setImportStatus(true);
  };
  //****/

  //**********showing add modal ********/

  let ShowAddModal = () => {
    setAddModalStatus(true);
  };

  //**********showing edit modal ********/

  let ShowEditModalFunc = (receivedIdFromTable) => {
    let func = () => {
      receivedIdFromTable && setReceivedIdFromTable(receivedIdFromTable);
      appReducerState.intangibleAssets.primaryData.data.map((item) => {
        if (item.id === receivedIdFromTable) {
          setEditFormData({ ...EditFormData, ...item });
        }
      });
      setEditModalStatus(true);
    };
    func();
  };

  // *functions to handle display and hidding of modals ends*/

  //*function to get next batch of data for the table for infinite scrolling*/

  // const [dataBatchCount, setdataBatchCount] = useState({start: 10, end: 19})
  let getNextBatchOfData = () => {
    //new code to append data to existing list
    // fetchData();
  };
  //SORTING FUNCTIONS

  let Sort = (e) => {};

  const inputSize = {
    style: {
      height: "5vh",
      maxHeight: "32px",
      width: "15em",
      padding: "2px 10px",
    },
  };

  let dialogTheme = createTheme({
    components: {
      MuiDialogTitle: {
        styleOverrides: {
          root: {
            paddingTop: "10px",
          },
        },
      },
      MuiGrid: {
        styleOverrides: {
          root: {
            paddingTop: "10px",
          },
        },
      },
      MuiPaper: {
        styleOverrides: {
          root: {
            padding: "10px",
          },
        },
      },
    },
  });
  if (SuccessStatus | FailedStatus) {
    setTimeout(() => {
      setFailedStatus(false);
      setSuccessStatus(false);
    }, 3000);
  }

  let options = {
    getNextBatchOfData,
    Data:
      filteredOptionList.length > 0
        ? filteredData
        : appReducerState.intangibleAssets.primaryData.data,
    headerkeys: [
      "Name",
      "Start Date",
      "End Date",
      "No. Of License",
      "License Assigned",
      "version",
      "Price",
      "Type",
      "Organization",
      "",
    ],
    bodykeys: [
      "name",
      "licenseStDate",
      "licenseEndDate",
      "noOfLicense",
      "licenseAssigned",
      "version",
      "price",
      "type",
      "organization",
    ],
    IdKey: "id",
    ShowEditModalFunc,
    ShowDeleteModalFunc,
    ShowAddModal,
    addSelectedAsset,
    SelectedAssets,
    SelectAll,
    // setIntangibleAssetsData
  };

  return (
    <>
      <Stack style={{ position: "relative" }}>
        <Box
          sx={{ display: "flex" }}
          style={{
            position: "absolute",
            top: "-40px",
            right: "0",
            width: "70%",
          }}
        >
          {/* <Box sx={{ flexGrow: '4' }}>
            <Typography variant='h6' sx={{ padding: '20px', fontWeight: 'bold' }}>INVENTORY</Typography>
          </Box> */}

          <Box
            sx={{
              flexGrow: "1",
              display: "flex",
              justifyContent: "flex-end",
              paddingRight: "20px",
              gap: "2%",
              alignItems: "center",
            }}
          >
            <Button
              variant="contained"
              endIcon={<DeleteIcon sx={{ color: "red" }} />}
              sx={{
                border: "1px solid #F1F5FE",
                backgroundColor: "#F1F5FE",
                color: "#0288d1",
                fontSize: "12px",
                textTransform: "capitalize",
                height: "max-content",
                "&:hover": {
                  backgroundColor: "#F1F5FE",
                  color: "#0288d1",
                  border: "1px solid #0288d1",
                  boxShadow: "1px #000000",
                },
              }}
              onClick={ShowDeleteModalFunc}
            >
              {" "}
              Delete
            </Button>
            <Box>
              {/* <Button
                variant='contained'
                endIcon={!filterListStatus ? <FilterAltIcon /> : <FilterAltOff />}
                sx={{
                  border: '1px solid #F1F5FE', backgroundColor: '#F1F5FE', color: '#0288d1', fontSize: '12px', textTransform: 'capitalize', height: 'max-content', '&:hover': {
                    backgroundColor: '#F1F5FE',
                    color: '#0288d1',
                    border: '1px solid #0288d1',
                    boxShadow: "1px #000000",
                  }
                }}
                onClick={() => {
                  setFilterListStatus(prev => !prev)
                }}
              > {filterListStatus ? "Remove Filter" : "Filter"}</Button> */}
            </Box>
            <Button
              variant="contained"
              endIcon={<FileDownloadIcon />}
              sx={{
                border: "1px solid #F1F5FE",
                backgroundColor: "#F1F5FE",
                color: "#0288d1",
                fontSize: "12px",
                textTransform: "capitalize",
                height: "max-content",
                "&:hover": {
                  backgroundColor: "#F1F5FE",
                  color: "#0288d1",
                  border: "1px solid #0288d1",
                  boxShadow: "1px #000000",
                },
              }}
              onClick={() => {
                // downloadFile();
              }}
            >
              Export
            </Button>
            <Button
              variant="contained"
              endIcon={<UploadFileIcon />}
              sx={{
                border: "1px solid #F1F5FE",
                backgroundColor: "#F1F5FE",
                color: "#0288d1",
                fontSize: "12px",
                textTransform: "capitalize",
                height: "max-content",
                "&:hover": {
                  backgroundColor: "#F1F5FE",
                  color: "#0288d1",
                  border: "1px solid #0288d1",
                  boxShadow: "1px #000000",
                },
              }}
              onClick={ShowImportModalFunc}
            >
              {" "}
              Import
            </Button>
            <Button
              variant="contained"
              startIcon={<AddCircleIcon />}
              sx={{
                border: "1px solid #F1F5FE",
                backgroundColor: "#F1F5FE",
                color: "#0288d1",
                fontSize: "12px",
                textTransform: "capitalize",
                height: "max-content",
                "&:hover": {
                  backgroundColor: "#F1F5FE",
                  color: "#0288d1",
                  border: "1px solid #0288d1",
                  boxShadow: "1px #000000",
                },
              }}
              onClick={ShowAddModal}
            >
              Add
            </Button>
          </Box>
        </Box>

        <Box alignItems="center" display="flex" style={{ padding: "0 20px" }}>
          <TableUtility {...options} height={"70vh"} />

          <Slide
            direction="left"
            in={filterListStatus}
            mountOnEnter
            unmountOnExit
          >
            <Paper
              style={{ backgroundColor: "#f1f5fe", height: "70vh" }}
              sx={{ p: "0em 0.2em" }}
            >
              <Box
                display="flex"
                justifyContent="space-between"
                alignItems="center"
              >
                <Typography
                  variant="h4"
                  fontSize={18}
                  fontWeight="bold"
                  p={"0.1em 0em"}
                  color="#0288d1"
                >
                  Add Filters
                </Typography>
                <IconButton onClick={clearAllFilter}>
                  <FilterAltOff />
                </IconButton>
              </Box>
              <Divider />
              <Box>
                <Box sx={{ p: "0.2em" }}>
                  <Typography
                    variant="h4"
                    fontSize={16}
                    fontWeight="bold"
                    sx={{ display: "block" }}
                  >
                    Location
                  </Typography>
                  <Box pl={"0.5em"}>
                    <FormControlLabel
                      label="Bhubaneswar"
                      control={
                        <Checkbox
                          name="isBbsr"
                          checked={filterOptions.isBbsr.checked}
                          onChange={handleOnFilterChange}
                          inputProps={{ "aria-label": "controlled" }}
                          sx={{ "& .MuiSvgIcon-root": { fontSize: 18 } }}
                        />
                      }
                    />
                    <FormControlLabel
                      label="Banglore"
                      control={
                        <Checkbox
                          name="isBang"
                          checked={filterOptions.isBang.checked}
                          onChange={handleOnFilterChange}
                          inputProps={{ "aria-label": "controlled" }}
                          sx={{ "& .MuiSvgIcon-root": { fontSize: 18 } }}
                        />
                      }
                    />
                  </Box>
                </Box>
                <Box sx={{ p: "0.2em" }}>
                  <Typography
                    variant="h4"
                    fontSize={16}
                    fontWeight="bold"
                    sx={{ display: "block" }}
                  >
                    Type
                  </Typography>
                  <Box pl={"0.5em"}>
                    <FormControlLabel
                      label="Laptop"
                      control={
                        <Checkbox
                          name="isLaptop"
                          checked={filterOptions.isLaptop.checked}
                          onChange={handleOnFilterChange}
                          inputProps={{ "aria-label": "controlled" }}
                          sx={{ "& .MuiSvgIcon-root": { fontSize: 18 } }}
                        />
                      }
                    />
                    <FormControlLabel
                      label="Mobile"
                      control={
                        <Checkbox
                          name="isMobile"
                          checked={filterOptions.isMobile.checked}
                          onChange={handleOnFilterChange}
                          inputProps={{ "aria-label": "controlled" }}
                          sx={{ "& .MuiSvgIcon-root": { fontSize: 18 } }}
                        />
                      }
                    />
                  </Box>
                </Box>
                <Box sx={{ p: "0.2em" }}>
                  <Typography
                    variant="h4"
                    fontSize={16}
                    fontWeight="bold"
                    sx={{ display: "block" }}
                  >
                    Brand
                  </Typography>
                  <Box pl={"0.5em"}>
                    <FormControlLabel
                      label="Apple"
                      control={
                        <Checkbox
                          name="isApple"
                          checked={filterOptions.isApple.checked}
                          onChange={handleOnFilterChange}
                          inputProps={{ "aria-label": "controlled" }}
                          sx={{ "& .MuiSvgIcon-root": { fontSize: 18 } }}
                        />
                      }
                    />
                    <FormControlLabel
                      label="Dell"
                      control={
                        <Checkbox
                          name="isDell"
                          checked={filterOptions.isDell.checked}
                          onChange={handleOnFilterChange}
                          inputProps={{ "aria-label": "controlled" }}
                          sx={{ "& .MuiSvgIcon-root": { fontSize: 18 } }}
                        />
                      }
                    />
                    <FormControlLabel
                      label="HP"
                      control={
                        <Checkbox
                          name="isHP"
                          checked={filterOptions.isHP.checked}
                          onChange={handleOnFilterChange}
                          inputProps={{ "aria-label": "controlled" }}
                          sx={{ "& .MuiSvgIcon-root": { fontSize: 18 } }}
                        />
                      }
                    />
                    <FormControlLabel
                      label="Lenovo"
                      control={
                        <Checkbox
                          name="isLenovo"
                          checked={filterOptions.isLenovo.checked}
                          onChange={handleOnFilterChange}
                          inputProps={{ "aria-label": "controlled" }}
                          sx={{ "& .MuiSvgIcon-root": { fontSize: 18 } }}
                        />
                      }
                    />
                  </Box>
                </Box>
              </Box>
            </Paper>
          </Slide>
          {/* </Collapse> */}
        </Box>
      </Stack>
      {/***** SNACKBAR COMPONENT * */}
      <Snackbar
        open={SuccessStatus}
        autoHideDuration={6000}
        onClose={handleClose}
      >
        <Alert onClose={handleClose} severity="success" sx={{ width: "100%" }}>
          SUCCESS!!
        </Alert>
      </Snackbar>
      <Snackbar
        open={FailedStatus}
        autoHideDuration={6000}
        onClose={handleClose}
      >
        <Alert onClose={handleClose} severity="error" sx={{ width: "100%" }}>
          FAILED!!
        </Alert>
      </Snackbar>

      {/* *modal components***  */}

      <ThemeProvider theme={dialogTheme}>
        {/* *modal import***  */}

        <Dialog open={importStatus}>
          <DialogTitle sx={{ textAlign: "center" }}>
            <Typography variant="h6">Select File</Typography>
          </DialogTitle>
          <DialogContent sx={{ padding: "10px" }} className="wbScroll">
            {/* <Box sx={{ display: 'flex', jusityContent: 'center', alignItems: 'center' }}>
                            <input type="file" onChange={setFile} />
                        </Box> */}
            <DraggableFileUploader
              file={selectedFile}
              setFile={setSelectedFile}
              fileTypes={["xlsx"]}
            />
          </DialogContent>
          <DialogActions>
            <Button
              onClick={(e) => uploadFile(e)}
              variant="contained"
              sx={{
                backgroundColor: "#0288d1",
                textTransform: "none",
                "&:hover": {
                  backgroundColor: "#140B96",
                  boxShadow: "1px #000000",
                },
              }}
              disableRipple
            >
              Upload
            </Button>
            <Button
              onClick={handleClose}
              variant="contained"
              sx={{
                backgroundColor: "#E0E0E0",
                textTransform: "none",
                color: "black",
                "&:hover": {
                  backgroundColor: "#E0E0E0",
                  boxShadow: "1px #000000",
                },
              }}
              disableRipple
            >
              Cancel
            </Button>
          </DialogActions>
        </Dialog>

        {/* *modal DELETE***  */}

        <Dialog open={deleteModalStatus}>
          <DialogTitle sx={{ textAlign: "center" }}>
            <ErrorOutlineIcon sx={{ color: "#FF9A22", fontSize: "2.3rem" }} />
            <Typography variant="h6">Are you sure?</Typography>
            <Typography sx={{ fontSize: "0.9rem" }}>
              <span>Are you sure you want to delete this record?</span>
              <br />
              <span>This process cannot be undone.</span>
            </Typography>
          </DialogTitle>
          <DialogActions sx={{ justifyContent: "center" }}>
            <Button
              onClick={handleDelete}
              variant="contained"
              sx={{
                backgroundColor: "#CF0100",
                textTransform: "none",
                "&:hover": {
                  backgroundColor: "#B70100",
                  boxShadow: "1px #000000",
                },
              }}
              disableRipple
            >
              Delete
            </Button>
            <Button
              onClick={handleClose}
              variant="contained"
              sx={{
                backgroundColor: "#E0E0E0",
                textTransform: "none",
                color: "black",
                "&:hover": {
                  backgroundColor: "#E0E0E0",
                  boxShadow: "1px #000000",
                },
              }}
              disableRipple
            >
              Cancel
            </Button>
          </DialogActions>
        </Dialog>

        {/* *modal EDIT***  */}
        {/* 
        /     //[
  // {
  //   "id": 3,
  //   "name": "qwerty",
  //   "licenseStDate": 1659916800000,
  //   "licenseEndDate": 1659916800000,
  //   "noOfLicense": 1,
  //   "licenseAssigned": 0,
  //   "desc": "abc",
  //   "price": 110,
  //   "type": "Paid",
  //   "organization": "Inc"
  //   }
  //   ] */}

        <Dialog open={editModalStatus} sx={{}}>
          {/* <DialogTitle>
            <Typography varient='h1'>
              Edit IntangibleAssets
            </Typography>
          </DialogTitle> */}
          <DialogContent sx={{ padding: "10px" }} className="wbScroll">
            <Stack>
              <Grid container spacing={8} rowSpacing={3}>
                <Grid item xs={6}>
                  <Typography sx={{ color: "#424242" }}>
                    Name<span style={{ color: "red" }}>*</span>
                  </Typography>
                  <TextField
                    sx={{ width: "max-content" }}
                    SelectProps={inputSize}
                    margin="normal"
                    id="outlined-basic"
                    variant="outlined"
                    name="name"
                    value={EditFormData.name}
                    onChange={handleOnChange}
                  ></TextField>
                </Grid>
                <Grid item xs={6}>
                  <Typography sx={{ color: "#424242" }}>
                    License start date <span style={{ color: "red" }}>*</span>
                  </Typography>
                  <TextField
                    SelectProps={inputSize}
                    margin="normal"
                    id="outlined-basic"
                    variant="outlined"
                    name="licenseStDate"
                    defaultValue={EditFormData.licenseStDate}
                    onChange={handleOnChange}
                  ></TextField>
                </Grid>
                <Grid item xs={6}>
                  <Typography sx={{ color: "#424242" }}>
                    License End Date<span style={{ color: "red" }}>*</span>
                  </Typography>
                  {/* <LocalizationProvider dateAdapter={AdapterDateFns}>
                                <DatePicker
                                    InputProps={dateInputSize}
                                    value={EditFormData.licenseEndDate}
                                    onChange={(newValue) => {
                                        setAssignFormData((prev) => {
                                            return {
                                                ...prev, assetStDate: `${Date.parse(newValue) ? newValue.toISOString().substr(0, 10) : { date }}`
                                            }
                                        })
                                    }}
                                    renderInput={(params) =>
                                        <FormControl sx={{ width: '100%' }}>
                                            <TextField
                                                {...params}
                                            />
                                        </FormControl>
                                    }
                                />
                            </LocalizationProvider> */}
                  <TextField
                    margin="normal"
                    id="outlined-basic"
                    variant="outlined"
                    name="licenseEndDate"
                    InputProps={{
                      style: { ...inputSize.style, paddingLeft: "20px" },
                    }}
                    value={EditFormData.licenseEndDate}
                    onChange={handleOnChange}
                  />
                </Grid>
                <Grid item xs={6}>
                  <Typography sx={{ color: "#424242" }}>
                    Number Of License<span style={{ color: "red" }}>*</span>
                  </Typography>
                  <TextField
                    SelectProps={inputSize}
                    margin="normal"
                    id="outlined-basic"
                    variant="outlined"
                    name="noOfLicense"
                    defaultValue={EditFormData.noOfLicense}
                    onChange={handleOnChange}
                  ></TextField>
                </Grid>
                <Grid item xs={6}>
                  <Typography sx={{ color: "#424242" }}>
                    Version<span style={{ color: "red" }}>*</span>
                  </Typography>
                  <TextField
                    InputProps={inputSize}
                    margin="normal"
                    id="outlined-basic"
                    variant="outlined"
                    name="version"
                    value={EditFormData.version}
                    onChange={handleOnChange}
                  />
                </Grid>
                <Grid item xs={6}>
                  <Typography sx={{ color: "#424242" }}>
                    Description<span style={{ color: "red" }}>*</span>
                  </Typography>
                  <TextField
                    InputProps={inputSize}
                    margin="normal"
                    id="outlined-basic"
                    variant="outlined"
                    name="desc"
                    value={EditFormData.desc}
                    onChange={handleOnChange}
                  />
                </Grid>
                <Grid item xs={6}>
                  <Typography sx={{ color: "#424242" }}>
                    Price<span style={{ color: "red" }}>*</span>
                  </Typography>
                  <TextField
                    InputProps={inputSize}
                    margin="normal"
                    id="outlined-basic"
                    variant="outlined"
                    name="price"
                    value={EditFormData.price}
                    onChange={handleOnChange}
                  />
                </Grid>
                <Grid item xs={6}>
                  <Typography sx={{ color: "#424242" }}>
                    type<span style={{ color: "red" }}>*</span>
                  </Typography>
                  <TextField
                    InputProps={inputSize}
                    margin="normal"
                    id="outlined-basic"
                    variant="outlined"
                    name="type"
                    value={EditFormData.type}
                    onChange={handleOnChange}
                  />
                </Grid>
                <Grid item xs={6}>
                  <Typography sx={{ color: "#424242" }}>
                    Organization<span style={{ color: "red" }}>*</span>
                  </Typography>
                  <TextField
                    InputProps={inputSize}
                    margin="normal"
                    id="outlined-basic"
                    variant="outlined"
                    name="organization"
                    value={EditFormData.organization}
                    onChange={handleOnChange}
                  />
                </Grid>
              </Grid>
            </Stack>
          </DialogContent>
          <DialogActions>
            <Button
              onClick={() => handleValidations(1)}
              variant="contained"
              sx={{
                backgroundColor: "#0288d1",
                textTransform: "none",
                "&:hover": {
                  backgroundColor: "#140B96",
                  boxShadow: "1px #000000",
                },
              }}
              disableRipple
            >
              Save
            </Button>
            <Button
              onClick={handleClose}
              variant="contained"
              sx={{
                backgroundColor: "#E0E0E0",
                textTransform: "none",
                color: "black",
                "&:hover": {
                  backgroundColor: "#E0E0E0",
                  boxShadow: "1px #000000",
                },
              }}
              disableRipple
            >
              Cancel
            </Button>
          </DialogActions>
        </Dialog>

        {/* *modal ADD NEW DATA***  */}
        <form
          onSubmit={(e) => {
            e.preventDefault();
            console.log(e);
          }}
        >
          <Dialog open={AddModalStatus} sx={{ padding: "20px" }}>
            <DialogContent sx={{ padding: "10px" }} className="wbScroll">
              <Stack>
                <Grid container spacing={8} rowSpacing={3}>
                  <Grid item xs={6}>
                    <Typography sx={{ color: "#424242" }}>
                      Name<span style={{ color: "red" }}>*</span>
                    </Typography>
                    <TextField
                      sx={{ width: "max-content" }}
                      SelectProps={inputSize}
                      margin="normal"
                      id="outlined-basic"
                      l
                      variant="outlined"
                      name="name"
                      defaultValue={EditFormData.name}
                      onChange={handleOnChange}
                    >
                      <MenuItem value={"Bhubaneswar"}>Bhubaneswar</MenuItem>
                      <MenuItem value={"Bangalore"}>Bangalore</MenuItem>
                    </TextField>
                  </Grid>
                  <Grid item xs={6}>
                    <Typography sx={{ color: "#424242" }}>
                      License start date <span style={{ color: "red" }}>*</span>
                    </Typography>
                    <TextField
                      SelectProps={inputSize}
                      margin="normal"
                      id="outlined-basic"
                      variant="outlined"
                      name="licenseStDate"
                      defaultValue={EditFormData.licenseStDate}
                      onChange={handleOnChange}
                    ></TextField>
                  </Grid>
                  <Grid item xs={6}>
                    <Typography sx={{ color: "#424242" }}>
                      License End Date<span style={{ color: "red" }}>*</span>
                    </Typography>
                    <TextField
                      margin="normal"
                      id="outlined-basic"
                      variant="outlined"
                      name="licenseEndDate"
                      InputProps={{
                        style: { ...inputSize.style, paddingLeft: "20px" },
                      }}
                      value={EditFormData.licenseEndDate}
                      onChange={handleOnChange}
                    />
                  </Grid>
                  <Grid item xs={6}>
                    <Typography sx={{ color: "#424242" }}>
                      Number Of License<span style={{ color: "red" }}>*</span>
                    </Typography>
                    <TextField
                      SelectProps={inputSize}
                      margin="normal"
                      id="outlined-basic"
                      variant="outlined"
                      name="noOfLicense"
                      defaultValue={EditFormData.noOfLicense}
                      onChange={handleOnChange}
                    ></TextField>
                  </Grid>
                  <Grid item xs={6}>
                    <Typography sx={{ color: "#424242" }}>
                      Version<span style={{ color: "red" }}>*</span>
                    </Typography>
                    <TextField
                      InputProps={inputSize}
                      margin="normal"
                      id="outlined-basic"
                      variant="outlined"
                      name="version"
                      value={EditFormData.version}
                      onChange={handleOnChange}
                    />
                  </Grid>
                  <Grid item xs={6}>
                    <Typography sx={{ color: "#424242" }}>
                      Description<span style={{ color: "red" }}>*</span>
                    </Typography>
                    <TextField
                      InputProps={inputSize}
                      margin="normal"
                      id="outlined-basic"
                      variant="outlined"
                      name="desc"
                      value={EditFormData.desc}
                      onChange={handleOnChange}
                    />
                  </Grid>
                  <Grid item xs={6}>
                    <Typography sx={{ color: "#424242" }}>
                      Price<span style={{ color: "red" }}>*</span>
                    </Typography>
                    <TextField
                      InputProps={inputSize}
                      margin="normal"
                      id="outlined-basic"
                      variant="outlined"
                      name="price"
                      value={EditFormData.price}
                      onChange={handleOnChange}
                    />
                  </Grid>
                  <Grid item xs={6}>
                    <Typography sx={{ color: "#424242" }}>
                      type<span style={{ color: "red" }}>*</span>
                    </Typography>
                    <TextField
                      InputProps={inputSize}
                      margin="normal"
                      id="outlined-basic"
                      variant="outlined"
                      name="type"
                      value={EditFormData.type}
                      onChange={handleOnChange}
                    />
                  </Grid>
                  <Grid item xs={6}>
                    <Typography sx={{ color: "#424242" }}>
                      Organization<span style={{ color: "red" }}>*</span>
                    </Typography>
                    <TextField
                      InputProps={inputSize}
                      margin="normal"
                      id="outlined-basic"
                      variant="outlined"
                      name="organization"
                      value={EditFormData.organization}
                      onChange={handleOnChange}
                    />
                  </Grid>
                </Grid>
              </Stack>
            </DialogContent>
            <DialogActions>
              <Button
                type="submit"
                onClick={() => handleValidations(0)}
                variant="contained"
                sx={{
                  backgroundColor: "#0288d1",
                  textTransform: "none",
                  "&:hover": {
                    backgroundColor: "#140B96",
                    boxShadow: "1px #000000",
                  },
                }}
                disableRipple
              >
                Save
              </Button>
              <Button
                onClick={handleClose}
                variant="contained"
                sx={{
                  backgroundColor: "#E0E0E0",
                  textTransform: "none",
                  color: "black",
                  "&:hover": {
                    backgroundColor: "#E0E0E0",
                    boxShadow: "1px #000000",
                  },
                }}
                disableRipple
              >
                Cancel
              </Button>
            </DialogActions>
          </Dialog>
        </form>
        {/* ***  */}
      </ThemeProvider>
      {/* ***  */}
    </>
  );
};

export default IntangibleAssets;

import { Box } from "@mui/system";
import React, { useState } from "react";
import { FileUploader } from "react-drag-drop-files";
import UploadFileIcon from "@mui/icons-material/UploadFile";
import { Button, Typography } from "@mui/material";
import { Delete } from "@mui/icons-material";

const DraggableFileUploader = ({ file = null, setFile, fileTypes }) => {
  const handleChange = (file) => {
    console.log(file);
    setFile(file);
  };

  return (
    <div>
      <FileUploader
        handleChange={handleChange}
        name="file"
        types={fileTypes}
        children={<CustomDropBox />}
      />
      <Box display={"flex"} justifyContent="space-around" alignItems="center">
        <p>
          {file ? `File name: ${file?.name ?? ""}` : "Upload or select a file"}
        </p>
        {file ? (
          <Button
            variant="contained"
            endIcon={<Delete sx={{ color: "red" }} />}
            sx={{
              border: "1px solid #F1F5FE",
              backgroundColor: "#F1F5FE",
              color: "#0288d1",
              fontSize: "12px",
              textTransform: "capitalize",
              height: "max-content",
              "&:hover": {
                backgroundColor: "#F1F5FE",
                color: "#0288d1",
                border: "1px solid #0288d1",
                boxShadow: "1px #000000",
              },
            }}
            onClick={() => setFile(null)}
          >
            Remove File
          </Button>
        ) : null}
      </Box>
    </div>
  );
};

export default DraggableFileUploader;

const CustomDropBox = () => {
  return (
    <Box
      sx={{ height: "18vh", width: "25em" }}
      display="flex"
      flexDirection="column"
      justifyContent="center"
      alignItems="center"
      style={{
        borderStyle: "dashed",
        borderRadius: "5px",
        backgroundColor: "#f1f5fe",
        borderColor: "grey",
        cursor: "pointer",
      }}
    >
      <UploadFileIcon sx={{ color: "#0288d1", fontSize: "2.3rem" }} />
    </Box>
  );
};
